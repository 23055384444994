import { ChangeEvent } from 'react';
import styles from './index.module.css';
import clsx from 'clsx';

interface InputProps {
  label?: string;
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  form?: string;
  type?: string;
  classes?: {
    group?: string;
    label?: string;
    input?: string;
  };
}
const Input = (props: InputProps) => {
  const {
    label,
    value,
    onChange,
    required,
    form,
    type = 'text',
    classes,
  } = props;

  return (
    <div className={clsx(styles.group, classes?.group)}>
      <label>
        {label && (
          <div className={clsx(styles.label, classes?.label)}>{label}</div>
        )}
        <input
          required={required}
          form={form}
          type={type}
          className={clsx(styles.input, classes?.input)}
          value={value}
          onChange={(e) => onChange && onChange(e)}
        />
      </label>
    </div>
  );
};

export default Input;

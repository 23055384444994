import Navbar from 'components/shared/Navbar';
import Footer from 'components/shared/Footer';
import Sidebar from 'components/shared/Sidebar';
import CookieNotification from 'components/shared/CookieNotification';
import { FunctionComponent } from 'react';
import styles from './index.module.css';

const Layout: FunctionComponent = (props) => {
  return (
    <>
      <Navbar />
      <main>{props.children}</main>
      <Footer />
      <Sidebar />
      <CookieNotification
        classNames={{ component: styles.cookieNotification }}
      />
    </>
  );
};

export default Layout;

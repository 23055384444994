import { CustomField, Snipcart } from 'types/Snipcart';
import { IProduct, IVariation } from '../../../types/strapi';

export const ITEM_ADDING = 'item.adding';
export const ITEM_ADDED = 'item.added';
export const ITEM_UPDATED = 'item.updated';
export const ITEM_REMOVED = 'item.removed';
export const CART_CREATED = 'cart.created';
export const CART_CONFIRMED = 'cart.confirmed';
export const CART_CONFIR_ERROR = 'cart.confirm.error';
export const CART_RESET = 'cart.reset';
export const PAYMENT_FAILED = 'payment.failed';
export const CUSTOMER_REGISTERED = 'customer.registered';
export const CUSTOMER_SIGNEDIN = 'customer.signedin';
export const CUSTOMER_SIGNEDOUT = 'customer.signedout';
export const LANGUAGE_UPDATED = 'language.updated';
export const SNIPCART_INITIALIZED = 'snipcart.initialized';
export const SNIPCART_INITIALIZATION_FAILED = 'snipcart.initialization.failed';
export const SNIPCART_READY = 'snipcart.ready';
export const THEME_ROUTECHANGED = 'theme.routechanged';

const config = {
  key:
    process.env.REACT_APP_SNIPCART_KEY ||
    'ZTdkNGUzMDYtYzAxYi00OTkwLTgzYmEtM2M2NjM2MGMxY2Y0NjM3NzI3NTk2OTcyMDYxMTYx',
  defaultCurrency: 'gbp',
  customLabels: {
    en: {
      actions: {
        continue_shopping: 'Exit checkout',
      },
    },
  },
  paymentStyles: {
    input: {
      border: '2px solid black',
    },
  },

  productsValidationUrl: `${
    process.env.REACT_APP_CMS_URL || 'https://cms.creative-conscience.org.uk'
  }/products-validation`,
};

/**
 * Create Snipcart div, js, css once.
 */
const injectSnipcartHTML = () => {
  if (
    document.getElementById('snipcart') ||
    (window as any).__PRERENDER_INJECTED
  )
    return;

  // Html
  const snipcartHTML = document.createElement('div');
  snipcartHTML.hidden = true;
  snipcartHTML.id = 'snipcart';
  snipcartHTML.setAttribute('data-api-key', config.key);
  // Custom tags
  snipcartHTML.setAttribute('data-config-add-product-behavior', 'none');
  snipcartHTML.setAttribute('data-currency', config.defaultCurrency);

  document.body.appendChild(snipcartHTML);

  // Styles
  const snipcartStyles = document.createElement('link');
  snipcartStyles.rel = 'stylesheet';
  snipcartStyles.type = 'text/css';
  snipcartStyles.href =
    'https://cdn.snipcart.com/themes/v3.3.0/default/snipcart.css';
  document.head.appendChild(snipcartStyles);

  // Script
  const snipcartScript = document.createElement('script');
  snipcartScript.async = true;
  snipcartScript.setAttribute(
    'src',
    'https://cdn.snipcart.com/themes/v3.3.0/default/snipcart.js'
  );

  document.body.appendChild(snipcartScript);
};

export async function getSnipcart(): Promise<Snipcart> {
  injectSnipcartHTML();
  initSettings();
  return await new Promise((resolve) => {
    if (window.Snipcart) {
      window.Snipcart.ready.then(() => {
        resolve(window.Snipcart as Snipcart);
      });
    } else {
      document.addEventListener(SNIPCART_READY, () => {
        window.Snipcart?.ready.then(() => {
          resolve(window.Snipcart as Snipcart);
        });
      });
    }
  });
}

export async function clearCart(snipcart: Snipcart) {
  const items = snipcart.store.getState().cart.items.items;
  const discounts = snipcart.store.getState().cart.discounts.items;

  for (const item of items) {
    try {
      await snipcart.api.cart.items.remove(item.uniqueId);
    } catch (error) {
      console.error(error);
    }
  }

  for (const discount of discounts) {
    try {
      await snipcart.api.cart.removeDiscount(discount.code);
    } catch (error) {
      console.error(error);
    }
  }
}

export async function addItem(
  snipcart: Snipcart,
  product: IProduct,
  variation: IVariation,
  quantity: number,
  customFields?: CustomField[]
) {
  const item = {
    id: `${product.id}.${variation.id}`,
    name:
      product.name === variation.name
        ? product.name
        : `${product.name} / ${variation.name}`,
    price: variation.price,
    quantity,
    shippable: false,
    url: config.productsValidationUrl,
    categories: [product.productCategory],
    customFields,
  };
  return await snipcart.api.cart.items.add(item);
}

export function openCheckout() {
  window.location.hash = '/checkout';
}

/**
 * @returns {string} String in special format
 * required by snipcart for custom fields
 * "16GB|32GB[+50.00]|128GB[+200.00]",
 */
export const createSnipcartCustomFieldsString = (
  variations: { name: string; price: number }[]
) => variations.map((c) => `${c.name}[+${c.price}]`).join('|');

export const createOperationValueFromPrice = (price: number) => `+${price}`;

export async function addPaymentFormStyles(styles: any) {
  const snipcart = await getSnipcart();
  try {
    snipcart.api.theme.customization.registerPaymentFormCustomization(styles);
  } catch (error) {
    console.log(error);
  }
}

export async function addCustomLabels(languages: any) {
  const snipcart = await getSnipcart();
  try {
    Object.keys(languages).forEach((lang) => {
      snipcart.api.session.setLanguage(lang, languages[lang]);
    });
  } catch (error) {
    console.log(error);
  }
}

let settingsInited = false;
// Need to find a better place for this one
function initSettings() {
  if (settingsInited) return;

  settingsInited = true;
  addPaymentFormStyles(config.paymentStyles);
  addCustomLabels(config.customLabels);
}

export function subscribeSnipcartOrder(
  snipcart: Snipcart,
  onSuccess: (order: any) => void,
  onError: (error: string) => void
) {
  const unsubscribeSuccess = snipcart.events.on('cart.confirmed', onSuccess);
  const unsubscribeError = snipcart.events.on('cart.confirm.error', onError);
  return () => {
    unsubscribeSuccess();
    unsubscribeError();
  };
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IProjectTheme } from 'types/generated/strapi';

export const fetchProjectThemes = createAsyncThunk(
  'projectThemes/fetch',
  async () => {
    const response = await strapi.getProjectThemes();
    return response;
  }
);

export interface IProjectThemeSlice {
  projectThemesRequest: IApiRequest<IProjectTheme[]>;
}

const initialState: IProjectThemeSlice = {
  projectThemesRequest: {
    status: 'initial',
  },
};

export const projectThemeSlice = createSlice({
  name: 'projectTheme',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectThemes.pending, (state) => {
        state.projectThemesRequest.status = 'pending';
      })
      .addCase(fetchProjectThemes.fulfilled, (state, action) => {
        state.projectThemesRequest.status = 'fulfilled';
        state.projectThemesRequest.data = action.payload;
      })
      .addCase(fetchProjectThemes.rejected, (state, action) => {
        state.projectThemesRequest.status = 'rejected';
        state.projectThemesRequest.error = action.error;
      });
  },
});

export default projectThemeSlice.reducer;

import { useEffect, useMemo, useState, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { connect, ConnectedProps } from 'react-redux';
import { fetchProject } from 'redux/projectSlice';
import { AppState } from 'redux/store';

import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeAddClasses from 'rehype-add-classes';
import rehypeRaw from 'rehype-raw';

import Error404 from 'pages/Error404/Error404';

import Layout from 'components/shared/Layout';
import SeoHead from 'components/shared/SeoHead';
import StrapiLink from 'components/shared/StrapiLink';
import StrapiImage from 'components/shared/StrapiImage';
import BackLink from 'components/shared/BackLink';
import VideoEmbed from 'components/shared/VideoEmbed';

import PageHero from 'components/sections/PageHero';

import { ICollectionHeader } from 'types/generated/strapi';

import styles from './Project.module.css';
import generateStaticPage from 'utils/generateStaticPage';
import Loading from 'components/shared/Loading';

const ProjectPage: FunctionComponent<IProjectPageProps> = (props) => {
  const { slug } = useParams();
  const { fetchProject, project, projectStatus, collectionSettings } = props;
  const {
    awarded,
    discipline,
    disciplineWinner,
    projectTheme,
    themeWinner,
    websites,
    projectDescription,
    projectMedia,
    education,
    projectEntrants,
    // projectTitle,
  } = { ...project };

  const [pageIsReady, setPageIsReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (!slug) return;
      if (project?.slug !== slug) await fetchProject(slug);
      setPageIsReady(true);
      generateStaticPage();
    })();
  }, [fetchProject, slug, project]);

  const pageHeroSection: ICollectionHeader | undefined = useMemo(() => {
    return collectionSettings?.collectionHeaders.find((collection) => {
      return collection.collectionType === 'Winners';
    });
  }, [collectionSettings]);

  const projectIsWinner: boolean = useMemo(() => {
    if (!awarded) return false;
    return ['Gold', 'Silver', 'Bronze', 'HighlyCommended'].includes(awarded);
  }, [awarded]);

  const backLinkUrl = useMemo(() => {
    return projectIsWinner ? '/winners' : '/shortlisted';
  }, [projectIsWinner]);

  const backLinkLabel = useMemo(() => {
    return `Back to all ${projectIsWinner ? 'winners' : 'shortlisted'}`;
  }, [projectIsWinner]);

  const entrantsFullNames = useMemo(() => {
    if (!projectEntrants?.length) return '';
    return projectEntrants
      .map((entrant) => `${entrant.firstName} ${entrant.lastName}`)
      .join(', ');
  }, [projectEntrants]);

  if (
    project === undefined ||
    projectStatus === 'pending' ||
    slug !== project?.slug
  )
    return <Loading />;
  if (project === null) return <Error404 />;

  return (
    <Layout>
      <SeoHead seo={project.SEO} />

      {/* Hero */}
      {pageHeroSection && <PageHero section={pageHeroSection} />}

      {/* Project */}
      <div
        className={clsx(styles.project, !pageIsReady && styles.projectLoading)}
      >
        <div className={styles.projectInner}>
          {/* Details */}
          <div className={styles.details}>
            {/* Author title */}
            {entrantsFullNames && (
              <h2 className={styles.author}>{entrantsFullNames}</h2>
            )}

            {/* Awarded */}
            {awarded && (
              <div
                className={clsx(
                  styles.awarded,
                  awarded === 'Gold' && styles.awardedGold,
                  awarded === 'Silver' && styles.awardedSilver,
                  awarded === 'Bronze' && styles.awardedBronze
                )}
              >
                {awarded === 'HighlyCommended' ? 'Highly Commended' : awarded}
              </div>
            )}

            {/* Discipline */}
            {discipline && (
              <div className={styles.discipline}>
                <h4 className={styles.disciplineTitle}>
                  Discipline {disciplineWinner && <span> (WINNER)</span>}
                </h4>
                <div>{discipline.name}</div>
              </div>
            )}

            {/* Theme */}
            {projectTheme && (
              <div className={styles.theme}>
                <h4 className={styles.themeTitle}>
                  Theme {themeWinner && <span> (WINNER)</span>}
                </h4>
                <div>{projectTheme.name}</div>
              </div>
            )}

            {/* Place of study */}
            {education?.university && (
              <div className={styles.placeOfStudy}>
                <h4 className={styles.placeOfStudyTitle}>University</h4>
                <div>{education.university}</div>
              </div>
            )}

            {/* Year of study */}
            {education?.yearOfStudy && (
              <div className={styles.yearOfStudy}>
                <h4 className={styles.yearOfStudyTitle}>Year of Study</h4>
                <div>{education.yearOfStudy}</div>
              </div>
            )}

            {/* Links */}
            {websites && websites.length > 0 && (
              <ul className={styles.websites}>
                {websites.map((website) => (
                  <li key={`website-${website.id}`}>
                    <StrapiLink
                      className={styles.websiteLink}
                      link={website}
                      underline="hover"
                    />
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Description */}
          {projectDescription && (
            <ReactMarkdown
              className={styles.description}
              rehypePlugins={[
                [rehypeExternalLinks, { rel: ['noopener', 'noreferrer'] }],
                [
                  rehypeAddClasses,
                  {
                    a: styles.biographyLink,
                    ul: styles.biographyList,
                    li: styles.biographyListItem,
                  },
                ],
                rehypeRaw,
              ]}
              children={projectDescription}
            />
          )}

          {/* Media */}
          {projectMedia && projectMedia.length > 0 && (
            <div className={styles.media}>
              {projectMedia.map((media) => (
                <div
                  className={styles.mediaItem}
                  key={`media-${media.__component}-${media.id}`}
                >
                  {/* Image */}
                  {media.__component === 'shared.image-item' && (
                    <StrapiImage image={media.image} format="large" />
                  )}

                  {/* YouTube */}
                  {media.__component === 'shared.youtube-item' && (
                    <VideoEmbed url={media.youtubeLink} />
                  )}

                  {/* Vimeo */}
                  {media.__component === 'shared.vimeo-item' && (
                    <VideoEmbed url={media.vimeoLink} />
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Back to winners link */}
          <div className={styles.backLinkWrapper}>
            <BackLink link={backLinkUrl} label={backLinkLabel} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  project: state.project.projectRequest.data,
  projectStatus: state.project.projectRequest.status,
  collectionSettings: state.collectionSettings.collectionSettingsRequest.data,
});

const mapDispatchToProps = {
  fetchProject,
};

interface IProjectPageProps extends ConnectedProps<typeof connector> {}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProjectPage);

import { IJoinCommunity } from 'types/generated/strapi';
import styles from './index.module.css';
import { FunctionComponent } from 'react';
import StrapiLink from 'components/shared/StrapiLink';
import ReactMarkdown from 'react-markdown';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';

interface IJoinCommunityProps {
  section: IJoinCommunity;
}

const JoinCommunity: FunctionComponent<IJoinCommunityProps> = (props) => {
  const { title, items } = props.section;

  const { ref, inView } = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });

  return (
    <div className={styles.joinCommunity}>
      <div className={styles.joinCommunityInner} ref={ref}>
        <ReactMarkdown
          className={clsx(styles.titleWrapper, inView && styles.rollUp)}
          components={{
            // convert main wrapper <p> into <h3>
            p: ({ node, ...props }) => (
              <h3 className={styles.title} children={props.children} />
            ),
            // convert *text* to `span` with an outline effect
            em: ({ node, ...props }) => (
              <span className={styles.outlinedText} {...props} />
            ),
          }}
        >
          {title}
        </ReactMarkdown>

        <ul className={styles.list}>
          {items.map((item) => {
            return (
              <li
                key={item.id}
                className={clsx(styles.customerItem, inView && styles.rollUp)}
              >
                <StrapiLink
                  link={item}
                  className={clsx(
                    styles.customerItemLink,
                    inView && styles.rollUp
                  )}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default JoinCommunity;

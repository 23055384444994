import { FunctionComponent } from 'react';
import { IAwardsIntro } from 'types/generated/strapi';
import styles from './index.module.css';
import ReactMarkdown from 'react-markdown';
import HiglightedSpans from 'components/shared/HighlightedSpans';

interface IAwardsIntroProps {
  section: IAwardsIntro;
}

const AwardsIntro: FunctionComponent<IAwardsIntroProps> = (props) => {
  const { title, copy } = props.section;
  return (
    <div
      className={styles.awardsIntro}
      data-sidebar-target
      data-sidebar-sticky
      data-sidebar-padding-top="var(--space-xxxl)"
    >
      <div className={styles.awardsIntroInner}>
        <div className={styles.stage}>
          <div className={styles.stageCopy}>
            <h2 className={styles.title}>{title}</h2>
            {copy && (
              <ReactMarkdown
                children={copy}
                components={{
                  a: ({ node, href, children }) => {
                    if (!href) return null;
                    if (href === 'HIGHLIGHTED') {
                      const text = children[0];
                      if (typeof text !== 'string') return null;

                      return <HiglightedSpans text={text} />;
                    }

                    return <a href={href}>{children}</a>;
                  },
                }}
                className={styles.copy}
              />
            )}
          </div>
          <div className={styles.stageTimeline}>
            <span className={styles.line}></span>
          </div>
          <div className={styles.stageDeadline}></div>
        </div>
      </div>
    </div>
  );
};

export default AwardsIntro;

import styles from './index.module.css';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { IShortlistedList, IProject } from 'types/generated/strapi';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import {
  fetchShortlistedProjects,
  fetchShortlistedProjectsCount,
} from 'redux/projectSlice';

import useFilter from 'utils/useFilter';
import StrapiImage from 'components/shared/StrapiImage';
import usePagination from 'utils/usePagination';
import Pagination from 'components/shared/Pagination';

interface IShortlistedListProps extends ConnectedProps<typeof connector> {
  section: IShortlistedList;
}

const ShortlistedList: FunctionComponent<IShortlistedListProps> = (props) => {
  const gridRef = useRef<HTMLDivElement>(null);

  const {
    fetchShortlistedProjects,
    fetchShortlistedProjectsCount,
    shortlisted,
    shortlistedCount,
    shortlistedStatus,
  } = props;

  // Connect pagination
  const { page, setPage, totalPages, perPage, withNextPage, withPrevPage } =
    usePagination({
      contentPerPage: 9,
      count: shortlistedCount,
    });

  // Connect filter by url query
  const [searchFilter, setSearchFilter] = useFilter({
    queryName: 'author',
  });
  const [pageFilter, setPageFilter] = useFilter({
    queryName: 'page',
  });

  const searchFilterValue = searchFilter[0] || '';
  const pageFilterValue = pageFilter[0] || 1;

  // Fetch list on search strings change
  useEffect(() => {
    fetchShortlistedProjects({
      page: pageFilterValue,
      perPage,
      search: searchFilterValue,
    });

    fetchShortlistedProjectsCount({
      search: searchFilterValue,
    });
  }, [
    pageFilterValue,
    perPage,
    searchFilterValue,
    fetchShortlistedProjects,
    fetchShortlistedProjectsCount,
  ]);

  // Set pagination page on query page change
  useEffect(() => {
    if (pageFilter[0]) {
      setPage(Number(pageFilter[0]));
    }
  }, [pageFilter, setPage]);

  // Scroll to list container on pagination button click
  const onPaginationClick = () => {
    gridRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const entrantsFullNames = useCallback((project: IProject) => {
    if (!project.projectEntrants?.length) return '';
    return project.projectEntrants
      .map((entrant) => `${entrant.firstName} ${entrant.lastName}`)
      .join(', ');
  }, []);

  return (
    <div className={styles.shortlistedList}>
      <div className={styles.shortlistedListInner}>
        {/* Grid Header */}
        <div className={styles.shortlistedGridHeader}>
          <div className={styles.shortlistedSearchInputWrapper}>
            <input
              type="text"
              className={styles.shortlistedSearchInput}
              value={searchFilterValue}
              onChange={(e) => {
                setPageFilter(1);
                setSearchFilter(e.target.value);
              }}
              placeholder="Search for"
            />
          </div>
        </div>

        {/* Grid */}
        <div ref={gridRef} className={styles.shortlistedGrid}>
          {shortlistedStatus === 'fulfilled' && !shortlisted.length && (
            <div>No matching projects found</div>
          )}
          {shortlisted.map((project: IProject) => {
            const author = entrantsFullNames(project);

            return (
              <div className={styles.shortlistedGridItem} key={project.id}>
                {project.titleImage && (
                  <div className={styles.shortlistedPhotoWrapper}>
                    <StrapiImage
                      className={styles.shortlistedPhoto}
                      format="medium"
                      image={project.titleImage}
                    />
                  </div>
                )}
                {author && (
                  <div className={styles.shortlistedName}>{author}</div>
                )}
                {project.discipline && (
                  <div className={styles.shortlistedDiscipline}>
                    {project.discipline.name}
                  </div>
                )}
                {project.websites && project.websites.length > 0 && (
                  <a href={project.websites[0].url} rel="nofollow noreferrer">
                    {project.websites[0].label || 'Website'}
                  </a>
                )}
              </div>
            );
          })}
        </div>

        {/* Pagination */}
        <Pagination
          page={page}
          totalPages={totalPages}
          withPrevPage={withPrevPage}
          withNextPage={withNextPage}
          onClick={onPaginationClick}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  shortlisted: state.project.shortlistedProjectsRequest.data ?? [],
  shortlistedCount: state.project.shortlistedProjectsCountRequest.data ?? 0,
  shortlistedStatus: state.project.shortlistedProjectsRequest.status,
});

const mapDispatchToProps = {
  fetchShortlistedProjects,
  fetchShortlistedProjectsCount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ShortlistedList);

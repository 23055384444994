import { FunctionComponent } from 'react';

import StrapiLink from 'components/shared/StrapiLink';

import { ILinkListColumns } from 'types/generated/strapi';

import styles from './index.module.css';

interface ILinkListColumnsProps {
  section: ILinkListColumns;
}

const LinkListColumns: FunctionComponent<ILinkListColumnsProps> = (props) => {
  const { heading, columns } = props.section;

  return (
    <div className={styles.linkListColumns}>
      <div className={styles.linkListColumnsInner}>
        {/* Main heading */}
        {heading && <h2 className={styles.heading}>{heading}</h2>}

        <div className={styles.columnsWrapper}>
          {columns.map((column, columnIndex) => {
            return (
              <div className={styles.column} key={`column-${columnIndex}`}>
                {/* Column heading */}
                {column.heading && (
                  <h4 className={styles.columnHeading}>{column.heading}</h4>
                )}

                <ul className={styles.columnList}>
                  {column.links.map((item, itemIndex) => {
                    return item.label ? (
                      <li className={styles.listItem} key={`item-${itemIndex}`}>
                        {/* Link */}
                        <StrapiLink link={item} underline="hover" />
                      </li>
                    ) : null;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LinkListColumns;

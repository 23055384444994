import styles from "./index.module.css";
import { FunctionComponent } from "react";
import { IArticle } from "types/generated/strapi";
import clsx from "clsx";

interface IArticleMetaProps {
  article: IArticle;
  className?: string;
}

const ArticleMeta: FunctionComponent<IArticleMetaProps> = (props) => {
  return (
    <div className={clsx(props.className, styles.articleMeta)}>
      <span>
        {new Date(props.article.publishDate).toLocaleString("en-GB", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </span>
      <span>{props.article.author}</span>
    </div>
  );
};

export default ArticleMeta;

import styles from './index.module.css';
import { FunctionComponent, ChangeEvent } from 'react';
import clsx from 'clsx';

interface IStyledSelectProps {
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  options: {
    value: string | number;
    label: string;
  }[];
  value?: string | number;
  placeholder?: string;
  variant?: 'primary' | 'secondary' | 'inverted';
  [x: string]: any;
}

const StyledSelect: FunctionComponent<IStyledSelectProps> = ({
  onChange,
  options,
  value,
  placeholder,
  variant = 'primary',
  children,
  ...props
}) => {
  return (
    <div className={clsx(styles.styledSelect, styles[variant])}>
      <select
        className={styles.select}
        onChange={onChange}
        value={value}
        {...props}
      >
        {options.map(({ value, label }, index) => {
          return (
            <option className={styles.option} key={index} value={value}>
              {label}
            </option>
          );
        })}
      </select>
      <div className={styles.selectButton}>
        <div className={styles.arrowDown}></div>
      </div>
    </div>
  );
};

export default StyledSelect;

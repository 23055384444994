import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'assets/css/fonts.css';
import 'assets/css/globals.css';
import 'assets/css/snipcart.css';

import { store } from 'redux/store';
import { Provider } from 'react-redux';

import { fetchMenu } from 'redux/menuSlice';
import { fetchFooter } from 'redux/footerSlice';
import { fetchCollectionSettings } from 'redux/collectionSettingsSlice';
import { fetchSidebar } from 'redux/sidebarSlice';

store.dispatch(fetchMenu());
store.dispatch(fetchFooter());
store.dispatch(fetchCollectionSettings());
store.dispatch(fetchSidebar());

if (!(window as any).__PRERENDER_INJECTED) {
  document.addEventListener('generate-static-page', () => {
    document.getElementById('root-server')?.remove();
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById(
    (window as any).__PRERENDER_INJECTED ? 'root-server' : 'root-client'
  )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

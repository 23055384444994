import { useEffect, FunctionComponent, useState } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import Loading from 'components/shared/Loading';
import { fetchProjectPreview } from 'redux/projectSlice';
import Error404 from 'pages/Error404/Error404';

const Preview: FunctionComponent<IPreviewProps> = (props) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const contentType = searchParams.get('contentType');
  const secretKey = searchParams.get('key');
  const { fetchProjectPreview, project, projectStatus } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (
      !id ||
      !contentType ||
      secretKey !==
        (process.env.REACT_APP_PREVIEW_KEY ||
          '1d1d8366-90e4-4be5-94dd-4adc0755e986')
    ) {
      setError(true);
      console.error('Invalid preview query');
      return;
    }
    if (contentType === 'project') {
      fetchProjectPreview(Number(id));
      return;
    }
    console.error('Invalid preview content type');
    setError(true);
  }, [id, contentType, fetchProjectPreview, secretKey]);

  if (error) return <Error404 />;
  if (project === undefined || projectStatus === 'pending') return <Loading />;
  if (project) {
    return <Navigate to={`/winners/${project?.slug}`} />;
  }

  return null;
};

const mapStateToProps = (state: AppState) => ({
  project: state.project?.projectRequest?.data,
  projectStatus: state.project?.projectRequest?.status,
});

const mapDispatchToProps = {
  fetchProjectPreview,
};

interface IPreviewProps extends ConnectedProps<typeof connector> {}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Preview);

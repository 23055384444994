import TagManager from 'react-gtm-module';

const initGtm = () => {
  // only run in production
  const env = process.env.NODE_ENV;
  if (env !== 'production') return;

  // only run if cookies accepted
  const gdpr = localStorage.getItem('GDPR:accepted');
  if (gdpr !== 'true') return;

  // trigger
  TagManager.initialize({
    gtmId: 'GTM-TBT373W',
  });
};
export default initGtm;

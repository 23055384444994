import { IAward, IAwardBrief, ISidebar } from './../types/generated/strapi';
import qs from 'qs';

import getTodayString from 'utils/getTodayString';

import {
  IPage,
  IMenu,
  IFooter,
  ICollectionSettings,
  IArticle,
  IPerson,
  IArticleCategory,
  IEvent,
  IProduct,
  IProject,
  IDiscipline,
  IProjectTheme,
} from '../types/generated/strapi';
import { Discount } from 'types/Snipcart';

interface StrapiDateQuery {
  date_lt?: string;
  date_lte?: string;
  date_gt?: string;
  date_gte?: string;
  date_null?: boolean;
}

interface StrapiEndDateQuery {
  endDate_lt?: string;
  endDate_lte?: string;
  endDate_gt?: string;
  endDate_gte?: string;
  endDate_null?: boolean;
}

/**
 * -----------------------------------------------------------------------------
 *  Helpers
 * -----------------------------------------------------------------------------
 */
// Strapi in production uses s3. Strapi local uses uploads.
export function getStrapiMediaUrl(path: string): string {
  if (process.env.NODE_ENV === 'production') return path;

  const cmsUrl = getStrapiURL('');
  return cmsUrl.includes('localhost') ? cmsUrl + path : path;
}

export function getStrapiURL(path: string): string {
  return `${
    process.env.REACT_APP_CMS_URL || 'https://cms.creative-conscience.org.uk'
  }${path}`;
}

async function fetchAPI<T>(path: string, options = {}): Promise<T> {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };
  const requestUrl = getStrapiURL(path);
  const response = await fetch(requestUrl, mergedOptions);

  if (!response.ok) {
    console.error(response.statusText);
    throw new Error(`An error occurred please try again`);
  }
  const data = await response.json();
  return data;
}

/**
 * -----------------------------------------------------------------------------
 *  Pages
 * -----------------------------------------------------------------------------
 */
export async function getPageBySlug(slug: string) {
  const data = await fetchAPI<IPage[]>(`/pages?slug=${slug}`);
  return !data || data.length < 1 ? null : data[0];
}

/**
 * -----------------------------------------------------------------------------
 *  Articles
 * -----------------------------------------------------------------------------
 */
export async function getArticleBySlug(slug: string) {
  const data = await fetchAPI<IArticle[]>(`/articles?slug=${slug}`);
  return !data || data.length < 1 ? null : data[0];
}

export async function getArticles({
  category,
  title,
  page,
  perPage,
}: {
  category: string;
  title: string;
  page: number | string;
  perPage: number | string;
}) {
  const query: {
    _start: number;
    _limit: number | string;
    'articleCategory.slug'?: string;
    title_contains?: string;
    _sort: string;
  } = {
    _start: (Number(page) - 1) * Number(perPage),
    _limit: perPage,
    'articleCategory.slug': category || undefined,
    title_contains: title || undefined,
    _sort: 'publishDate:DESC,id:DESC',
  };

  const queryString = qs.stringify(query, { indices: false });

  const data = await fetchAPI<IArticle[]>(`/articles?${queryString}`);

  return !data || data.length < 1 ? null : data;
}

export async function getArticlesCount({
  category,
  title,
}: {
  category: string;
  title: string;
}) {
  const query: {
    'articleCategory.slug'?: string;
    title_contains?: string;
  } = {
    'articleCategory.slug': category || undefined,
    title_contains: title || undefined,
  };

  const queryString = qs.stringify(query, { indices: false });

  const data = await fetchAPI<number>(`/articles/count?${queryString}`);

  return data;
}

/**
 * -----------------------------------------------------------------------------
 *  Menu
 * -----------------------------------------------------------------------------
 */
export async function getMenu() {
  const data = await fetchAPI<IMenu>(`/menu`);
  return data;
}

/**
 * -----------------------------------------------------------------------------
 *  Footer
 * -----------------------------------------------------------------------------
 */
export async function getFooter() {
  const data = await fetchAPI<IFooter>(`/footer`);
  return data;
}

/**
 * -----------------------------------------------------------------------------
 *  Sidebar
 * -----------------------------------------------------------------------------
 */
export async function getSidebar() {
  const data = await fetchAPI<ISidebar>(`/sidebar`);
  return data;
}

/**
 * -----------------------------------------------------------------------------
 *  Collection Settings
 * -----------------------------------------------------------------------------
 */
export async function getCollectionSettings() {
  const data = await fetchAPI<ICollectionSettings>(`/collection-settings`);
  return data;
}

/**
 * -----------------------------------------------------------------------------
 *  People
 * -----------------------------------------------------------------------------
 */
export async function getPersonBySlug(slug: string) {
  const data = await fetchAPI<IPerson[]>(`/people?slug=${slug}`);
  return !data || data.length < 1 ? null : data[0];
}

export async function getAllPeople(limit: number = -1) {
  const data = await fetchAPI<IPerson[]>(`/people?_limit=${limit}`);
  return !data || data.length < 1 ? null : data;
}

export async function getJudges({
  page,
  perPage,
  discipline,
  award,
}: {
  page: number | string;
  perPage: number | string;
  discipline: string;
  award: string;
}) {
  const query: {
    _start: number;
    _limit: number | string;
    'discipline.name'?: string;
    'judgeAwards.year'?: string;
    judgeAwards_null: boolean;
  } = {
    _start: (Number(page) - 1) * Number(perPage),
    _limit: perPage,
    'discipline.name': discipline || undefined,
    'judgeAwards.year': award || undefined,
    judgeAwards_null: false,
  };

  const queryString = qs.stringify(query, { indices: false });

  const data = await fetchAPI<IPerson[]>(`/people?${queryString}`);

  return !data || data.length < 1 ? null : data;
}

export async function getJudgesCount({
  discipline,
  award,
}: {
  discipline: string;
  award: string;
}) {
  const query: {
    'discipline.name'?: string;
    'judgeAwards.year'?: string;
    judgeAwards_null: boolean;
  } = {
    'discipline.name': discipline || undefined,
    'judgeAwards.year': award || undefined,
    judgeAwards_null: false,
  };

  const queryString = qs.stringify(query, { indices: false });

  const data = await fetchAPI<number>(`/people/count?${queryString}`);

  return data;
}

/**
 * -----------------------------------------------------------------------------
 *  Categories
 * -----------------------------------------------------------------------------
 */
export async function getCategories(limit: number = -1) {
  const data = await fetchAPI<IArticleCategory[]>(
    `/article-categories?_limit=${limit}`
  );

  return !data || data.length < 1 ? null : data;
}

/**
 * -----------------------------------------------------------------------------
 *  Events
 * -----------------------------------------------------------------------------
 */
export async function getEventBySlug(slug: string) {
  const data = await fetchAPI<IEvent[]>(`/events?slug=${slug}`);
  return !data || data.length < 1 ? null : data[0];
}

export async function getAllEvents(limit: number = -1) {
  const data = await fetchAPI<IEvent[]>(`/events?_limit=${limit}`);
  return !data || data.length < 1 ? null : data;
}

export async function getPastEvents(limit: number = -1) {
  const todayString = getTodayString();

  const query: {
    _limit: number | string;
    _sort: string;
    _where: {
      _or: Array<Array<StrapiDateQuery | StrapiEndDateQuery>>;
    };
  } = {
    _limit: limit,
    _sort: 'date:DESC,id:DESC',
    _where: {
      _or: [
        [{ date_lt: todayString }, { endDate_lt: todayString }],
        [{ date_lt: todayString }, { endDate_null: true }],
      ],
    },
  };

  const queryString = qs.stringify(query, { encode: false });

  const data = await fetchAPI<IEvent[]>(`/events?${queryString}`);

  return !data || data.length < 1 ? null : data;
}

export async function getFutureEvents(limit: number = -1) {
  const todayString = getTodayString();

  const query: {
    _limit: number | string;
    _sort: string;
    date_gte?: string;
  } = {
    _limit: limit,
    _sort: 'id:DESC',
    date_gte: todayString,
  };

  const queryString = qs.stringify(query, { encode: false });

  const data = await fetchAPI<IEvent[]>(`/events?${queryString}`);

  return !data || data.length < 1 ? null : data;
}

export async function getFutureAndOngoingEvents(limit: number = -1) {
  const todayString = getTodayString();

  const query: {
    _limit: number | string;
    _sort: string;
    _where: {
      _or: Array<Array<StrapiDateQuery | StrapiEndDateQuery>>;
    };
  } = {
    _limit: limit,
    _sort: 'date:DESC,id:DESC',
    _where: {
      _or: [
        [{ date_lte: todayString }, { endDate_gte: todayString }],
        [{ date_gte: todayString }],
        [{ date_null: true }],
      ],
    },
  };

  const queryString = qs.stringify(query, { encode: false });

  const data = await fetchAPI<IEvent[]>(`/events?${queryString}`);

  return !data || data.length < 1 ? null : data;
}

/**
 * -----------------------------------------------------------------------------
 *  Products
 * -----------------------------------------------------------------------------
 */
export async function getAllProducts(limit: number = -1) {
  const data = await fetchAPI<IProduct[]>(`/products?_limit=${limit}`);
  return !data || data.length < 1 ? null : data;
}

/**
 * -----------------------------------------------------------------------------
 *  Projects
 * -----------------------------------------------------------------------------
 */
export async function getProjectPreviewById(id: number) {
  const data = await fetchAPI<IProject>(
    `/projects/${id}?_publicationState=preview`
  );
  return data || null;
}

export async function getProjectBySlug(slug: string) {
  const data = await fetchAPI<IProject[]>(`/projects?slug=${slug}`);
  return !data || data.length < 1 ? null : data[0];
}

export async function getWinningProjects({
  theme,
  discipline,
  perPage,
  page,
  search,
}: {
  theme: string;
  discipline: string;
  perPage: number | string;
  page: number | string;
  search: string;
}) {
  const query: {
    awarded_in: IProject['awarded'][];
    _start: number;
    _limit: number | string;
    'discipline.name'?: string;
    'projectTheme.name'?: string;
    keywords_contains?: string;
    _where?: {};
  } = {
    awarded_in: ['HighlyCommended', 'Bronze', 'Silver', 'Gold'],
    _start: (Number(page) - 1) * Number(perPage),
    _limit: perPage,
    'discipline.name': discipline || undefined,
    'projectTheme.name': theme || undefined,
    keywords_contains: search || undefined,
    // _where: {
    //   _or: [
    //     // { 'projectEntrants.firstName_contains': search || undefined },
    //     // { 'projectEntrants.lastName_contains': search || undefined },
    //     // { 'education.university_contains': search || undefined },
    //     { placeOfStudy_contains: search || undefined },
    //     { author_contains: search || undefined },
    //   ],
    // },
  };

  const queryString = qs.stringify(query, { indices: true });

  const data = await fetchAPI<IProject[]>(`/projects?${queryString}`);

  return !data || data.length < 1 ? null : data;
}

export async function getWinningProjectsCount({
  theme,
  discipline,
  search,
}: {
  theme: string;
  discipline: string;
  search: string;
}) {
  const query: {
    awarded_in: IProject['awarded'][];
    'discipline.name'?: string;
    'projectTheme.name'?: string;
    keywords_contains?: string;
    _where?: {};
  } = {
    awarded_in: ['HighlyCommended', 'Bronze', 'Silver', 'Gold'],
    'discipline.name': discipline || undefined,
    'projectTheme.name': theme || undefined,
    keywords_contains: search || undefined,
    // _where: {
    //   _or: [
    //     // { 'projectEntrants.firstName_contains': search || undefined },
    //     // { 'projectEntrants.lastName_contains': search || undefined },
    //     // { 'education.university_contains': search || undefined },
    //     { placeOfStudy_contains: search || undefined },
    //     { author_contains: search || undefined },
    //   ],
    // },
  };

  const queryString = qs.stringify(query, { indices: false });

  const data = await fetchAPI<number>(`/projects/count?${queryString}`);

  return data;
}

export async function getShortlistedProjects({
  page,
  perPage,
  search,
}: {
  perPage: number | string;
  page: number | string;
  search: string;
}) {
  const query: {
    awarded_in: IProject['awarded'][];
    _start: number;
    _limit: number | string;
    keywords_contains?: string;
    _where?: {};
  } = {
    awarded_in: ['Shortlisted', 'Commended'],
    _start: (Number(page) - 1) * Number(perPage),
    _limit: perPage,
    keywords_contains: search || undefined,
    // _where: {
    //   _or: [
    //     // { 'projectEntrants.firstName_contains': search || undefined },
    //     // { 'projectEntrants.lastName_contains': search || undefined },
    //     // { 'education.university_contains': search || undefined },
    //     { placeOfStudy_contains: search || undefined },
    //     { author_contains: search || undefined },
    //   ],
    // },
  };

  const queryString = qs.stringify(query, { indices: true });

  const data = await fetchAPI<IProject[]>(`/projects?${queryString}`);

  return !data || data.length < 1 ? null : data;
}

export async function getShortlistedProjectsCount({
  search,
}: {
  search: string;
}) {
  const query: {
    awarded_in: IProject['awarded'][];
    keywords_contains?: string;
    _where?: {};
  } = {
    awarded_in: ['Shortlisted', 'Commended'],
    keywords_contains: search || undefined,
    // _where: {
    //   _or: [
    //     // { 'projectEntrants.firstName_contains': search || undefined },
    //     // { 'projectEntrants.lastName_contains': search || undefined },
    //     // { 'education.university_contains': search || undefined },
    //     { placeOfStudy_contains: search || undefined },
    //     { author_contains: search || undefined },
    //   ],
    // },
  };

  const queryString = qs.stringify(query, { indices: false });

  const data = await fetchAPI<number>(`/projects/count?${queryString}`);

  return data;
}

export async function submitProject(formData: FormData): Promise<{
  success: boolean;
  data?: any;
  message?: string;
  error?: string;
}> {
  try {
    const resp = await fetch(getStrapiURL(`/projects`), {
      method: 'POST',
      body: formData,
    });
    const data = await resp.json();
    if (resp.ok) {
      return {
        success: true,
        message: data.message,
        data,
      };
    } else {
      return {
        success: false,
        message: data.message,
        error: data.error,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: String(e),
    };
  }
}

export async function updateProject(
  id: number,
  project: IProject
): Promise<{
  success: boolean;
  data?: any;
  message?: string;
  error?: string;
}> {
  try {
    const resp = await fetch(getStrapiURL(`/projects/${id}`), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project),
    });
    const data = await resp.json();
    if (resp.ok) {
      return {
        success: true,
        message: data.message,
        data,
      };
    } else {
      return {
        success: false,
        message: data.message,
        error: data.error,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: String(e),
    };
  }
}

/**
 * -----------------------------------------------------------------------------
 *  Award
 * -----------------------------------------------------------------------------
 */
export async function getAwards(limit: number = -1) {
  const data = await fetchAPI<IAward[]>(`/awards?_limit=${limit}`);
  return !data || data.length < 1 ? null : data;
}

/**
 * -----------------------------------------------------------------------------
 *  Award Briefs
 * -----------------------------------------------------------------------------
 */
export async function getAwardBriefs(limit: number = -1) {
  const data = await fetchAPI<IAwardBrief[]>(`/award-briefs?_limit=${limit}`);
  return !data || data.length < 1 ? null : data;
}

/**
 * -----------------------------------------------------------------------------
 *  Disciplines
 * -----------------------------------------------------------------------------
 */
export async function getDisciplines(limit: number = -1) {
  const data = await fetchAPI<IDiscipline[]>(`/disciplines?_limit=${limit}`);
  return !data || data.length < 1 ? null : data;
}

/**
 * -----------------------------------------------------------------------------
 *  Mailchimp
 * -----------------------------------------------------------------------------
 */

export async function mailChimpSubscribe(email: string, name: string) {
  const url = getStrapiURL('/mailchimp');

  // prepare
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName: name,
      email: email,
    }),
  };

  // subscribe
  const resp = await fetch(url, request);
  const data = await resp.json();

  // success
  if (resp.status === 200) {
    return {
      success: true,
      message: 'You have been subscribed',
    };
  }

  // confict
  if (resp.status === 409) {
    return {
      success: false,
      message: data.message,
    };
  }

  // other errors
  return {
    success: false,
    message: data.message,
    error: data.error || null,
  };
}

/**
 * -----------------------------------------------------------------------------
 *  Project Themes
 * -----------------------------------------------------------------------------
 */
export async function getProjectThemes(limit: number = -1) {
  const data = await fetchAPI<IProjectTheme[]>(
    `/project-themes?_limit=${limit}`
  );
  return !data || data.length < 1 ? null : data;
}

/**
 * -----------------------------------------------------------------------------
 *  Membership details
 * -----------------------------------------------------------------------------
 */
export async function submitMembership(formData: FormData): Promise<{
  success: boolean;
  data?: any;
  message?: string;
  error?: string;
}> {
  try {
    const resp = await fetch(getStrapiURL(`/memberships`), {
      method: 'POST',
      body: formData,
    });
    const data = await resp.json();
    if (resp.ok) {
      return {
        success: true,
        message: data.message,
        data,
      };
    } else {
      return {
        success: false,
        message: data.message,
        error: data.error,
      };
    }
  } catch (e) {
    return {
      success: false,
      error: String(e),
    };
  }
}

export async function createAwardsEntryDiscountCodes(order: any) {
  const url = getStrapiURL('/awards-entry-discounts');

  // prepare
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(order),
  };

  const resp = await fetch(url, request);
  const data = await resp.json();

  // success
  if (resp.status === 200) {
    return {
      success: true,
      discountCodes: data.discountCodes,
    };
  }

  // other errors
  return {
    success: false,
    message: data.message,
    error: data.error || null,
  };
}

export async function getDiscountByCode(code: string) {
  const url = getStrapiURL(`/awards-entry-discounts/${code}`);
  const resp = await fetch(url);
  const data = await resp.json();

  // success
  if (resp.status === 200) {
    return {
      success: true,
      discount: data.discount as Discount,
    };
  }

  // other errors
  return {
    success: false,
    message: data.message,
    error: data.error || null,
  };
}

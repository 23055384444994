import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as strapi from "services/strapi";
import { IApiRequest } from "types/ApiRequest";
import { IMenu } from "types/generated/strapi";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchMenu = createAsyncThunk("menu/fetch", async () => {
  const response = await strapi.getMenu();
  return response;
});

export interface IMenuSlice {
  menuRequest: IApiRequest<IMenu>;
}

const initialState: IMenuSlice = {
  menuRequest: {
    status: "initial",
  },
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenu.pending, (state) => {
        state.menuRequest.status = "pending";
      })
      .addCase(fetchMenu.fulfilled, (state, action) => {
        state.menuRequest.status = "fulfilled";
        state.menuRequest.data = action.payload;
      })
      .addCase(fetchMenu.rejected, (state, action) => {
        state.menuRequest.status = "rejected";
        state.menuRequest.error = action.error;
      });
  },
});

export default menuSlice.reducer;

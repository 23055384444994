import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IPage } from 'types/generated/strapi';

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchPage = createAsyncThunk(
  'page/fetch',
  async (slug: string) => {
    const response = await strapi.getPageBySlug(slug);
    return response;
  }
);

export interface IPageSlice {
  pageRequest: IApiRequest<IPage>;
}

const initialState: IPageSlice = {
  pageRequest: {
    status: 'initial',
  },
};

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchPage.pending, (state) => {
        state.pageRequest.status = 'pending';
        state.pageRequest.data = undefined;
      })
      .addCase(fetchPage.fulfilled, (state, action) => {
        state.pageRequest.status = 'fulfilled';
        state.pageRequest.data = action.payload;
      })
      .addCase(fetchPage.rejected, (state, action) => {
        state.pageRequest.status = 'rejected';
        state.pageRequest.error = action.error;
      });
  },
});

export default pageSlice.reducer;

import { FunctionComponent } from 'react';
import { IAwardsSelector } from 'types/generated/strapi';
import styles from './index.module.css';
import StrapiLink from 'components/shared/StrapiLink';

interface IAwardsSelectorProps {
  section: IAwardsSelector;
}

const AwardsSelector: FunctionComponent<IAwardsSelectorProps> = (props) => {
  const { title, links } = props.section;
  return (
    <div className={styles.awardsSelector}>
      <div className={styles.awardsSelectorInner}>
        <div className={styles.title}>{title}</div>
        <ul className={styles.links}>
          {links.map((link) => (
            <li key={link.id} className={styles.linkItem}>
              <StrapiLink className={styles.link} link={link} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AwardsSelector;

import { FunctionComponent } from 'react';

import AboutUs from 'components/sections/AboutUs';
import FeaturedNews from 'components/sections/FeaturedNews';
import HomeHero from 'components/sections/HomeHero';
import JoinCommunity from 'components/sections/JoinCommunity';
import PageHero from 'components/sections/PageHero';
import ArticleList from 'components/sections/ArticleList';
import UpcomingEvents from 'components/sections/UpcomingEvents';
import PastEvents from 'components/sections/PastEvents';
import MembershipDetails from 'components/sections/MembershipDetails';
import TwoColumnTitleAndText from 'components/sections/TwoColumnTitleAndText';
import AwardsIntro from 'components/sections/AwardsIntro';
import AwardsTimeline from 'components/sections/AwardsTimeline';
import PeopleGrid from 'components/sections/PeopleGrid';
import LinkListColumns from 'components/sections/LinkListColumns';
import RichText from 'components/sections/RichText';
import JudgeList from 'components/sections/JudgeList';
import WinnerList from 'components/sections/WinnerList';
import ShortlistedList from 'components/sections/ShortlistedList';
import AwardsEntry from 'components/sections/AwardsEntry';
import MembershipForm from 'components/sections/MembershipForm';
import TitleSubtitle from 'components/sections/TitleSubtitle';
import EntryDetails from 'components/sections/EntryDetails';
import EntryForm from 'components/sections/EntryForm';
import AwardsCallToAction from 'components/sections/AwardsCallToAction';
import AwardsSelector from 'components/sections/AwardsSelector';
import DonationBlack from 'components/sections/DonationBlack';
import DonationWhite from 'components/sections/DonationWhite';
// import Blank from 'components/sections/Blank';

import { IPage } from 'types/generated/strapi';

type sectionName = IPage['sections'][number]['__component'];

const sections: Record<sectionName, FunctionComponent<any>> = {
  'sections.home-hero': HomeHero,
  'sections.about-us': AboutUs,
  'sections.featured-news': FeaturedNews,
  'sections.join-community': JoinCommunity,
  'sections.page-hero': PageHero,
  'sections.article-list': ArticleList,
  'sections.upcoming-events': UpcomingEvents,
  'sections.past-events': PastEvents,
  'sections.membership-benefits': MembershipDetails,
  'sections.two-column-title-and-text': TwoColumnTitleAndText,
  'sections.awards-intro': AwardsIntro,
  'sections.awards-timeline': AwardsTimeline,
  'sections.people-grid': PeopleGrid,
  'sections.link-list-columns': LinkListColumns,
  'sections.rich-text': RichText,
  'sections.judge-list': JudgeList,
  'sections.winner-list': WinnerList,
  'sections.shortlisted-list': ShortlistedList,
  'sections.awards-entry': AwardsEntry,
  'sections.membership-details': MembershipForm,
  'sections.title-subtitle': TitleSubtitle,
  'sections.enter-details': EntryDetails,
  'sections.entry-form': EntryForm,
  'sections.awards-call-to-action': AwardsCallToAction,
  'sections.awards-selector': AwardsSelector,
  'sections.donation-black': DonationBlack,
  'sections.donation-white': DonationWhite,
};

export default sections;

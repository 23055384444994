import { FunctionComponent, useEffect } from 'react';
import clsx from 'clsx';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import { fetchEventsPast } from 'redux/eventSlice';

import truncateString from 'utils/truncateString';
import formatSlug from 'utils/formatSlug';

import ReactMarkdown from 'react-markdown';
import EventCard from 'components/shared/EventCard';

import { IEvent, IPastEvents } from 'types/generated/strapi';

import styles from './index.module.css';

interface IPastEventsProps extends ConnectedProps<typeof connector> {
  section: IPastEvents;
}

const PastEvents: FunctionComponent<IPastEventsProps> = (props) => {
  const maxEventsToShow = props.section.maxEventsToShow ?? 6;

  const { events, eventsStatus, fetchEventsPast } = props;
  const { hideSectionIfEmpty, heading, blurbLeft, blurbRight } = props.section;

  // Fetch all past events once
  useEffect(() => {
    if (eventsStatus === 'initial') {
      fetchEventsPast(maxEventsToShow);
    }
  }, [eventsStatus, fetchEventsPast, maxEventsToShow]);

  const getThumbnail = (event: IEvent) => {
    return event.thumbnail || event.image;
  };

  const getBlurb = (event: IEvent) => {
    return event.blurb || truncateString(event.details);
  };

  const getReadMoreUrl = (event: IEvent) => {
    return formatSlug(`/events/${event.slug}`);
  };

  if (hideSectionIfEmpty && events.length <= 0) return null;

  return (
    <div
      className={styles.pastEvents}
      data-sidebar-target
      data-sidebar-sticky
      data-sidebar-padding-bottom="var(--space-xxl)"
    >
      <div className={styles.pastEventsInner}>
        <div className={styles.introLeft}>
          {/* Heading */}
          {heading && <h2 className={styles.heading}>{heading}</h2>}

          {/* Blurb left */}
          <ReactMarkdown
            className={clsx(styles.blurb, styles.blurbLeft)}
            children={blurbLeft || ''}
          />
        </div>

        <div className={styles.introRight}>
          {/* Blurb Right */}
          <ReactMarkdown className={styles.blurb} children={blurbRight || ''} />
        </div>

        {/* Events grid */}
        <div className={styles.eventsGrid}>
          {events.length <= 0 && (
            <div className={styles.noEvents}>No past events found</div>
          )}

          {events?.map((event, index) => {
            return (
              <EventCard
                key={`event-${index}`}
                image={getThumbnail(event)}
                title={event.title}
                blurb={getBlurb(event)}
                date={event.date}
                readMoreUrl={getReadMoreUrl(event)}
                bookingUrl={event.url}
                layout="past"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  events: state.event?.eventsPastRequest.data ?? [],
  eventsStatus: state.event?.eventsPastRequest.status,
});

const mapDispatchToProps = {
  fetchEventsPast,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PastEvents);

import { useCallback, useState } from 'react';

/**
 * Gives the user a visual feedback when the
 * 'loading' property is set to true.
 * @returns const [loading, startLoading, stopLoading]
 */
export const useLoading = () => {
  const [loading, setLoading] = useState(false);

  const startLoading = useCallback((message?: string) => {
    setLoading(true);

    document.documentElement.style.filter = 'grayscale(100%)';
    document.documentElement.style.pointerEvents = 'none';
    document.documentElement.style.cursor = 'wait';

    if (message) {
      const modal = document.createElement('div');
      modal.id = 'loading-message';
      modal.style.position = 'fixed';
      modal.style.zIndex = '999';
      modal.style.top = '50%';
      modal.style.left = '50%';
      modal.style.transform = 'translate(-50%, -50%)';
      modal.style.backgroundColor = 'white';
      modal.style.border = '2px solid black';
      modal.style.padding = '30px 60px';
      modal.style.textAlign = 'center';
      modal.innerHTML = message;
      document.documentElement.appendChild(modal);
    }
  }, []);

  const stopLoading = useCallback(() => {
    setLoading(false);

    document.documentElement.style.filter = 'none';
    document.documentElement.style.pointerEvents = '';
    document.documentElement.style.cursor = '';

    document.getElementById('loading-message')?.remove();
  }, []);

  return [loading, startLoading, stopLoading] as const;
};

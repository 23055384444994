import styles from './index.module.css';
import clsx from 'clsx';

import { IArticleCategory, IArticleList } from 'types/generated/strapi';

import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';

import { AppState } from 'redux/store';
import { connect, ConnectedProps } from 'react-redux';
import { fetchCategories } from 'redux/categorySlice';
import { fetchArticles, fetchArticlesCount } from 'redux/articleSlice';

import usePagination from 'utils/usePagination';

import ArticleCategory from 'components/shared/ArticleCategory';
import ArticleMeta from 'components/shared/ArticleMeta';
import StrapiImage from 'components/shared/StrapiImage';
import Pagination from 'components/shared/Pagination';
import useFilter from 'utils/useFilter';

interface IArticleListProps extends ConnectedProps<typeof connector> {
  section: IArticleList;
}

const ArticleList: FunctionComponent<IArticleListProps> = (props) => {
  const articlesRef = useRef<HTMLDivElement>(null);

  const {
    categories,
    categoriesStatus,
    articles,
    articlesStatus,
    articlesCount,
    fetchCategories,
    fetchArticles,
    fetchArticlesCount,
  } = props;

  // Fetch all categories once
  useEffect(() => {
    if (categoriesStatus === 'initial') {
      fetchCategories();
    }
  }, [categoriesStatus, fetchCategories]);

  // Connect pagination
  const { page, setPage, totalPages, perPage, withNextPage, withPrevPage } =
    usePagination({
      contentPerPage: 8,
      count: articlesCount,
    });

  // Connect filter by url query
  const [categoryFilter] = useFilter({
    queryName: 'category',
  });
  const [titleFilter, setTitleFilter] = useFilter({
    queryName: 'title',
  });
  const [pageFilter, setPageFilter] = useFilter({
    queryName: 'page',
  });

  const pageFilterValue = pageFilter[0] || 1;
  const titleFilterValue = titleFilter[0] || '';
  const categoryFilterValue = categoryFilter[0] || '';

  // Fetch articles
  useEffect(() => {
    fetchArticles({
      page: pageFilterValue,
      title: titleFilterValue,
      category: categoryFilterValue === 'All' ? '' : categoryFilterValue,
      perPage,
    });

    fetchArticlesCount({
      title: titleFilterValue,
      category: categoryFilterValue === 'All' ? '' : categoryFilterValue,
    });
  }, [
    pageFilterValue,
    titleFilterValue,
    categoryFilterValue,
    perPage,
    fetchArticles,
    fetchArticlesCount,
  ]);

  // Handle search field
  const onChangeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPageFilter(1);
    setTitleFilter(e.target.value);
  };

  const isActiveCategory = (slug: string) => categoryFilter.includes(slug);

  // Set page on query page change
  useEffect(() => {
    if (pageFilter[0]) {
      setPage(Number(pageFilter[0]));
    } else {
      setPage(1);
    }
  }, [pageFilter, setPage]);

  // Scroll to list container on pagination button click
  const onPaginationClick = () => {
    articlesRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const getArticleCategoryName = (
    articleCategory: number | IArticleCategory | undefined
  ) => {
    if (typeof articleCategory === 'number') return '';
    return articleCategory?.name || '';
  };

  const categoriesSorted = useMemo(() => {
    if (!categories.length) return [];
    return [...categories].sort((a, b) => a.name.localeCompare(b.name));
  }, [categories]);

  return (
    <div
      className={styles.articleList}
      data-sidebar-target
      data-sidebar-sticky
      data-sidebar-padding-top="var(--space-xl)"
      data-sidebar-padding-bottom="var(--space-xl)"
    >
      <div className={styles.articleListInner}>
        {/* Head with tags and search */}
        <div className={styles.articlesListHead}>
          {categoriesSorted.length > 0 && (
            <div className={styles.categories}>
              <ArticleCategory
                name="All"
                slug="All"
                slugPageWithList="news"
                className={clsx(
                  styles.category,
                  isActiveCategory('All') && styles.categoryActive
                )}
                active={isActiveCategory('All')}
              />
              {categoriesSorted.map(
                (category) =>
                  category && (
                    <ArticleCategory
                      key={category.slug}
                      name={category.name}
                      slug={category.slug}
                      slugPageWithList="news"
                      className={clsx(
                        styles.category,
                        isActiveCategory(category.slug) && styles.categoryActive
                      )}
                      active={isActiveCategory(category.slug)}
                    />
                  )
              )}
            </div>
          )}
          <div className={styles.inputGroup}>
            <input
              type="text"
              className={styles.searchInput}
              value={titleFilter}
              onChange={onChangeSearchInput}
              placeholder="Search for"
            />
          </div>
        </div>

        {/* Articles grid */}
        <div ref={articlesRef} className={styles.articles}>
          {articlesStatus === 'fulfilled' && !articles.length && (
            <div>No matching articles found</div>
          )}

          {articles.map((article) => {
            const articleCategoryName = getArticleCategoryName(
              article.articleCategory
            );

            return (
              <Link
                to={article.slug}
                className={styles.article}
                key={article.id}
              >
                <div className={styles.articleImageWrapper}>
                  <StrapiImage
                    className={styles.articleImage}
                    image={article.thumbnail}
                    format="medium"
                  />
                </div>

                <div className={styles.articleInner}>
                  <div className={styles.articleCategoryWrapper}>
                    {articleCategoryName && (
                      <ArticleCategory name={articleCategoryName} tag="span" />
                    )}
                  </div>
                  <div className={styles.articleContent}>
                    <ArticleMeta article={article} />
                    <h2 className={styles.articleTitle}>{article.title}</h2>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

        {/* Pagination */}
        <Pagination
          page={page}
          totalPages={totalPages}
          withPrevPage={withPrevPage}
          withNextPage={withNextPage}
          onClick={onPaginationClick}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  categories: state.category.categoriesRequest.data ?? [],
  categoriesStatus: state.category.categoriesRequest.status,
  articles: state.article.articlesRequest.data ?? [],
  articlesStatus: state.article.articlesRequest.status,
  articlesCount: state.article?.articlesCountRequest.data ?? 0,
});

const mapDispatchToProps = {
  fetchCategories,
  fetchArticles,
  fetchArticlesCount,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ArticleList);

import { FunctionComponent, useMemo } from 'react';
import {
  getImageFormat,
  getImageWidth,
  getImageHeight,
  IFile,
  File,
} from '@casbah/strapi-helpers';
import { getStrapiMediaUrl } from 'services/strapi';

interface IStrapiImageProps {
  image: IFile;
  format: File.Format;
  waterfall?: boolean;
  className?: string;
  alt?: string;
}

const StrapiImage: FunctionComponent<IStrapiImageProps> = ({
  image,
  format,
  className,
  waterfall = false,
  alt,
}) => {
  const imageUrl: string = useMemo(() => {
    return (
      getImageFormat({
        file: image,
        format,
        waterfall,
      }) || ''
    );
  }, [image, format, waterfall]);

  const imageHeight: number | undefined = useMemo(() => {
    return getImageHeight({
      file: image,
      format,
      waterfall,
    });
  }, [image, format, waterfall]);

  const imageWidth: number | undefined = useMemo(() => {
    return getImageWidth({
      file: image,
      format,
      waterfall,
    });
  }, [image, format, waterfall]);

  const imageFullPath = useMemo(() => {
    return getStrapiMediaUrl(imageUrl);
  }, [imageUrl]);

  const altText = useMemo(() => {
    return alt || image.alternativeText || '';
  }, [alt, image]);

  return (
    <img
      className={className}
      src={imageFullPath}
      width={imageWidth}
      height={imageHeight}
      alt={altText}
      loading="lazy"
    />
  );
};

export default StrapiImage;

import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import initGtm from 'utils/gtm';
import ScrollToTop from 'components/shared/ScrollToTop';

import Page from 'pages/Page/Page';
import Article from 'pages/Article/Article';
import Person from 'pages/Person/Person';
import Event from 'pages/Event/Event';
import Project from 'pages/Project/Project';

import Error404 from 'pages/Error404/Error404';

import styles from './App.module.css';
import Preview from 'pages/Preview/Preview';
import Fallback from 'pages/Fallback/Page';

function App() {
  useEffect(() => {
    initGtm();
  }, []);

  return (
    <div className={styles.app}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Page />} />
          <Route path="/fallback" element={<Fallback />} />
          <Route path="/:slug" element={<Page />} />
          <Route path="/news/:slug" element={<Article />} />
          <Route path="/people/:slug" element={<Person />} />
          <Route path="/events/:slug" element={<Event />} />
          <Route path="/winners/:slug" element={<Project />} />
          <Route path="/preview" element={<Preview />} />
          <Route element={<Error404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import { FunctionComponent, useMemo } from 'react';
import clsx from 'clsx';
import styles from './index.module.css';
import { createChunks } from 'utils/createChunks';

interface IHiglightedSpansProps {
  text: string;
  className?: string;
}

const HiglightedSpans: FunctionComponent<IHiglightedSpansProps> = (props) => {
  const { text, className = '' } = props;

  const highlightedSpans: string[] = useMemo(() => {
    const maxWordsPerText = 3;
    const words = text.split(' ').length;

    if (words <= maxWordsPerText) return [text];

    return createChunks(text.split(' '), 3).map((c: string[]) => c.join(' '));
  }, [text]);

  return (
    <span>
      {highlightedSpans.map((c: string, index) => {
        const words = c.split(' ').length;
        return (
          <span
            key={index}
            className={clsx(
              styles.highlighted,
              {
                [styles.small]: words === 1,
                [styles.medium]: words === 2,
                [styles.large]: words === 3,
                [styles.xlarge]: words > 3,
              },
              className
            )}
          >
            {c}
          </span>
        );
      })}
    </span>
  );
};

export default HiglightedSpans;

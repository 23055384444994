import { FunctionComponent, useMemo, useCallback } from 'react';

import formatSlug from 'utils/formatSlug';
import { useSnapActiveItem } from 'utils/useSnapActiveItem';

import StrapiImage from 'components/shared/StrapiImage';

import { IPeopleGrid, IPerson } from 'types/generated/strapi';

import styles from './index.module.css';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

interface IPeopleGridProps {
  section: IPeopleGrid;
}

interface IPersonWithRotation extends IPerson {
  rotation: number;
}

// Declare types for inline custom CSS var properties
declare module 'csstype' {
  interface Properties {
    '--rotation'?: string;
  }
}

const getRandomRotation = () => {
  return Math.floor(Math.random() * (360 - 0) + 0);
};

const PeopleGrid: FunctionComponent<IPeopleGridProps> = (props) => {
  const { heading } = props.section;

  const checkPosition = useCallback((gridElement: Element, grid: Element) => {
    // return (
    //   Math.abs(
    //     gridElement.getBoundingClientRect().left - grid.getBoundingClientRect().left
    //   ) < 10
    // );

    return true;
  }, []);

  const [carouselRef, activeCarouselItemIndex] =
    useSnapActiveItem(checkPosition);

  const people: IPersonWithRotation[] = useMemo(() => {
    return props.section.people
      .map((person) => person.person)
      .filter((person): person is IPerson => !!person)
      .map((person) => {
        return {
          ...person,
          rotation: getRandomRotation(),
        };
      });
  }, [props.section.people]);

  if (people.length <= 0) return null;

  return (
    <div
      className={styles.peopleGrid}
      data-sidebar-target
      data-sidebar-sticky
      data-sidebar-padding-top="var(--space-xxl)"
      data-sidebar-padding-bottom="var(--space-xxl)"
    >
      <div className={styles.peopleGridInner}>
        {/* Heading */}
        {heading && <h2 className={styles.heading}>{heading}</h2>}

        {/* Grid */}
        <div ref={carouselRef} className={styles.grid}>
          {people.map((person, index) => {
            return (
              <div
                key={`person-${person.id}`}
                className={clsx(
                  styles.person,
                  activeCarouselItemIndex === index && styles.personActive
                )}
              >
                <Link
                  to={formatSlug(`/people/${person.slug}`)}
                  className={styles.personLink}
                  style={{ '--rotation': `${person.rotation}deg` }}
                >
                  {/* Image */}
                  {person.image && (
                    <div className={styles.imageWrapper}>
                      <StrapiImage
                        className={styles.image}
                        image={person.image}
                        format="small"
                      />
                    </div>
                  )}

                  {/* Name */}
                  <div className={styles.personName}>{person.name}</div>

                  {/* Role */}
                  {person.role && (
                    <div className={styles.personRole}>{person.role}</div>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PeopleGrid;

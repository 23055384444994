export const stepsIndividual = [
  {
    name: 'project',
    title: 'Tell us about your project',
    subtitle: '',
  },
  {
    name: 'yourself',
    title: 'Tell us about yourself',
    subtitle: '',
  },
  {
    name: 'education',
    title: 'Education details',
    subtitle: '',
  },
];

export const stepsGroup = [
  {
    name: 'project',
    title: 'Tell us about your project',
    subtitle: '',
  },
  {
    name: 'yourself',
    title: 'Tell us about yourself',
    subtitle:
      'Please fill the form for each of team members by clicking “add another entrant”',
  },
  {
    name: 'reviewyourself',
    title: 'Review your team',
    subtitle: '',
  },
  {
    name: 'education',
    title: 'Education details',
    subtitle: '',
  },
];

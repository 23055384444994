import { useEffect, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';

import { connect, ConnectedProps } from 'react-redux';
import { fetchPage } from 'redux/pageSlice';
import { AppState } from 'redux/store';

import { IPage } from 'types/generated/strapi';

import Error404 from 'pages/Error404/Error404';

import Layout from 'components/shared/Layout';
import SeoHead from 'components/shared/SeoHead';

import sections from 'constants/sections';
import generateStaticPage from 'utils/generateStaticPage';
import Loading from 'components/shared/Loading';

const Page: FunctionComponent<IPageProps> = (props) => {
  const { slug } = useParams();
  const { page, pageStatus, fetchPage } = props;

  // Convert page data into string, to prevent infinite loop in useEffect
  const pageString = JSON.stringify(page || '');

  useEffect(() => {
    (async () => {
      const page = JSON.parse(pageString) as IPage | null | undefined;

      if (page?.slug === 'home' && slug === undefined) return;
      if (!page || page?.slug !== slug) {
        await fetchPage(slug || 'home');
      }

      generateStaticPage();
    })();
  }, [slug, pageString, fetchPage]);

  if (page === undefined || pageStatus === 'pending') return <Loading />;
  if (page === null) return <Error404 />;

  return (
    <Layout>
      <SeoHead seo={page.SEO} />

      {props.sections.map((section) => {
        const Section = sections[section.__component];

        return Section ? (
          <Section
            key={`${section.__component}${section.id}`}
            section={section}
          />
        ) : null;
      })}
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  sections: state.page?.pageRequest?.data?.sections ?? [],
  page: state.page?.pageRequest?.data,
  pageStatus: state.page?.pageRequest?.status,
});

const mapDispatchToProps = {
  fetchPage: fetchPage,
};

interface IPageProps extends ConnectedProps<typeof connector> {}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Page);

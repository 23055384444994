import {
  cleanDoubleSlashes,
  withLeadingSlash,
  withoutTrailingSlash,
} from 'ufo';

/**
 * Format a string into a normalised slug
 *
 * examples:
 *   'home' -> '/home
 *   '/link/to//page/' -> '/link/to/page'
 */

const formatSlug = (slug: string) => {
  if (!slug || typeof slug !== 'string') return '';

  return cleanDoubleSlashes(withLeadingSlash(withoutTrailingSlash(slug)));
};

export default formatSlug;

import { IEntrantFormData } from './IEntrantFormData';
import { IFormData } from './IFormData';

const test = window.location.href.includes('localhost');

export const initialFormData: IFormData = {
  awardBrief: test ? 1 : undefined,
  projectTheme: test ? 1 : undefined,
  discipline: test ? 1 : undefined,
  projectTitle: test ? 'Test' : '',
  websites: undefined,
  projectDescription: test ? 'Test' : '',
  projectEntrants: [],
  education: {
    university: test ? 'Test' : '',
    country: test ? 'Ireland' : '',
    yearOfStudy: test ? '1st Year' : '',
    tutorFirstName: test ? 'Test' : '',
    tutorLastName: test ? 'Test' : '',
    tutorEmail: test ? 'test@test.com' : '',
  },
};

export const initialEntrantFormData: IEntrantFormData = {
  firstName: test ? 'Test' : '',
  lastName: test ? 'Test' : '',
  nationality: test ? 'English' : '',
  email: test ? 'test@test.com' : '',
  telephone: test ? '123456789' : '',
  twitter: test ? '@twitter' : '',
  instagram: test ? '@instagram' : '',
  website: test ? '' : '',
};

import {
  ChangeEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './index.module.css';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import clsx from 'clsx';

interface FilePreviewProps {
  name: string;
  src: string;
  onDelete: () => void;
}
const FilePreview = (props: FilePreviewProps) => {
  const { name, src, onDelete } = props;

  return (
    <div className={styles.preview}>
      <div className={styles.previewInner}>
        {src && <img className={styles.previewImage} src={src} alt="" />}
        {!src && name && <div className={styles.previewDocument}>{name}</div>}
        <button
          onClick={onDelete}
          type="button"
          className={styles.previewDeleteButton}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

interface InputFileProps {
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  form?: string;
  accept?: string;
  buttonLabel?: string;
  filesRef: MutableRefObject<File[]>;
  max?: number;
  min?: number;
  maxSize: number;
  multiple?: boolean;
  classes?: {
    group?: string;
    label?: string;
  };
}

const InputFile = (props: InputFileProps) => {
  const {
    label,
    onChange,
    required,
    form,
    buttonLabel = 'Upload',
    accept,
    filesRef,
    max,
    min,
    maxSize,
    multiple = true,
    classes,
  } = props;

  const [previewData, setPreviewData] = useState<
    { name: string; src: string }[]
  >([]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (files: FileList | null) => {
    const imagesExtension = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp',
    ];

    if (!files?.length) {
      filesRef.current = [];
      setPreviewData([]);
      return;
    }

    const filesAsArray = Array.from(files);

    filesRef.current = filesAsArray;

    const data = filesAsArray.map((file) => {
      if (!imagesExtension.includes(file.type))
        return { name: file.name, src: '' };
      return { name: file.name, src: URL.createObjectURL(file) };
    });

    return setPreviewData(data);
  };

  const deleteFile = (index: number) => {
    filesRef.current.splice(index, 1);

    setPreviewData((previewData) => {
      const cloned = [...previewData];
      cloned.splice(index, 1);
      return cloned;
    });
  };

  // Validation
  useEffect(() => {
    let message = '';
    if (required && previewData.length === 0) message = 'Required';
    if (multiple && min && previewData.length < min)
      message = `Minimum ${min} files`;
    if (multiple && max && previewData.length > max)
      message = `Maximum ${max} files`;
    const files = Array.from(inputRef.current?.files ?? []);
    if (files.some((x) => x.size / 1024 / 1024 > maxSize))
      message = `Each file size can not exceed ${maxSize}MB`;
    inputRef.current?.setCustomValidity(message);
  }, [previewData.length, required, multiple, max, min, maxSize]);

  return (
    <div className={clsx(styles.group, classes?.group)}>
      {label && (
        <div className={clsx(styles.label, classes?.label)}>{label}</div>
      )}
      <div className={styles.inner}>
        {!!previewData.length && (
          <div className={styles.previews}>
            {previewData.map((fileData, index) => (
              <FilePreview
                key={index}
                name={fileData.name}
                src={fileData.src}
                onDelete={() => deleteFile(index)}
              />
            ))}
          </div>
        )}

        <button
          type="button"
          className={styles.button}
          onClick={() => inputRef.current?.click()}
        >
          {buttonLabel}
        </button>
        <input
          className={styles.input}
          form={form}
          ref={inputRef}
          type="file"
          multiple={multiple}
          accept={accept}
          onChange={(e) => {
            handleChange(e.target?.files);
            onChange && onChange(e);
          }}
        />
      </div>
    </div>
  );
};

export default InputFile;

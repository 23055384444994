import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { IMembershipDetails } from 'types/generated/strapi';
import styles from './index.module.css';
import * as strapi from 'services/strapi';
import pandotSrc from 'assets/img/paintdot-membership-details.jpg';
import InputFile from 'components/shared/InputFile';
import Input from 'components/shared/Input';
import Textarea from 'components/shared/Textarea';
import Select from 'components/shared/Select';
import { IMembershipFormData } from './IMembershipDetailsFormData';
import {
  getSnipcart,
  openCheckout,
  subscribeSnipcartOrder,
} from 'services/snipcart';
import { useLoading } from 'utils/useLoading';

const MembershipForm = (props: { section: IMembershipDetails }) => {
  const { section } = props;
  const navigate = useNavigate();
  const logoRef = useRef<File[]>([]);
  const [, startLoading, stopLoading] = useLoading();
  const [errorMessage, setError] = useState('');
  const [formData, setFormData] = useState<IMembershipFormData>({
    id: '',
    token: '',
    email: '',
    organizationName: '',
    comments: '',
    referral: '',
    referralOther: '',
    contacts: [],
    finished: true,
  });

  const [newContactFormData, setNewContactFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [membershipType, setMembershipType] = useState<
    'individual' | 'business' | 'education' | ''
  >('');
  const [maxContacts, setMaxContacts] = useState(1);

  const getMembershipTypeFromProductName = useCallback((product: string) => {
    return product.includes('Individual')
      ? 'individual'
      : product.includes('Business, Agency or Social Enterprise')
      ? 'business'
      : product.includes('Education')
      ? 'education'
      : '';
  }, []);

  useEffect(() => {
    var unsubscribeSnipcartOrder: () => void | undefined;
    (async () => {
      var snipcart = await getSnipcart();
      unsubscribeSnipcartOrder = subscribeSnipcartOrder(
        snipcart,
        async (order: any) => {
          startLoading();
          // Submit membership to Strapi
          const requestFormData = new FormData();
          requestFormData.append(
            'data',
            JSON.stringify({
              ...formData,
              email: order.email,
              orderId: order.invoiceNumber,
              orderToken: order.token,
            })
          );
          const logo = logoRef.current[0];
          if (logo) {
            requestFormData.append('files.organizationLogo', logo, logo.name);
          }
          const result = await strapi.submitMembership(requestFormData);
          if (result.success) {
            window.location.replace(`/${section?.thankYouPage?.slug || ''}`);
          } else {
            setError(
              result.message ||
                'Something went wrong with your submission, please contact us.'
            );
            stopLoading();
          }
        },
        (error: string) => {
          setError(error);
        }
      );
    })();
    // Cleanup
    return () => {
      unsubscribeSnipcartOrder?.();
    };
  }, [navigate, startLoading, stopLoading, formData, section?.thankYouPage?.slug]);

  useEffect(() => {
    (async () => {
      // Get membership product item from cart
      const snipcart = await getSnipcart();
      const membershipCartItem = snipcart.store
        .getState()
        .cart.items.items.find((c) => c.categories.includes('Membership'));
      // Not found redirect to selector
      if (!membershipCartItem) {
        navigate('/membership', { replace: true });
        return;
      }

      const { name, quantity } = membershipCartItem;
      setMembershipType(getMembershipTypeFromProductName(name));
      setMaxContacts(quantity);
      setFormData((formData) => ({
        ...formData,
        quantity: quantity || 1,
        product: name,
      }));
    })();
  }, [navigate, getMembershipTypeFromProductName]);

  const referralSelectOptions = useMemo(() => {
    return [
      { label: 'Choose', value: '' },
      { label: 'From students', value: 'student' },
      { label: 'Social Media', value: 'socialMedia' },
      { label: 'Direct Mail', value: 'directMail' },
      { label: 'Other', value: 'other' },
    ];
  }, []);

  const resetNewContactFormData = () => {
    setNewContactFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
  };

  const onFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    openCheckout();
  };

  const onNewContactFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setFormData((formData) => ({
      ...formData,
      contacts: [...formData.contacts, newContactFormData],
    }));

    resetNewContactFormData();
  };

  const withReferralOther = formData.referral === 'other';

  const withName =
    membershipType === 'business' || membershipType === 'education';
  const withLogo =
    membershipType === 'business' || membershipType === 'education';

  // Individual depends on quantity, others 15
  const withMoreContacts =
    membershipType === 'individual'
      ? maxContacts > formData.contacts.length
      : formData.contacts.length < 16;
  const withTableHead = formData.contacts.length > 0;

  return (
    <div className={styles.membershipDetails}>
      <div className={styles.membershipDetailsInner}>
        {/* Form */}
        {membershipType && (
          <>
            {/* Forms hidden */}
            <form id="membership" onSubmit={onFormSubmit} />
            <form id="contact" onSubmit={onNewContactFormSubmit} />

            {/* Title, subtitle */}
            <div className={styles.header}>
              <h2 className={styles.title}>{section.title}</h2>
              <div className={styles.subtitle}>{section.subtitle}</div>
              <img src={pandotSrc} alt="" className={styles.paintDot} />
            </div>

            {/* Name */}
            {withName && (
              <div className={styles.inputsBlock}>
                <Input
                  required={true}
                  label="University or Business, agency or social enterprise name*"
                  form="membership"
                  value={formData.organizationName}
                  onChange={(e) =>
                    setFormData((formData) => ({
                      ...formData,
                      organizationName: e.target.value,
                    }))
                  }
                />
              </div>
            )}

            {/* Logo */}
            {withLogo && (
              <div className={styles.inputsBlock}>
                <InputFile
                  label="Your logo"
                  buttonLabel="Attach your logo"
                  form="membership"
                  filesRef={logoRef}
                  multiple={false}
                  maxSize={5}
                />
              </div>
            )}

            {/* Contacts table */}
            {withTableHead && (
              <div className={styles.inputsBlock}>
                <div className={styles.label}>Contacts</div>
                <div className={styles.tableWrapper}>
                  <div className={styles.table}>
                    <div className={styles.columnHeaderIndex}>#</div>
                    <div className={styles.columnHeader}>Fullname</div>
                    <div className={styles.columnHeader}>Email</div>
                    <div className={styles.columnHeader}>Phone</div>
                    <div className={styles.columnHeaderDeleteButton} />
                    {formData.contacts.map((contact, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className={styles.columnContactIndex}>
                            {index + 1}.
                          </div>
                          <div className={styles.columnContact}>
                            {contact.firstName} {contact.lastName}
                          </div>
                          <div className={styles.columnContact}>
                            {contact.email}
                          </div>
                          <div className={styles.columnContact}>
                            {contact.phone}
                          </div>
                          <div className={styles.columnContactDeleteButton}>
                            <button
                              onClick={() =>
                                setFormData((formData) => ({
                                  ...formData,
                                  contacts: formData.contacts.filter(
                                    (_, i) => i !== index
                                  ),
                                }))
                              }
                              title="Delete"
                              className={styles.removeContactButton}
                            >
                              x
                            </button>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {/* New contact table */}
            {withMoreContacts && (
              <div className={styles.inputsBlock}>
                <div className={styles.newContactTable}>
                  <div className={styles.newContactColumn}>
                    <Input
                      label="First name*"
                      required={true}
                      form="contact"
                      value={newContactFormData.firstName}
                      onChange={(e) =>
                        setNewContactFormData((formData) => ({
                          ...formData,
                          firstName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className={styles.newContactColumn}>
                    <Input
                      label="Last name*"
                      required={true}
                      form="contact"
                      value={newContactFormData.lastName}
                      onChange={(e) =>
                        setNewContactFormData((formData) => ({
                          ...formData,
                          lastName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className={styles.newContactColumn}>
                    <Input
                      label="Email*"
                      type="email"
                      required={true}
                      form="contact"
                      value={newContactFormData.email}
                      onChange={(e) =>
                        setNewContactFormData((formData) => ({
                          ...formData,
                          email: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className={styles.newContactColumn}>
                    <Input
                      label="Phone"
                      form="contact"
                      value={newContactFormData.phone}
                      onChange={(e) =>
                        setNewContactFormData((formData) => ({
                          ...formData,
                          phone: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <button
                  form="contact"
                  type="submit"
                  className={styles.addContactButton}
                >
                  Add contact
                </button>
              </div>
            )}

            {/* Comments */}
            <div className={styles.inputsBlock}>
              <Textarea
                label="Is there anything not offered by our membership that you would
                  like to see included?"
                form="membership"
                value={formData.comments}
                onChange={(e) =>
                  setFormData((formData) => ({
                    ...formData,
                    comments: e.target.value,
                  }))
                }
              />
            </div>

            {/* Referral */}
            <div className={styles.inputsBlock}>
              <Select
                form="membership"
                required={true}
                label="How did you hear about Creative Conscience Membership?*"
                value={formData.referral}
                options={referralSelectOptions}
                onChange={(e) =>
                  setFormData((formData) => ({
                    ...formData,
                    referral: e.target.value,
                  }))
                }
              />
            </div>
            {/* Referral other */}
            {withReferralOther && (
              <div className={styles.inputsBlock}>
                <Textarea
                  label="Please let us know how you found out about the Creative
              Conscience Membership"
                  form="membership"
                  value={formData.referralOther}
                  onChange={(e) =>
                    setFormData((formData) => ({
                      ...formData,
                      referralOther: e.target.value,
                    }))
                  }
                />
              </div>
            )}

            {/* Order notes */}
            <div className={styles.inputsBlock}>
              <div className={styles.orderNotes}>
                Please note: You will not be able to edit the information above
                after finishing your application.
              </div>
            </div>

            {/* Submit */}
            <div className={styles.inputsBlock}>
              <button
                form="membership"
                className={styles.submitButton}
                type="submit"
              >
                Submit
              </button>
            </div>

            {/* Error */}
            {errorMessage && (
              <div className={styles.inputsBlock}>{errorMessage}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MembershipForm;

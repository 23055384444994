import { useState, useEffect, useRef } from 'react';

export const useSnapActiveItem = (
  callback: (ele: Element, parent: Element) => boolean
) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [activeCarouselItemIndex, setActiveCarouselItemIndex] = useState(0);

  // Set active carousel element on scroll event
  useEffect(() => {
    if (!carouselRef.current) return;

    const { current } = carouselRef;

    const setActiveCarouselItem = () => {
      Array.from(current.children).forEach((ele, index) => {
        if (callback(ele, current)) {
          //The ele element at this moment is the element currently positioned
          setActiveCarouselItemIndex(index);
        }
      });
    };

    current.addEventListener('scroll', setActiveCarouselItem);
    return () => current.removeEventListener('scroll', setActiveCarouselItem);
  }, [callback]);

  // https://fettblog.eu/typescript-react-typeing-custom-hooks/
  return [carouselRef, activeCarouselItemIndex] as const;
};

import { IFeaturedNews, IFeaturedNewsItem } from 'types/generated/strapi';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.css';
import clsx from 'clsx';

import { connect, ConnectedProps } from 'react-redux';
import { fetchCategories } from 'redux/categorySlice';
import { AppState } from 'redux/store';

import formatSlug from 'utils/formatSlug';
import { useSnapActiveItem } from 'utils/useSnapActiveItem';

import StrapiImage from 'components/shared/StrapiImage';
import ArticleMeta from 'components/shared/ArticleMeta';
import ArticleCategory from 'components/shared/ArticleCategory';

import { IArticleCategory } from 'types/generated/strapi';

interface IFeaturedNewsProps extends ConnectedProps<typeof connector> {
  section: IFeaturedNews;
}

const FeaturedNews: FunctionComponent<IFeaturedNewsProps> = (props) => {
  const { ref: containerRef, inView } = useInView({
    threshold: 0.6,
    triggerOnce: true,
  });

  const { fetchCategories, section, allCategories, allCategoriesStatus } =
    props;

  const checkCenterAlign = useCallback((ele: Element, current: Element) => {
    return (
      Math.abs(
        ele.getBoundingClientRect().left -
          (window.innerWidth - ele.getBoundingClientRect().width) / 2
      ) < 10
    );
  }, []);

  const [carouselRef, activeCarouselItemIndex] =
    useSnapActiveItem(checkCenterAlign);

  useEffect(() => {
    if (allCategoriesStatus === 'initial') {
      fetchCategories();
    }
  }, [allCategoriesStatus, fetchCategories]);

  const getArticleCategoryName = (
    articleCategory: number | IArticleCategory
  ) => {
    const categoryId =
      typeof articleCategory === 'number'
        ? articleCategory
        : articleCategory.name;

    return allCategories.find((c) => c.id === categoryId)?.name || '';
  };

  const allItemsLinkUrl = useMemo(() => {
    return formatSlug(section?.linkToAllNewsPage?.page?.slug || '/');
  }, [section?.linkToAllNewsPage?.page?.slug]);

  const allItemsLinkLabel = useMemo(() => {
    return (
      section?.linkToAllNewsPage?.label ||
      section?.linkToAllNewsPage?.page?.title ||
      '/'
    );
  }, [section?.linkToAllNewsPage]);

  const linkUrl = (item: IFeaturedNewsItem) => {
    const slug = item.article?.slug || '/';
    return formatSlug(`/news/${slug}`);
  };

  return (
    <div className={styles.featuredNews}>
      <div ref={containerRef} className={styles.featuredNewsInner}>
        {/* Title */}
        <div className={clsx(styles.title)}>{section.title}</div>

        {/* Items */}
        <div ref={carouselRef} className={styles.carousel}>
          {section.items.map((item, index) => {
            return (
              <div
                key={item.id}
                className={clsx(
                  styles.carouselItem,
                  activeCarouselItemIndex === index &&
                    styles.carouselItemActive,
                  inView && styles.rollUp
                )}
              >
                <Link className={styles.articleLink} to={linkUrl(item)}>
                  {/* Image */}
                  <StrapiImage
                    image={item.image}
                    format="medium"
                    className={styles.articleImage}
                  />

                  <div className={styles.articleLinkContent}>
                    <div className={styles.articleLinkContentInner}>
                      {/* Category */}
                      <div className={styles.itemCategoryWrapper}>
                        {item.article?.articleCategory && (
                          <ArticleCategory
                            name={getArticleCategoryName(
                              item.article.articleCategory
                            )}
                            tag="span"
                          />
                        )}
                      </div>

                      <div className={styles.itemContentWrapper}>
                        {/* Meta */}
                        {item.article && (
                          <ArticleMeta
                            className={styles.articleMeta}
                            article={item.article}
                          />
                        )}

                        {/* Title */}
                        <div className={styles.articleTitle}>
                          {item.article?.title}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                {/* All news link */}
                <Link className={styles.allItemsLink} to={allItemsLinkUrl}>
                  {allItemsLinkLabel}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  allCategories: state.category.categoriesRequest.data ?? [],
  allCategoriesStatus: state.category.categoriesRequest.status,
});

const mapDispatchToProps = {
  fetchCategories,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(FeaturedNews);

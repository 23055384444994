import { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import formatSlug from 'utils/formatSlug';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';

import styles from './index.module.css';

interface IBackLinkProps {
  link: string;
  label: string;
  className?: string;
}

const BackLink: FunctionComponent<IBackLinkProps> = (props) => {
  const { link, label } = props;

  const linkPath = useMemo(() => {
    return formatSlug(link);
  }, [link]);

  if (!link || !label) return null;

  return (
    <Link className={clsx(styles.backLink, props.className)} to={linkPath}>
      <ArrowIcon className={styles.arrow} aria-hidden="true" />
      <span className={styles.label}>{label}</span>
    </Link>
  );
};

export default BackLink;

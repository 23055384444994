import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IArticle } from 'types/generated/strapi';

export const fetchArticle = createAsyncThunk(
  'article/fetch',
  async (slug: string) => {
    const response = await strapi.getArticleBySlug(slug);
    return response;
  }
);

export const fetchArticles = createAsyncThunk(
  'articles/fetch',
  async (searchParams: any) => {
    const response = await strapi.getArticles(searchParams);
    return response;
  }
);

export const fetchArticlesCount = createAsyncThunk(
  'articlesCount/fetch',
  async (searchParams: any) => {
    const response = await strapi.getArticlesCount(searchParams);
    return response;
  }
);

export interface IArticleSlice {
  articleRequest: IApiRequest<IArticle>;
  articlesRequest: IApiRequest<IArticle[]>;
  articlesCountRequest: IApiRequest<number>;
}

const initialState: IArticleSlice = {
  articleRequest: {
    status: 'initial',
  },
  articlesRequest: {
    status: 'initial',
  },
  articlesCountRequest: {
    status: 'initial',
  },
};

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchArticle.pending, (state) => {
        state.articleRequest.status = 'pending';
      })
      .addCase(fetchArticle.fulfilled, (state, action) => {
        state.articleRequest.status = 'fulfilled';
        state.articleRequest.data = action.payload;
      })
      .addCase(fetchArticle.rejected, (state, action) => {
        state.articleRequest.status = 'rejected';
        state.articleRequest.error = action.error;
      })
      .addCase(fetchArticles.pending, (state) => {
        state.articlesRequest.status = 'pending';
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.articlesRequest.status = 'fulfilled';
        state.articlesRequest.data = action.payload;
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.articlesRequest.status = 'rejected';
        state.articlesRequest.error = action.error;
      })
      .addCase(fetchArticlesCount.pending, (state) => {
        state.articlesCountRequest.status = 'pending';
      })
      .addCase(fetchArticlesCount.fulfilled, (state, action) => {
        state.articlesCountRequest.status = 'fulfilled';
        state.articlesCountRequest.data = action.payload;
      })
      .addCase(fetchArticlesCount.rejected, (state, action) => {
        state.articlesCountRequest.status = 'rejected';
        state.articlesCountRequest.error = action.error;
      });
  },
});

export default articleSlice.reducer;

import styles from './index.module.css';
import { FunctionComponent } from 'react';

import { Link } from 'react-router-dom';

import formatSlug from 'utils/formatSlug';
import clsx from 'clsx';

interface IArticleCategoryProps {
  name: string;
  slug?: string;
  className?: string;
  slugPageWithList?: string;
  tag?: 'a' | 'span';
  active?: boolean;
}

const ArticleCategory: FunctionComponent<IArticleCategoryProps> = ({
  name,
  slug,
  className,
  slugPageWithList,
  tag = 'a',
  active = false,
}) => {
  if (name === '') return null;

  if (tag === 'span' || active) {
    return (
      <span
        className={clsx(
          styles.articleCategory,
          active && styles.articleCategoryActive,
          className
        )}
      >
        {name}
      </span>
    );
  }

  return (
    <Link
      className={clsx(
        styles.articleCategory,
        active && styles.articleCategoryActive,
        className
      )}
      to={formatSlug(`${slugPageWithList}?category=${slug || ''}`)}
    >
      {name}
    </Link>
  );
};

export default ArticleCategory;

import styles from './index.module.css';

const Loading = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.loaderWrapper}>
        <span className={styles.loader} />
      </div>
    </div>
  );
};

export default Loading;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { ISidebar } from 'types/generated/strapi';

export const fetchSidebar = createAsyncThunk('sidebar/fetch', async () => {
  const response = await strapi.getSidebar();
  return response;
});

export interface ISidebarSlice {
  sidebarRequest: IApiRequest<ISidebar>;
}

const initialState: ISidebarSlice = {
  sidebarRequest: {
    status: 'initial',
  },
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSidebar.pending, (state) => {
        state.sidebarRequest.status = 'pending';
      })
      .addCase(fetchSidebar.fulfilled, (state, action) => {
        state.sidebarRequest.status = 'fulfilled';
        state.sidebarRequest.data = action.payload;
      })
      .addCase(fetchSidebar.rejected, (state, action) => {
        state.sidebarRequest.status = 'rejected';
        state.sidebarRequest.error = action.error;
      });
  },
});

export default sidebarSlice.reducer;

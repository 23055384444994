import { useEffect, useMemo, useState, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import Error404 from 'pages/Error404/Error404';

import { connect, ConnectedProps } from 'react-redux';
import { fetchEvent } from 'redux/eventSlice';
import { AppState } from 'redux/store';

import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeAddClasses from 'rehype-add-classes';
import rehypeRaw from 'rehype-raw';

import Layout from 'components/shared/Layout';
import SeoHead from 'components/shared/SeoHead';
import BackLink from 'components/shared/BackLink';

import PageHero from 'components/sections/PageHero';

import { IPageHero } from 'types/generated/strapi';

import styles from './Event.module.css';
import generateStaticPage from 'utils/generateStaticPage';
import Loading from 'components/shared/Loading';

const EventPage: FunctionComponent<IEventPageProps> = (props) => {
  const { slug } = useParams();
  const { event, eventStatus, fetchEvent } = props;

  const [pageIsReady, setPageIsReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (!slug) return;
      if (event?.slug !== slug) await fetchEvent(slug);
      setPageIsReady(true);
      generateStaticPage();
    })();
  }, [fetchEvent, slug, event]);

  const { date, endDate, url } = { ...props.event };

  const pageHeroProps: IPageHero | null = props.event
    ? {
        id: 0,
        backgroundImage: props.event?.image,
      }
    : null;

  const dateObj = useMemo(() => {
    return date ? new Date(date) : null;
  }, [date]);

  const endDateObj = useMemo(() => {
    return endDate ? new Date(endDate) : null;
  }, [endDate]);

  const eventIsUpcoming = useMemo(() => {
    return dateObj ? dateObj > new Date() : false;
  }, [dateObj]);

  const eventIsOngoing = useMemo(() => {
    if (!dateObj || !endDateObj) return false;
    const now = new Date();
    return now > dateObj && now < endDateObj;
  }, [dateObj, endDateObj]);

  // Should we use browser locale format instead of forcing these to en-GB?
  const dateString = useMemo(() => {
    return dateObj
      ? Intl.DateTimeFormat('en-GB', {
          weekday: 'short',
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).format(dateObj)
      : null;
  }, [dateObj]);

  const endDateString = useMemo(() => {
    return endDateObj
      ? Intl.DateTimeFormat('en-GB', {
          weekday: 'short',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }).format(endDateObj)
      : null;
  }, [endDateObj]);

  const bookNowVisible = useMemo(() => {
    if (!url) return false;
    return eventIsUpcoming || eventIsOngoing;
  }, [url, eventIsUpcoming, eventIsOngoing]);

  if (event === undefined || eventStatus === 'pending' || slug !== event?.slug)
    return <Loading />;
  if (event === null) return <Error404 />;

  return (
    <Layout>
      <SeoHead seo={event.SEO} />

      {/* Hero */}
      {pageHeroProps && <PageHero section={pageHeroProps} />}

      {/* Event */}
      <div className={clsx(styles.event, !pageIsReady && styles.eventLoading)}>
        <div
          className={clsx(
            styles.eventInner,
            !dateString && styles.eventInnerWithoutDate
          )}
        >
          {/* Title */}
          <h2 className={styles.title}>{event.title}</h2>

          {/* Date */}
          {dateString && (
            <div className={styles.dateWrapper}>
              {endDateString && <div>From:</div>}
              <div className={styles.date}>{dateString}</div>
              {endDateString && <div className={styles.endDateTitle}>To:</div>}
              {endDateString && (
                <div className={styles.date}>{endDateString}</div>
              )}
            </div>
          )}

          {/* Book now */}
          {bookNowVisible && (
            <div className={styles.bookLinkWrapper}>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className={styles.bookLink}
              >
                Book now
              </a>
            </div>
          )}

          {/* Event details */}
          <ReactMarkdown
            className={styles.details}
            children={event.details}
            rehypePlugins={[
              [rehypeExternalLinks, { rel: ['noopener', 'noreferrer'] }],
              [
                rehypeAddClasses,
                {
                  ul: styles.detailsList,
                  li: styles.detailsListItem,
                },
              ],
              rehypeRaw,
            ]}
          />

          {/* Back to events link */}
          <div className={styles.backLinkWrapper}>
            <BackLink link="/what-we-do" label="Back to all events" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  event: state.event?.eventRequest?.data,
  eventStatus: state.event?.eventRequest?.status,
});

const mapDispatchToProps = {
  fetchEvent,
};

interface IEventPageProps extends ConnectedProps<typeof connector> {}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EventPage);

import { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import StyledSelect from 'components/shared/StyledSelect';
import {
  getSnipcart,
  addItem,
  subscribeSnipcartOrder,
  clearCart,
  openCheckout,
} from 'services/snipcart';
import type {
  IProduct,
  IDonationBlack,
  IVariation,
} from 'types/generated/strapi';
import styles from './index.module.css';
import { fetchProducts } from 'redux/productSlice';
import StrapiImage from 'components/shared/StrapiImage';
import { useNavigate } from 'react-router-dom';
import { useLoading } from 'utils/useLoading';

const DonationBlack = (
  props: {
    section: IDonationBlack;
  } & ConnectedProps<typeof connector>
) => {
  const { allProducts, allProductsStatus, fetchProducts } = props;
  const { titleImage, description, thankYouPage } = props.section;
  const [selectedProduct, setSelectedProduct] = useState<
    IProduct | undefined
  >();
  const [selectedVariation, setSelectedVariation] = useState<
    IVariation | undefined
  >();
  const navigate = useNavigate();
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (allProductsStatus === 'initial') {
      fetchProducts();
    }
  }, [allProductsStatus, fetchProducts]);

  const dontationProducts = useMemo(() => {
    if (!allProducts.length) return [];
    const dontationProducts = allProducts.filter(
      (prod) => prod.productCategory === 'Donation'
    );
    return dontationProducts;
  }, [allProducts]);

  // Select first product after initial fetch
  useEffect(() => {
    if (!dontationProducts.length) return;
    const activeProduct = dontationProducts[0];
    setSelectedProduct(activeProduct);
    setSelectedVariation(activeProduct.variations[0]);
  }, [dontationProducts]);

  // Register Snipcart event callbacks
  useEffect(() => {
    var unsubscribeSnipcartOrder: () => void | undefined;
    (async () => {
      var snipcart = await getSnipcart();
      unsubscribeSnipcartOrder = subscribeSnipcartOrder(
        snipcart,
        async (order: any) => {
          // prevent snipcart order summary to briefly appear before redirect
          document.documentElement.style.opacity = '0';
          window.location.replace(`/${thankYouPage?.slug || ''}`);
        },
        (error: string) => {
          setError(error);
        }
      );
    })();
    // Cleanup
    return () => {
      unsubscribeSnipcartOrder?.();
    };
  }, [navigate, startLoading, stopLoading, thankYouPage?.slug]);

  // Submit
  const onButtonClick = async () => {
    debugger;
    if (!selectedProduct) {
      setError('Product not found');
      stopLoading();
      return;
    }
    if (!selectedVariation) {
      setError('Variation not found');
      stopLoading();
      return;
    }
    startLoading();
    const snipcart = await getSnipcart();
    await clearCart(snipcart);
    // Add item to cart
    try {
      await addItem(snipcart, selectedProduct, selectedVariation, 1);
      openCheckout();
    } catch (error) {
      setError('Error adding item to cart.');
      stopLoading();
      return;
    }
    stopLoading();
  };

  const variationSelectorOptions = useMemo(() => {
    if (!selectedProduct?.variations?.length) return [];
    return selectedProduct.variations.map((c) => ({
      label: `£${Intl.NumberFormat('en-GB').format(c.price)}`,
      value: c.name,
    }));
  }, [selectedProduct?.variations]);

  const selectedVariationName = selectedVariation?.name || '';

  return (
    <div className={styles.donationBlack}>
      <div className={styles.donationBlackInner}>
        <div className={styles.contentWrapper}>
          {/* Title */}
          <StrapiImage
            className={styles.titleImage}
            image={titleImage}
            format="medium"
          />

          {/* Description */}
          <div className={styles.description}>{description}</div>
        </div>

        <div className={styles.selector}>
          <div className={styles.selectorInner}>
            <div className={styles.label}>Select your donation amount</div>
            {selectedProduct && (
              <StyledSelect
                onChange={({ target }) => {
                  const selectedVariation = selectedProduct.variations.find(
                    (c) => c.name === target.value
                  );
                  setSelectedVariation(selectedVariation);
                }}
                options={variationSelectorOptions}
                value={selectedVariationName}
                variant="inverted"
              />
            )}
            <p>{error}</p>
            <div className={styles.buttonWrapper}>
              <button
                className={styles.button}
                onClick={onButtonClick}
                disabled={loading}
              >
                Donate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  allProducts: state.product.productsRequest.data ?? [],
  allProductsStatus: state.product.productsRequest.status,
});

const mapDispatchToProps = {
  fetchProducts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(DonationBlack);

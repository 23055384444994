import { createElement, FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import truncateString from 'utils/truncateString';

import ReactMarkdown from 'react-markdown';
import StrapiImage from 'components/shared/StrapiImage';

import { IFile } from 'types/generated/strapi';

import styles from './index.module.css';

interface IEventCardProps {
  image: IFile;
  title: string;
  blurb: string;
  date?: Date | string;
  endDate?: Date | string;
  bookingUrl?: string;
  readMoreUrl?: string;
  layout?: 'upcoming' | 'past';
}

const EventCard: FunctionComponent<IEventCardProps> = (props) => {
  const { date, endDate, bookingUrl } = props;

  const blurb = truncateString(props.blurb);

  const dateObj = useMemo(() => {
    return date ? new Date(date) : null;
  }, [date]);

  const endDateObj = useMemo(() => {
    return endDate ? new Date(endDate) : null;
  }, [endDate]);

  const eventIsUpcoming = useMemo(() => {
    return dateObj ? dateObj > new Date() : false;
  }, [dateObj]);

  const eventIsOngoing = useMemo(() => {
    if (!dateObj || !endDateObj) return false;
    const now = new Date();
    return now > dateObj && now < endDateObj;
  }, [dateObj, endDateObj]);

  const layout = props.layout || (eventIsUpcoming ? 'upcoming' : 'past');

  const bookNowVisible = useMemo(() => {
    if (!bookingUrl || layout === 'past') return false;
    return eventIsUpcoming || eventIsOngoing;
  }, [bookingUrl, eventIsUpcoming, eventIsOngoing, layout]);

  const dateIsVisible = useMemo(() => {
    return date && layout === 'upcoming';
  }, [date, layout]);

  const dateString = useMemo(() => {
    return dateObj
      ? Intl.DateTimeFormat('en-GB', {
          weekday: 'long',
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        }).format(dateObj)
      : null;
  }, [dateObj]);

  const imageWrapper = createElement(
    props.readMoreUrl ? Link : 'div',
    { to: props.readMoreUrl || '', className: styles.imageWrapper },
    <StrapiImage image={props.image} format="small" className={styles.image} />
  );

  return (
    <div
      className={clsx(
        styles.eventCard,
        layout === 'upcoming' && styles.eventCardUpcoming,
        layout === 'past' && styles.eventCardPast
      )}
    >
      {/* Image */}
      {imageWrapper}

      <div
        className={clsx(
          styles.details,
          layout === 'upcoming' && styles.detailsUpcoming,
          layout === 'past' && styles.detailsPast
        )}
      >
        <div className={styles.titleWrapper}>
          {/* Title */}
          <h3 className={styles.title}>{props.title}</h3>

          {/* Date */}
          {dateIsVisible && <div className={styles.date}>{dateString}</div>}
        </div>

        {/* Blurb */}
        <ReactMarkdown className={styles.blurb} children={blurb} />

        {/* Read more */}
        {props.readMoreUrl && (
          <div className={styles.moreLinkWrapper}>
            <Link to={props.readMoreUrl} className={styles.moreLink}>
              Read more
            </Link>
          </div>
        )}

        {/* Book now */}
        {bookNowVisible && (
          <div className={styles.bookLinkWrapper}>
            <a
              href={bookingUrl}
              target="_blank"
              rel="noreferrer"
              className={styles.bookLink}
            >
              Book now
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;

import { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import RemarkGfm from 'remark-gfm';
import { IRichText } from 'types/generated/strapi';
import styles from './index.module.css';

interface IRichTextProps {
  section: IRichText;
}

const RichText: FunctionComponent<IRichTextProps> = (props) => {
  const gfmOptions = {};
  const paddingTop = props.section.paddingTop
    ? `${props.section.paddingTop}rem`
    : 'none';
  const paddingBottom = props.section.paddingTop
    ? `${props.section.paddingBottom}rem`
    : 'none';
  const richTextPaddings = {
    paddingTop,
    paddingBottom,
  };

  const location = useLocation();
  const getAttributesByLocation = () => {
    const isWhatWeDoPage = ['/what-we-do', '/what-we-do/'].some(
      (c) => location.pathname === c
    );
    return isWhatWeDoPage
      ? {
          'data-sidebar-target': true,
          'data-sidebar-sticky': true,
          'data-sidebar-padding-top': 'var(--space-xxl)',
        }
      : {};
  };

  return (
    <div className={styles.richText} {...getAttributesByLocation()}>
      <div className={styles.richTextInner}>
        <div style={richTextPaddings}>
          {props.section.copy && (
            <ReactMarkdown
              className={styles.copy}
              remarkPlugins={[RemarkGfm, gfmOptions]}
            >
              {props.section.copy}
            </ReactMarkdown>
          )}
        </div>
      </div>
    </div>
  );
};

export default RichText;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IDiscipline } from 'types/generated/strapi';

export const fetchDisciplines = createAsyncThunk(
  'disciplines/fetch',
  async () => {
    const response = await strapi.getDisciplines();
    return response;
  }
);

export interface IDisciplineSlice {
  disciplinesRequest: IApiRequest<IDiscipline[]>;
}

const initialState: IDisciplineSlice = {
  disciplinesRequest: {
    status: 'initial',
  },
};

export const disciplineSlice = createSlice({
  name: 'discipline',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchDisciplines.pending, (state) => {
        state.disciplinesRequest.status = 'pending';
      })
      .addCase(fetchDisciplines.fulfilled, (state, action) => {
        state.disciplinesRequest.status = 'fulfilled';
        state.disciplinesRequest.data = action.payload;
      })
      .addCase(fetchDisciplines.rejected, (state, action) => {
        state.disciplinesRequest.status = 'rejected';
        state.disciplinesRequest.error = action.error;
      });
  },
});

export default disciplineSlice.reducer;

import { FunctionComponent } from 'react';

import Navbar from 'components/shared/Navbar';
import Footer from 'components/shared/Footer';

import styles from './Error404.module.css';

const Error404: FunctionComponent = () => {
  return (
    <>
      <Navbar />
      <main className={styles.errorPage}>
        <h1>404 Not Found</h1>
      </main>
      <Footer />
    </>
  );
};

export default Error404;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IAwardBrief } from 'types/generated/strapi';

export const fetchAwardBriefs = createAsyncThunk(
  'awardBrief/fetch',
  async () => {
    const response = await strapi.getAwardBriefs();
    return response;
  }
);

export interface IAwardBriefSlice {
  awardBriefRequest: IApiRequest<IAwardBrief[]>;
}

const initialState: IAwardBriefSlice = {
  awardBriefRequest: {
    status: 'initial',
  },
};

export const awardBriefSlice = createSlice({
  name: 'awardBrief',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchAwardBriefs.pending, (state) => {
        state.awardBriefRequest.status = 'pending';
      })
      .addCase(fetchAwardBriefs.fulfilled, (state, action) => {
        state.awardBriefRequest.status = 'fulfilled';
        state.awardBriefRequest.data = action.payload;
      })
      .addCase(fetchAwardBriefs.rejected, (state, action) => {
        state.awardBriefRequest.status = 'rejected';
        state.awardBriefRequest.error = action.error;
      });
  },
});

export default awardBriefSlice.reducer;

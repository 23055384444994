import { FunctionComponent } from 'react';
// import clsx from 'clsx';

import ReactMarkdown from 'react-markdown';
import { ReactComponent as BrushSplash } from 'assets/svg/brush-splash.svg';

import { ITwoColumnTitleAndText } from 'types/generated/strapi';

import styles from './index.module.css';

interface ITwoColumnTitleAndTextProps {
  section: ITwoColumnTitleAndText;
}

const TwoColumnTitleAndText: FunctionComponent<ITwoColumnTitleAndTextProps> = (
  props
) => {
  const { title, text } = props.section;

  return (
    <div className={styles.twoColumnTitleAndText}>
      <div className={styles.twoColumnTitleAndTextInner}>
        {/* Left column */}
        {title && (
          <div className={styles.leftColumn}>
            <div className={styles.titleWrapper}>
              <BrushSplash
                className={styles.titleBrushImage}
                aria-hidden="true"
              />
              <h2 className={styles.title}>{title}</h2>
            </div>
          </div>
        )}

        {/* Right column */}
        <div className={styles.rightColumn}>
          {text && <ReactMarkdown className={styles.text} children={text} />}
        </div>
      </div>
    </div>
  );
};

export default TwoColumnTitleAndText;

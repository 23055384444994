import { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { getStrapiMediaUrl } from 'services/strapi';

import { ReactComponent as VolumeMuteIcon } from 'assets/svg/volume-mute.svg';
import { ReactComponent as VolumeUpIcon } from 'assets/svg/volume-up.svg';

import { IHomeHero } from 'types/generated/strapi';

import styles from './index.module.css';

interface IHomeHeroProps {
  section: IHomeHero;
}

const HomeHero: FunctionComponent<IHomeHeroProps> = (props) => {
  const [videoMuted, setVideoMuted] = useState<boolean>(true);

  const muteButtonText = useMemo(() => {
    return videoMuted ? 'Unmute' : 'Mute';
  }, [videoMuted]);

  const toggleMuted = useCallback(() => {
    setVideoMuted(!videoMuted);
  }, [videoMuted]);

  return (
    <div className={styles.homeHero} data-sidebar-target data-sidebar-centered>
      {/* Video */}
      <video
        className={styles.video}
        src={getStrapiMediaUrl(props.section.backgroundVideo.url)}
        autoPlay
        playsInline
        loop={true}
        muted={videoMuted}
        poster={
          props.section.backgroundVideoPoster?.url
            ? getStrapiMediaUrl(props.section.backgroundVideoPoster.url)
            : undefined
        }
      />

      {/* Content */}
      <div className={styles.contentContainer}>
        <div className={styles.contentContainerInner}></div>
      </div>

      {/* Mute button */}
      <button className={styles.muteButton} onClick={toggleMuted}>
        <span className="visually-hidden">{muteButtonText}</span>
        {videoMuted && <VolumeMuteIcon className={styles.muteIcon} />}
        {!videoMuted && <VolumeUpIcon className={styles.muteIcon} />}
      </button>
    </div>
  );
};

export default HomeHero;

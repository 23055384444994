import { FunctionComponent, useRef, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import styles from './index.module.css';
import { ReactComponent as TwitterIcon } from 'assets/svg/twitter.svg';
import { ReactComponent as InstagramIcon } from 'assets/svg/instagram.svg';
import { ReactComponent as TiktokIcon } from 'assets/svg/tiktok.svg';
import { ReactComponent as LinkedinIcon } from 'assets/svg/linkedin.svg';
import { ReactComponent as WebsiteIcon } from 'assets/svg/globe.svg';
import { ReactComponent as FacebookIcon } from 'assets/svg/facebook.svg';
import { ReactComponent as YoutubeIcon } from 'assets/svg/youtube.svg';
import { ReactComponent as TheDotsIcon } from 'assets/svg/the-dots.svg';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

interface ISidebarProps extends PropsFromRedux {}

const Sidebar: FunctionComponent<ISidebarProps> = (props) => {
  const location = useLocation();
  const isMagenta = ['/', '/people'].some((c) => location.pathname === c);
  const ref = useRef<HTMLDivElement>(null);
  const stickyRef = useRef<HTMLDivElement>(null);
  const stickyWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculatePosition = () => {
      if (!ref.current) return;

      ref.current.style.visibility = 'visible';

      const targets = Array.from(
        document.querySelectorAll('[data-sidebar-target]')
      );

      if (!targets.length || !stickyRef.current || !stickyWrapperRef.current)
        return;

      const rects = targets.map((c: any) => {
        return {
          top: c.offsetTop,
          height: c.offsetHeight,
        };
      });

      ref.current.style.height = `${rects
        .map((c) => c.height)
        .reduce((cur, prev) => cur + prev, 0)}px`;
      ref.current.style.top = `${Math.min(...rects.map((c) => c.top))}px`;

      const firstTarget = targets[0] as HTMLDivElement;
      const lastTarget = targets[targets.length - 1] as HTMLDivElement;
      const isSticky = firstTarget.dataset?.sidebarSticky;
      const isCentered = firstTarget.dataset?.sidebarCentered;
      const withTopPadding = firstTarget.dataset?.sidebarPaddingTop;
      const withBottomPadding = lastTarget.dataset?.sidebarPaddingBottom;

      if (isSticky) {
        stickyRef.current.style.cssText = `
            position: sticky;
            top: 1rem;
          `;

        stickyWrapperRef.current.style.cssText = `
            height: 100%;
          `;
      }
      if (isCentered) {
        ref.current.style.justifyContent = 'center';
      }
      if (withTopPadding) {
        ref.current.style.paddingTop = withTopPadding;
      }
      if (withBottomPadding) {
        ref.current.style.paddingBottom = withBottomPadding;
      }
    };

    const rootEl = document.querySelector('#root-client main');
    const body = document.querySelector('body');

    if (!rootEl || !body) return;

    const resizeObserver = new ResizeObserver(calculatePosition);
    const mutationObserver = new MutationObserver(calculatePosition);

    mutationObserver.observe(rootEl, { childList: true });
    resizeObserver.observe(body);

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  if (!props.sidebar?.socialLinks?.length) return null;

  return (
    <div
      className={clsx(styles.sidebar, isMagenta && styles.colorMagenta)}
      ref={ref}
    >
      <div ref={stickyWrapperRef}>
        <div ref={stickyRef}>
          <ul className={styles.socialLinks}>
            {props.sidebar.socialLinks.map((socialLink, socialLinkIndex) => {
              const { url, icon } = socialLink;

              return (
                <li key={`social-${socialLinkIndex}`}>
                  <a
                    className={styles.socialLink}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {icon === 'Twitter' && <TwitterIcon />}
                    {icon === 'Instagram' && <InstagramIcon />}
                    {icon === 'Linkedin' && <LinkedinIcon />}
                    {icon === 'Tiktok' && <TiktokIcon />}
                    {icon === 'Website' && <WebsiteIcon />}
                    {icon === 'Facebook' && <FacebookIcon />}
                    {icon === 'Youtube' && <YoutubeIcon />}
                    {icon === 'TheDots' && <TheDotsIcon />}
                  </a>
                </li>
              );
            })}
          </ul>
          {props.sidebar.text && (
            <div className={styles.text}>{props.sidebar.text}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  sidebar: state.sidebar.sidebarRequest.data,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Sidebar);

// Splits array
// Example: createChunks([1,2,3,4,5,6,7,8,9], 4) => [[1,2,3,4],[5,6,7,8],[9]]
export const createChunks = (inputArray: any[], perChunk: number) => {
  const result = inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return result;
};

import clsx from 'clsx';
import { ChangeEvent } from 'react';
import StyledSelect from '../StyledSelect';
import styles from './index.module.css';

interface SelectProps {
  label?: string;
  options: { label: string; value: number | string }[];
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  form?: string;
  classes?: {
    group?: string;
    label?: string;
  };
}
const Select = (props: SelectProps) => {
  const { label, options, value, onChange, required, form, classes } = props;

  return (
    <div className={clsx(styles.group, classes?.group)}>
      <label>
        {label && (
          <div className={clsx(styles.label, classes?.label)}>{label}</div>
        )}
        <StyledSelect
          form={form}
          required={required}
          options={options}
          value={value}
          onChange={(e) => onChange && onChange(e)}
        />
      </label>
    </div>
  );
};

export default Select;

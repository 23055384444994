import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { connect, ConnectedProps } from 'react-redux';
import { fetchPerson } from 'redux/personSlice';
import { AppState } from 'redux/store';

import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeAddClasses from 'rehype-add-classes';
import rehypeRaw from 'rehype-raw';

import Error404 from 'pages/Error404/Error404';

import Layout from 'components/shared/Layout';
import SeoHead from 'components/shared/SeoHead';
import StrapiImage from 'components/shared/StrapiImage';
import StrapiLink from 'components/shared/StrapiLink';
import BackLink from 'components/shared/BackLink';

import PageHero from 'components/sections/PageHero';

import { ICollectionHeader } from 'types/generated/strapi';

import styles from './Person.module.css';
import generateStaticPage from 'utils/generateStaticPage';
import Loading from 'components/shared/Loading';

interface IPersonProps extends ConnectedProps<typeof connector> {}

const Person: FunctionComponent<IPersonProps> = (props) => {
  const { slug } = useParams();
  const { person, personStatus, fetchPerson, collectionSettings } = props;

  const [pageIsReady, setPageIsReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (!slug) return;
      if (person?.slug !== slug) await fetchPerson(slug);
      setPageIsReady(true);
      generateStaticPage();
    })();
  }, [slug, person, fetchPerson]);

  const pageHeroSection: ICollectionHeader | undefined = useMemo(() => {
    return collectionSettings?.collectionHeaders.find((collection) => {
      return collection.collectionType === 'People';
    });
  }, [collectionSettings]);

  if (
    person === undefined ||
    personStatus === 'pending' ||
    slug !== person?.slug
  )
    return <Loading />;
  if (person === null) return <Error404 />;

  return (
    <Layout>
      <SeoHead seo={person.SEO} />

      {/* Hero */}
      {pageHeroSection && <PageHero section={pageHeroSection} />}

      {/* Person */}
      <div
        className={clsx(
          styles.personContainer,
          !pageIsReady && styles.personContainerLoading
        )}
      >
        <div className={styles.personContainerInner}>
          {/* Image */}
          {person.image && (
            <div className={styles.imageWrapper}>
              <StrapiImage
                className={styles.image}
                image={person.image}
                format="medium"
                alt={`Photo of ${person.name}`}
              />
            </div>
          )}

          {/* Details */}
          <div className={styles.details}>
            <h1 className={styles.detailsName}>{person.name}</h1>
            <h2 className={styles.detailsRole}>{person.role}</h2>
          </div>

          {/* Links */}
          {person.urls.length > 0 && (
            <ul className={styles.links}>
              {person.urls.map((link) => {
                if (!link.label) return null;

                return (
                  <li
                    key={`person-link-${link.id}`}
                    className={styles.linkItem}
                  >
                    <StrapiLink
                      className={styles.link}
                      link={link}
                      underline="hover"
                    />
                  </li>
                );
              })}
            </ul>
          )}

          {/* Biography */}
          <ReactMarkdown
            className={styles.biography}
            rehypePlugins={[
              [rehypeExternalLinks, { rel: ['noopener', 'noreferrer'] }],
              [
                rehypeAddClasses,
                {
                  a: styles.biographyLink,
                  ul: styles.biographyList,
                  li: styles.biographyListItem,
                },
              ],
              rehypeRaw,
            ]}
          >
            {person.biography ?? ''}
          </ReactMarkdown>

          {/* Back link */}
          <div className={styles.backLinkWrapper}>
            <BackLink link="/people" label="Back to all people" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  person: state.person.personRequest.data,
  personStatus: state.person.personRequest.status,
  collectionSettings: state.collectionSettings.collectionSettingsRequest.data,
});

const mapDispatchToProps = {
  fetchPerson,
};

interface IPersonProps extends ConnectedProps<typeof connector> {}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Person);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as strapi from 'services/strapi';

import { IApiRequest } from 'types/ApiRequest';
import { IProject } from 'types/generated/strapi';

export const fetchProject = createAsyncThunk(
  'project/fetch',
  async (slug: string) => {
    const response = await strapi.getProjectBySlug(slug);
    return response;
  }
);

export const fetchProjectPreview = createAsyncThunk(
  'project/fetch',
  async (id: number) => {
    const response = await strapi.getProjectPreviewById(id);
    return response;
  }
);

export const fetchWinningProjects = createAsyncThunk(
  'projectsWinning/fetch',
  async (searchParams: any) => {
    const response = await strapi.getWinningProjects(searchParams);
    return response;
  }
);

export const fetchWinningProjectsCount = createAsyncThunk(
  'projectsWinningCount/fetch',
  async (searchParams: any) => {
    const response = await strapi.getWinningProjectsCount(searchParams);
    return response;
  }
);

export const fetchShortlistedProjects = createAsyncThunk(
  'projectsShortlisted/fetch',
  async (searchParams: any) => {
    const response = await strapi.getShortlistedProjects(searchParams);
    return response;
  }
);

export const fetchShortlistedProjectsCount = createAsyncThunk(
  'projectsShortlistedCount/fetch',
  async (searchParams: any) => {
    const response = await strapi.getShortlistedProjectsCount(searchParams);
    return response;
  }
);

export interface IProjectSlice {
  projectRequest: IApiRequest<IProject>;
  winningProjectsRequest: IApiRequest<IProject[]>;
  winningProjectsCountRequest: IApiRequest<number>;
  shortlistedProjectsRequest: IApiRequest<IProject[]>;
  shortlistedProjectsCountRequest: IApiRequest<number>;
}

const initialState: IProjectSlice = {
  projectRequest: {
    status: 'initial',
  },
  winningProjectsRequest: {
    status: 'initial',
  },
  winningProjectsCountRequest: {
    status: 'initial',
  },
  shortlistedProjectsRequest: {
    status: 'initial',
  },
  shortlistedProjectsCountRequest: {
    status: 'initial',
  },
};

export const winningProjectsSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Single project
      .addCase(fetchProject.pending, (state) => {
        state.projectRequest.status = 'pending';
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.projectRequest.status = 'fulfilled';
        state.projectRequest.data = action.payload;
      })
      .addCase(fetchProject.rejected, (state, action) => {
        state.projectRequest.status = 'rejected';
        state.projectRequest.error = action.error;
      })

      // Winning projects
      .addCase(fetchWinningProjects.pending, (state) => {
        state.winningProjectsRequest.status = 'pending';
      })
      .addCase(fetchWinningProjects.fulfilled, (state, action) => {
        state.winningProjectsRequest.status = 'fulfilled';
        state.winningProjectsRequest.data = action.payload;
      })
      .addCase(fetchWinningProjects.rejected, (state, action) => {
        state.winningProjectsRequest.status = 'rejected';
        state.winningProjectsRequest.error = action.error;
      })

      // Winning projects count
      .addCase(fetchWinningProjectsCount.pending, (state) => {
        state.winningProjectsCountRequest.status = 'pending';
      })
      .addCase(fetchWinningProjectsCount.fulfilled, (state, action) => {
        state.winningProjectsCountRequest.status = 'fulfilled';
        state.winningProjectsCountRequest.data = action.payload;
      })
      .addCase(fetchWinningProjectsCount.rejected, (state, action) => {
        state.winningProjectsCountRequest.status = 'rejected';
        state.winningProjectsCountRequest.error = action.error;
      })

      // Shortlisted projects
      .addCase(fetchShortlistedProjects.pending, (state) => {
        state.shortlistedProjectsRequest.status = 'pending';
      })
      .addCase(fetchShortlistedProjects.fulfilled, (state, action) => {
        state.shortlistedProjectsRequest.status = 'fulfilled';
        state.shortlistedProjectsRequest.data = action.payload;
      })
      .addCase(fetchShortlistedProjects.rejected, (state, action) => {
        state.shortlistedProjectsRequest.status = 'rejected';
        state.shortlistedProjectsRequest.error = action.error;
      })

      // Shortlisted projects count
      .addCase(fetchShortlistedProjectsCount.pending, (state) => {
        state.shortlistedProjectsCountRequest.status = 'pending';
      })
      .addCase(fetchShortlistedProjectsCount.fulfilled, (state, action) => {
        state.shortlistedProjectsCountRequest.status = 'fulfilled';
        state.shortlistedProjectsCountRequest.data = action.payload;
      })
      .addCase(fetchShortlistedProjectsCount.rejected, (state, action) => {
        state.shortlistedProjectsCountRequest.status = 'rejected';
        state.shortlistedProjectsCountRequest.error = action.error;
      });
  },
});

export default winningProjectsSlice.reducer;

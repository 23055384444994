import { useEffect, useMemo, useState, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { connect, ConnectedProps } from 'react-redux';
import { fetchArticle } from 'redux/articleSlice';
import { AppState } from 'redux/store';

import ReactMarkdown from 'react-markdown';

import Error404 from 'pages/Error404/Error404';

import Layout from 'components/shared/Layout';
import SeoHead from 'components/shared/SeoHead';
import BackLink from 'components/shared/BackLink';
import StrapiImage from 'components/shared/StrapiImage';
import ArticleCategory from 'components/shared/ArticleCategory';
import ArticleMeta from 'components/shared/ArticleMeta';
import VideoEmbed from 'components/shared/VideoEmbed';

import PageHero from 'components/sections/PageHero';

import { ICollectionHeader } from 'types/generated/strapi';

import styles from './Article.module.css';
import generateStaticPage from 'utils/generateStaticPage';
import Loading from 'components/shared/Loading';

const Article: FunctionComponent<IArticleProps> = (props) => {
  const { slug } = useParams();
  const { article, articleStatus, fetchArticle, collectionSettings } = props;
  const { articleCategory } = { ...article };

  const [pageIsReady, setPageIsReady] = useState(false);

  useEffect(() => {
    (async () => {
      if (!slug) return;
      if (article?.slug !== slug) await fetchArticle(slug);
      setPageIsReady(true);
      generateStaticPage();
    })();
  }, [slug, article, fetchArticle]);

  const pageHeroSection: ICollectionHeader | undefined = useMemo(() => {
    return collectionSettings?.collectionHeaders.find((collection) => {
      return collection.collectionType === 'Articles';
    });
  }, [collectionSettings]);

  const articleCategoryName = useMemo(() => {
    return typeof articleCategory === 'number'
      ? ''
      : articleCategory?.name || '';
  }, [articleCategory]);

  const articleCategorySlug = useMemo(() => {
    return typeof articleCategory === 'number'
      ? ''
      : articleCategory?.slug || '';
  }, [articleCategory]);

  if (
    article === undefined ||
    articleStatus === 'pending' ||
    slug !== article?.slug
  )
    return <Loading />;
  if (article === null) return <Error404 />;

  return (
    <Layout>
      <SeoHead seo={article.SEO} />

      {/* Hero */}
      {pageHeroSection && <PageHero section={pageHeroSection} />}

      {/* Article */}
      <div
        className={clsx(styles.article, !pageIsReady && styles.articleLoading)}
      >
        <div className={styles.articleInner}>
          {/* Article header */}
          <div className={styles.articleHeader}>
            <div>
              {article.articleCategory && (
                <ArticleCategory
                  name={articleCategoryName}
                  slug={articleCategorySlug}
                  slugPageWithList="news"
                />
              )}
            </div>

            <StrapiImage
              className={styles.thumbnail}
              image={article.banner || article.thumbnail}
              format="large"
            />

            <ArticleMeta article={article} />

            <h1 className={styles.title}>{article.title}</h1>
          </div>

          {/* Article content */}
          <ReactMarkdown
            className={styles.markdown}
            components={{
              // [YOUTUBE_VIDEO](https://www.youtube.com/watch?v=_EGr6TdINSQ) will render youtube video
              // [VIMEO_VIDEO](https://vimeo.com/200877251) will render vimeo video
              a: ({ href, children }) => {
                if (!href) return null;

                // YouTube and Vimeo
                if (
                  children &&
                  (children[0] === 'YOUTUBE_VIDEO' ||
                    children[0] === 'VIMEO_VIDEO')
                ) {
                  return <VideoEmbed url={href} />;
                }

                // Link
                return <a href={href}>{children}</a>;
              },
            }}
          >
            {article.content}
          </ReactMarkdown>

          {/* Back to news link*/}
          <div className={styles.backLinkWrapper}>
            <BackLink link="/news" label="Back to news" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  article: state.article?.articleRequest?.data,
  articleStatus: state.article?.articleRequest?.status,
  collectionSettings: state.collectionSettings.collectionSettingsRequest.data,
});

const mapDispatchToProps = {
  fetchArticle: fetchArticle,
};

interface IArticleProps extends ConnectedProps<typeof connector> {}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(Article);

/**
 * Date ISO string at the start of today
 *
 * e.g. '2021-12-31T00:00:00.000Z'
 */

const getTodayString = (): string => {
  return new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
};

export default getTodayString;

import { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { IAwardsStage, IAwardsTimeline } from 'types/generated/strapi';
import styles from './index.module.css';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import HiglightedSpans from 'components/shared/HighlightedSpans';

interface IAwardsTimelineItemProps {
  stage: IAwardsStage;
  isStageInProgress: boolean;
  isCompletedStage: boolean;
}

const AwardsTimelineItem: FunctionComponent<IAwardsTimelineItemProps> = (
  props
) => {
  const { isStageInProgress, isCompletedStage } = props;
  const { date, title, copy, name } = props.stage;
  const localizedDate = date ? new Date(date).toLocaleDateString('en-GB') : '';

  return (
    <div className={styles.stage}>
      <div className={styles.stageCopy}>
        {title && (
          <h2 className={clsx(styles.title, styles.lgDownHidden)}>{title}</h2>
        )}

        <div className={clsx(styles.stageName, styles.lgUpHidden)}>{name}</div>
        <div className={clsx(styles.stageDate, styles.lgUpHidden)}>
          {localizedDate}
        </div>

        {copy && (
          <ReactMarkdown
            children={copy}
            className={styles.copy}
            components={{
              a: ({ node, href, children }) => {
                if (!href) return null;

                // [BUTTON_Downloads](https://any.com/super.pdf) will render link with button style
                if (
                  children[0] &&
                  typeof children[0] === 'string' &&
                  children[0].includes('BUTTON_')
                ) {
                  const label = children[0].split('_')[1];

                  return (
                    <a
                      className={styles.button}
                      href={href}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {label}
                    </a>
                  );

                  //[social or environmental impact](HIGHLIGHTED) will render span with highlighted class
                } else if (href === 'HIGHLIGHTED') {
                  const text = children[0];
                  if (typeof text !== 'string') return null;

                  return <HiglightedSpans text={text} />;

                  //[any](/page) will render Link component
                } else if (href.charAt(0) === '/') {
                  return <Link to={href}>{children}</Link>;
                }

                // Link
                return <a href={href}>{children}</a>;
              },
            }}
          />
        )}
      </div>
      <div
        className={clsx(
          styles.stageTimeline,
          isCompletedStage && styles.stageTimelineCompleted,
          isStageInProgress && styles.stageTimelineInProgress
        )}
      >
        <span className={styles.circle} />
        <span className={styles.line} />
      </div>
      <div className={styles.stageDeadline}>
        <div className={styles.stageName}>{name}</div>
        <div className={styles.stageDate}>{localizedDate}</div>
      </div>
    </div>
  );
};

interface IAwardsTimelineProps {
  section: IAwardsTimeline;
}

const AwardsTimeline: FunctionComponent<IAwardsTimelineProps> = (props) => {
  const { timeline } = props.section;
  const isStageInProgress = (stage: IAwardsStage) => {
    const firstNotCompletedStage = allNotCompletedStages[0];
    return firstNotCompletedStage
      ? stage.id === firstNotCompletedStage.id
      : false;
  };
  const isCompletedStage = (date: Date | undefined) => {
    if (date) {
      const dateNowUTC = new Date().toISOString();
      const dateNowUTCMil = new Date(dateNowUTC).getTime();
      const eventStartsDateUTCMil = new Date(date).getTime();
      if (eventStartsDateUTCMil < dateNowUTCMil) {
        return true;
      }
    }
    return false;
  };
  const allNotCompletedStages = timeline.filter(
    (c) => !isCompletedStage(c.date)
  );

  return (
    <div
      className={styles.timelineStages}
      data-sidebar-target
      data-sidebar-sticky
      data-sidebar-padding-bottom="16rem"
    >
      <div className={styles.timelineStagesInner}>
        {timeline.map((stage) => (
          <AwardsTimelineItem
            isStageInProgress={isStageInProgress(stage)}
            isCompletedStage={isCompletedStage(stage.date)}
            key={stage.id}
            stage={stage}
          />
        ))}
      </div>
    </div>
  );
};

export default AwardsTimeline;

import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';

const ScrollToTop: FunctionComponent<IPageProps> = (props) => {
  const { pathname } = useLocation();
  // const { pageRequestStatus } = props;

  // useLayoutEffect(() => {
  //   switch (pageRequestStatus) {
  //     case 'pending':
  //       document.documentElement.style.opacity = '0';
  //       break;
  //     case 'fulfilled':
  //       document.documentElement.style.opacity = '1';
  //       break;
  //   }
  // }, [pageRequestStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const mapStateToProps = (state: AppState) => ({
  pageRequestStatus: state.page.pageRequest.status,
});

const connector = connect(mapStateToProps);
interface IPageProps extends ConnectedProps<typeof connector> {}

export default connector(ScrollToTop);

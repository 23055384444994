import React, { FunctionComponent, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';

import StrapiLink from 'components/shared/StrapiLink';

import LogoImage from 'assets/img/logo-with-paint.png';
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';

import styles from './index.module.css';

interface INavbarProps extends PropsFromRedux {}

const Navbar: FunctionComponent<INavbarProps> = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const isPurple = location.pathname === '/' || location.pathname === '/people';
  const isActivePage = (slug: string) =>
    location.pathname === '/'
      ? slug === 'home'
      : location.pathname === `/${slug}`;
  const showMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuOpen(true);
  };

  const hideMenu = () => setMenuOpen(false);

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener('click', hideMenu);
    };

    const removeEventListeners = () => {
      document.removeEventListener('click', hideMenu);
    };

    addEventListeners();
    return () => removeEventListeners();
  }, []);

  if (!props.navbar?.Links) return null;

  return (
    <div className={styles.navbar}>
      <div className={styles.navbarInner}>
        {/* Logo */}
        <NavLink className={styles.logoWrapper} onClick={hideMenu} to="/">
          <img src={LogoImage} alt="Logo" />
        </NavLink>

        {/* Menu Icon */}
        <button
          className={clsx(
            styles.menuButton,
            menuOpen && styles.menuButtonHide,
            isPurple && styles.menuButtonPurple
          )}
          onClick={(event) => showMenu(event)}
        >
          <span className="visually-hidden">Show menu</span>
          <MenuIcon />
        </button>

        {/* Links container */}
        <div
          className={clsx(
            styles.navLinksWrapper,
            menuOpen && styles.navLinksWrapperShow
          )}
          onClick={(event) => event.stopPropagation()}
        >
          {/* Close Icon */}
          <button className={styles.closeButton} onClick={hideMenu}>
            <span className="visually-hidden">Hide menu</span>
            <CloseIcon />
          </button>
          <ul className={styles.navItems}>
            {/* Links */}
            {props.navbar.Links.map((link) => (
              <li className={styles.navbarLinkItem} key={link.id}>
                <StrapiLink
                  className={clsx(
                    styles.navbarLinkItemLink,
                    isActivePage(link.page?.slug || link.url || '') &&
                      styles.navbarLinkItemLinkActive
                  )}
                  onClick={hideMenu}
                  link={link}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  navbar: state.menu.menuRequest.data,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Navbar);

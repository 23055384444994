export const yearOfStudySelectorOptions = [
  { label: '', value: '' },
  { label: '1st Year', value: '1st Year' },
  { label: '2nd Year', value: '2nd Year' },
  { label: '3rd Year', value: '3rd Year' },
  { label: '4th Year', value: '4th Year' },
  { label: '5th Year', value: '5th Year' },
  { label: '6th Year', value: '6th Year' },
  { label: '7th Year', value: '7th Year' },
  { label: 'Graduated', value: 'Graduated' },
];

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import pageSlice from './pageSlice';
import menuSlice from './menuSlice';
import footerSlice from './footerSlice';
import sidebarSlice from './sidebarSlice';
import collectionSettingsSlice from './collectionSettingsSlice';
import articleSlice from './articleSlice';
import personSlice from './personSlice';
import categorySlice from './categorySlice';
import eventSlice from './eventSlice';
import productSlice from './productSlice';
import projectSlice from './projectSlice';
import disciplineSlice from './disciplineSlice';
import projectThemeSlice from './projectThemeSlice';
import awardBriefSlice from './awardBriefSlice';
import awardSlice from './awardSlice';

export const store = configureStore({
  reducer: {
    page: pageSlice,
    menu: menuSlice,
    footer: footerSlice,
    sidebar: sidebarSlice,
    collectionSettings: collectionSettingsSlice,
    article: articleSlice,
    person: personSlice,
    category: categorySlice,
    event: eventSlice,
    product: productSlice,
    project: projectSlice,
    discipline: disciplineSlice,
    projectTheme: projectThemeSlice,
    awardBrief: awardBriefSlice,
    award: awardSlice,
  },
  devTools: true,
});

export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;

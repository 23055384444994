import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IAward } from 'types/generated/strapi';

export const fetchAwards = createAsyncThunk('awards/fetch', async () => {
  const response = await strapi.getAwards();
  return response;
});

export interface IAwardSlice {
  awardsRequest: IApiRequest<IAward[]>;
}

const initialState: IAwardSlice = {
  awardsRequest: {
    status: 'initial',
  },
};

export const awardSlice = createSlice({
  name: 'award',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchAwards.pending, (state) => {
        state.awardsRequest.status = 'pending';
      })
      .addCase(fetchAwards.fulfilled, (state, action) => {
        state.awardsRequest.status = 'fulfilled';
        state.awardsRequest.data = action.payload;
      })
      .addCase(fetchAwards.rejected, (state, action) => {
        state.awardsRequest.status = 'rejected';
        state.awardsRequest.error = action.error;
      });
  },
});

export default awardSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IArticleCategory } from 'types/generated/strapi';

export const fetchCategories = createAsyncThunk(
  'categories/fetch',
  async () => {
    const response = await strapi.getCategories();
    return response;
  }
);

export interface IArticleCategorySlice {
  categoriesRequest: IApiRequest<IArticleCategory[]>;
}

const initialState: IArticleCategorySlice = {
  categoriesRequest: {
    status: 'initial',
  },
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.categoriesRequest.status = 'pending';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categoriesRequest.status = 'fulfilled';
        state.categoriesRequest.data = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.categoriesRequest.status = 'rejected';
        state.categoriesRequest.error = action.error;
      });
  },
});

export default categorySlice.reducer;

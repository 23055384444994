/**
 * Truncate a string to a particular length with an ellipsis
 *
 * e.g. "Ac dignissim urna accumsan vel ridiculus" -> "Ac dignissim urna…"
 */

const truncateString = (
  string: string,
  options?: {
    length?: number;
    breakAtWord?: boolean;
    separator?: string;
    overflow?: string;
  }
): string => {
  if (!string) return '';

  const maxLength = typeof options?.length === 'number' ? options.length : 400;
  const separator = options?.separator ?? ' ';
  const breakAtWord = options?.breakAtWord ?? true;
  const overflow = options?.overflow ?? '…';

  if (string.length <= maxLength) return string;

  if (breakAtWord) {
    return `${string.substring(
      0,
      string.lastIndexOf(separator, maxLength)
    )}${overflow}`;
  }

  return `${string.substring(0, maxLength)}${overflow}`;
};

export default truncateString;

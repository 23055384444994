import { FunctionComponent, MouseEvent } from 'react';
import styles from './index.module.css';
import { Link, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

interface IPaginationProps {
  page: number;
  totalPages: number;
  withPrevPage: boolean;
  withNextPage: boolean;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const Pagination: FunctionComponent<IPaginationProps> = ({
  page,
  totalPages,
  withPrevPage,
  withNextPage,
  onClick,
}) => {
  const [searchParams] = useSearchParams();

  if (totalPages < 2) return null;

  const getSearchParamsWithPageQuery = (index: number) => {
    const serachParamsWithPage = Object.assign(
      Object.fromEntries(searchParams.entries()), // Existing search params
      { page: index } // Page query
    );

    return '?' + new URLSearchParams(serachParamsWithPage).toString();
  };

  const getPageNumbers = () => {
    const DOTS = '...';
    const siblingCount = 1;
    const totalPageNumbers = siblingCount + 5;

    const range = (start: number, end: number) => {
      let length = end - start + 1;
      return Array.from({ length }, (_, idx) => idx + start);
    };

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPages]
    */
    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, totalPages);

    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPages - rightItemCount + 1, totalPages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

    return [];
  };

  return (
    <div className={styles.pagination}>
      {/* Previous page */}
      {!withPrevPage && <span className={styles.paginationButton}>&larr;</span>}
      {withPrevPage && (
        <Link
          to={getSearchParamsWithPageQuery(page - 1)}
          className={styles.paginationButton}
          onClick={onClick}
        >
          &larr;
        </Link>
      )}

      {/* Page numbers */}
      {getPageNumbers().map((el, index) => {
        if (typeof el === 'string') {
          return (
            <span key={index} className={styles.paginationButton}>
              {el}
            </span>
          );
        }
        return (
          <Link
            to={getSearchParamsWithPageQuery(el)}
            key={index}
            className={clsx(
              styles.paginationButton,
              page === el && styles.paginationButtonActive
            )}
            onClick={onClick}
          >
            {el}
          </Link>
        );
      })}

      {/* Next page */}
      {withNextPage && (
        <Link
          to={getSearchParamsWithPageQuery(page + 1)}
          className={styles.paginationButton}
          onClick={onClick}
        >
          &rarr;
        </Link>
      )}
      {!withNextPage && <span className={styles.paginationButton}>&rarr;</span>}
    </div>
  );
};

export default Pagination;

import { FunctionComponent, useState, FormEvent, useMemo } from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';

import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';

import * as strapi from 'services/strapi';
import StrapiImage from 'components/shared/StrapiImage';
import StrapiLink from 'components/shared/StrapiLink';
import DonationBlack from '../../sections/DonationBlack';

import styles from './index.module.css';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

interface IFooterProps extends PropsFromRedux {}

const Footer: FunctionComponent<IFooterProps> = (props) => {
  const [email, setEmail] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();

  const withoutPaddingTop = ['/events', '/events/'].includes(location.pathname);
  const withoutDonationBlack = [
    '/membership',
    '/membership/',
    '/awards-entry',
    '/awards-entry/',
    '/entry-form',
    '/entry-form/',
  ].includes(location.pathname);

  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  if (!props.footer) return null;

  const clearData = () => {
    setName('');
    setEmail('');
    setError('');
    setEmailSubmitted(false);
  };

  const startOver = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    clearData();
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      return;
    } else if (!name) {
      setEmailSubmitted(true);
      return;
    }

    // let's send it
    const result = await strapi.mailChimpSubscribe(email, name);
    if (result.success) {
      clearData();
      setSuccess(true);
    } else {
      clearData();
      setError(result.message);
      if (result.error) {
        console.error(result.error);
      }
    }
  };

  return (
    <div>
      {!withoutDonationBlack && props.footer.donate && (
        <DonationBlack section={props.footer.donate} />
      )}

      <div
        className={clsx(styles.footer, {
          [styles.withNoPaddingTop]: withoutPaddingTop,
        })}
      >
        <div className={styles.footerInner}>
          {/* Form title */}
          <div className={styles.formTitle}>
            Subscribe for
            <br />
            better creativity
          </div>

          {/* Errors */}
          {error && (
            <div className={styles.formError}>
              {error}
              <button className={styles.startOver} onClick={startOver}>
                Please start over
              </button>
            </div>
          )}

          {/* Success */}
          {!error && success && (
            <div className={styles.formSuccess}>
              Thanks for contacting us! We will get in touch with you shortly.
            </div>
          )}

          {/* Form */}
          {!error && !success && (
            <form onSubmit={onSubmit}>
              <div className={styles.formGroup}>
                {/* email */}
                {!emailSubmitted && (
                  <input
                    className={styles.input}
                    type="email"
                    placeholder="your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required={true}
                  />
                )}
                {/* name */}
                {emailSubmitted && (
                  <input
                    className={styles.input}
                    type="text"
                    placeholder="your name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    required={true}
                  />
                )}
                <button className={styles.submitButton}>
                  <ArrowIcon className={styles.submitArrow} />
                  <span className="visually-hidden">Submit</span>
                </button>
              </div>
            </form>
          )}

          {/* Partners */}
          <div className={styles.partners}>
            <div className={styles.partnersItems}>
              {props.footer.partners.map((partner) => {
                return (
                  <a
                    className={styles.partner}
                    key={partner.id}
                    href={partner.linkUrl}
                    target="blank"
                    rel="noreferrer nofollow"
                  >
                    <StrapiImage
                      className={styles.partnerLogo}
                      image={partner.logo}
                      format="medium"
                    />
                  </a>
                );
              })}
            </div>
            <div className={styles.partnersTitleContainer}>
              <div className={styles.partnersTitle}>Sponsors + partners</div>
            </div>
          </div>

          {/* Copyright, links */}
          <div>
            <div className={styles.footerCopyright}>
              © {currentYear} Creative Conscience | Charity Number: 1170093
            </div>
            <ul className={styles.footerLinks}>
              {props.footer.links.map((footerLink) => {
                return (
                  <li key={footerLink.id} className={styles.footerLinkItem}>
                    <StrapiLink link={footerLink} underline="hover" />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  footer: state.footer.footerRequest.data,
});
const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Footer);

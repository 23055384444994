import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IProduct } from 'types/generated/strapi';

export const fetchProducts = createAsyncThunk('products/fetch', async () => {
  const response = await strapi.getAllProducts();
  return response;
});

export interface IProductSlice {
  productsRequest: IApiRequest<IProduct[]>;
}

const initialState: IProductSlice = {
  productsRequest: {
    status: 'initial',
  },
};

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // All products
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.productsRequest.status = 'pending';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productsRequest.status = 'fulfilled';
        state.productsRequest.data = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.productsRequest.status = 'rejected';
        state.productsRequest.error = action.error;
      });
  },
});

export default productSlice.reducer;

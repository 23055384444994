import { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import clsx, { ClassValue } from 'clsx';

import formatSlug from 'utils/formatSlug';

import { ILinkItem } from 'types/generated/strapi';

import styles from './index.module.css';

interface IStrapiLinkProps {
  link: ILinkItem;
  className?: string;
  underline?: 'always' | 'hover' | 'never';
  onClick?: () => void;
}

const StrapiLink: FunctionComponent<IStrapiLinkProps> = (props) => {
  const { link, underline } = props;

  const internalUrl = useMemo(() => {
    const slug = link.page?.slug === 'home' ? '/' : link.page?.slug || '/';
    return formatSlug(`${slug}${link.anchor || ''}`);
  }, [link]);

  const linkLabel = useMemo(() => {
    return link.label || link.page?.title || link.url || '';
  }, [link]);

  const linkStyles: ClassValue = useMemo(() => {
    if (underline === 'hover') return styles.linkUnderlineHover;
    if (underline === 'always') return styles.linkUnderline;
    return styles.link;
  }, [underline]);

  if (!linkLabel) return null;

  if (link.url) {
    return (
      <a
        className={clsx(linkStyles, props.className)}
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={props.onClick}
      >
        {linkLabel}
      </a>
    );
  } else if (link.page) {
    return (
      <Link
        onClick={props.onClick}
        className={clsx(linkStyles, props.className)}
        to={internalUrl}
      >
        {linkLabel}
      </Link>
    );
  }

  return <span>{linkLabel}</span>;
};

export default StrapiLink;

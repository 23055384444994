import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as strapi from 'services/strapi';

import { IApiRequest } from 'types/ApiRequest';
import { ICollectionSettings } from 'types/generated/strapi';

export const fetchCollectionSettings = createAsyncThunk(
  'collection-settings/fetch',
  async () => {
    const response = await strapi.getCollectionSettings();
    return response;
  }
);

export interface ICollectionSettingsSlice {
  collectionSettingsRequest: IApiRequest<ICollectionSettings>;
}

const initialState: ICollectionSettingsSlice = {
  collectionSettingsRequest: {
    status: 'initial',
  },
};

export const collectionSettingsSlice = createSlice({
  name: 'collection-settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCollectionSettings.pending, (state) => {
        state.collectionSettingsRequest.status = 'pending';
      })
      .addCase(fetchCollectionSettings.fulfilled, (state, action) => {
        state.collectionSettingsRequest.status = 'fulfilled';
        state.collectionSettingsRequest.data = action.payload;
      })
      .addCase(fetchCollectionSettings.rejected, (state, action) => {
        state.collectionSettingsRequest.status = 'rejected';
        state.collectionSettingsRequest.error = action.error;
      });
  },
});

export default collectionSettingsSlice.reducer;

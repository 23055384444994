import {
  IMembershipBenefits,
  IProduct,
  IVariation,
} from 'types/generated/strapi';
import styles from './index.module.css';
import { useState, useMemo, ChangeEvent, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect, ConnectedProps } from 'react-redux';
import { fetchProducts } from 'redux/productSlice';
import { AppState } from 'redux/store';
import benefitBackground from 'assets/img/benefit-background.png';
import StrapiImage from 'components/shared/StrapiImage';
import StyledSelect from 'components/shared/StyledSelect';
import { getSnipcart, addItem, clearCart } from 'services/snipcart';
import { useLocation, useNavigate } from 'react-router';
import { useLoading } from 'utils/useLoading';

const MembershipDetails = (
  props: { section: IMembershipBenefits } & ConnectedProps<typeof connector>
) => {
  const { allProducts, allProductsStatus, section, fetchProducts } = props;
  const { packages } = section;
  const location = useLocation();
  const [loading, startLoading, stopLoading] = useLoading();
  const [error, setError] = useState<string | undefined>();
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [selectedVariation, setSelectedVariation] = useState<IVariation | null>(
    null
  );

  // Fetch products
  useEffect(() => {
    if (allProductsStatus === 'initial') {
      fetchProducts();
    }
  }, [allProductsStatus, fetchProducts]);

  // Strapi in response gives us products without variations (not populate)
  const products = useMemo(() => {
    if (!allProducts.length || !packages.length) return [];
    const sectionProductsIds = packages.map(({ id }) => id);
    return allProducts.filter((prod) => sectionProductsIds.includes(prod.id));
  }, [allProducts, packages]);

  // Select first product after initial fetch
  useEffect(() => {
    if (selectedProduct || !products.length) return;
    let product;
    // Set product from location hash
    if (location.hash) {
      const withoutHash = location.hash.substring(1);
      const parsedId = parseInt(withoutHash, 10);
      if (isNaN(parsedId)) {
        product = products[0];
      } else {
        product = products.find((c) => c.id === parsedId) || products[0];
      }
    } else {
      product = products[0];
    }
    setSelectedProduct(product);
    setSelectedVariation(product.variations[0]);
  }, [products, setSelectedProduct, selectedProduct, location.hash]);

  // On change product select
  const onChangeProduct = (event: ChangeEvent<HTMLSelectElement>) => {
    const product = products.find((c) => c.name === event.target.value);
    if (!product) return;
    setSelectedProduct(product);
    setSelectedVariation(product.variations[0]);
    setQuantity(1);
  };

  // On change variation select
  const onChangeVariation = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedVariation(
      selectedProduct?.variations.find((c) => c.name === event.target.value) ||
        null
    );
  };

  // Submit
  const onButtonClick = async () => {
    if (!selectedProduct) {
      setError('Product not found');
      return;
    }
    if (!selectedVariation) {
      setError('Variation not found');
      return;
    }
    startLoading();
    const snipcart = await getSnipcart();
    await clearCart(snipcart);
    // Add item to cart
    try {
      await addItem(snipcart, selectedProduct, selectedVariation, quantity);
    } catch (e) {
      setError('Error adding item to cart.');
      stopLoading();
      return;
    }
    stopLoading();
    // TODO: rename slug membership-form
    navigate(`/membership-details`);
  };

  const totalPrice = useMemo(() => {
    if (!selectedVariation) return 0;

    return selectedVariation.price * quantity;
  }, [selectedVariation, quantity]);

  const formatedTotalPrice = useMemo(() => {
    return Intl.NumberFormat('en-GB').format(totalPrice);
  }, [totalPrice]);

  const productSelectorOptions = useMemo(
    () => products.map((c) => ({ label: c.name, value: c.name })),
    [products]
  );

  const variationSelectorOptions = useMemo(() => {
    if (!selectedProduct?.variations?.length) return [];
    return selectedProduct.variations.map((c) => ({
      label: c.name,
      value: c.name,
    }));
  }, [selectedProduct?.variations]);

  return (
    <div
      className={styles.membershipBenefits}
      data-sidebar-target
      data-sidebar-sticky
    >
      <div className={styles.membershipBenefitsInner}>
        {/* Title, description */}
        <div className={styles.membershipBenefitsDescription}>
          <StrapiImage
            className={styles.backgroundImage}
            image={section.backgroundImage}
            format="medium"
          />
          <div className={styles.title}>{section.title}</div>
          <ReactMarkdown className={styles.description}>
            {section.description}
          </ReactMarkdown>
        </div>

        {/* Packages selector */}
        {selectedProduct && (
          <div className={styles.membershipPackages}>
            <div className={styles.membershipPackagesInner}>
              <div className={styles.inputGroup}>
                <div className={styles.label}>Annual Membership Package</div>
                <StyledSelect
                  onChange={onChangeProduct}
                  options={productSelectorOptions}
                  value={selectedProduct?.name || ''}
                />
              </div>

              {Number(selectedProduct?.variations?.length) > 1 && (
                <div className={styles.inputGroup}>
                  <div className={styles.label}>Select</div>
                  <StyledSelect
                    onChange={onChangeVariation}
                    options={variationSelectorOptions}
                    value={selectedVariation?.name || ''}
                  />
                </div>
              )}

              {selectedProduct?.name === 'Individual' && (
                <div className={styles.inputGroup}>
                  <div className={styles.label}>Quantity</div>
                  <input
                    className={styles.quantityInput}
                    value={quantity.toString()}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      // Minimum 1
                      setQuantity(Math.max(Number(e.target.value), 0))
                    }
                    type="number"
                  />
                </div>
              )}
              <div className={styles.price}>£{formatedTotalPrice}</div>
              <p style={{ color: 'red' }}>{error}</p>
              <div className={styles.applyButtonGroup}>
                <button
                  className={styles.applyButton}
                  onClick={onButtonClick}
                  disabled={loading}
                >
                  Become a member
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Benefits subtitle, grid */}
        <div className={styles.benefitsContainer}>
          <div className={styles.subtitle}>{section.subtitle}</div>
          <div className={styles.benefits}>
            {section.benefits.map((benefit, index) => {
              return (
                <button key={benefit.id} className={styles.benefit}>
                  <div className={styles.benefitInner}>
                    <img
                      className={styles.benefitBackgroundImage}
                      src={benefitBackground}
                      alt=""
                    />
                    <div className={styles.benefitIndex}>{index + 1}</div>
                    <div className={styles.benefitTitle}>{benefit.title}</div>
                    <div className={styles.benefitDescription}>
                      {benefit.description}
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  allProducts: state.product.productsRequest.data ?? [],
  allProductsStatus: state.product.productsRequest.status,
});

const mapDispatchToProps = {
  fetchProducts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(MembershipDetails);

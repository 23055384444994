import StrapiImage from 'components/shared/StrapiImage';
import StrapiLink from 'components/shared/StrapiLink';
import { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ITitleSubtitle } from 'types/generated/strapi';
import styles from './index.module.css';

interface ITitleSubtitleProps {
  section: ITitleSubtitle;
}

const TitleSubtitle: FunctionComponent<ITitleSubtitleProps> = ({ section }) => {
  const { title, subtitle, backgroundImage, button } = section;
  const [searchParams] = useSearchParams();
  const discountCodesQuery = searchParams.get('discountCodes');
  const discountCodes = discountCodesQuery
    ? discountCodesQuery.split(',').map((c) => c.trim())
    : [];

  return (
    <div className={styles.titleSubtitle}>
      <div className={styles.titleSubtitleInner}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.subtitle}>{subtitle}</div>
        {backgroundImage && (
          <StrapiImage
            className={styles.backgrounImage}
            format="medium"
            image={backgroundImage}
          />
        )}
        {!!discountCodes.length && (
          <div className={styles.discountCodes}>
            {discountCodes.map((discountCode) => (
              <div key={discountCode} className={styles.discountCode}>
                {discountCode}
              </div>
            ))}
          </div>
        )}
        {button && (
          <div className={styles.buttonWrapper}>
            <StrapiLink className={styles.button} link={button} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TitleSubtitle;

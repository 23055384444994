import { useEffect } from 'react';
import generateStaticPage from 'utils/generateStaticPage';

// Empty page, we use it as a fallback for all pages
// No need to have any pre-rendered content here
export default function Fallback() {
  useEffect(() => {
    generateStaticPage();
  }, []);

  return null;
}

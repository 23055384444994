import { FunctionComponent, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';

import ReactMarkdown from 'react-markdown';

import { IAboutUs, IAboutUsItem } from 'types/generated/strapi';

import styles from './index.module.css';

import video from 'assets/video/orange-stroke.mp4';

interface IAboutUsItemProps {
  item: IAboutUsItem;
  withVideo: boolean;
}

const AboutUsItem: FunctionComponent<IAboutUsItemProps> = (props) => {
  const { item, withVideo } = props;

  const videoRef = useRef<HTMLVideoElement>(null);

  const { ref, inView } = useInView({
    threshold: 1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      videoRef.current?.play();
    }
  }, [inView]);

  return (
    <div ref={ref} className={styles.item}>
      {/* Digits */}
      <span className={clsx(styles.itemDigit)} aria-hidden="true" />

      {/* Paint splash */}
      <span className={clsx(styles.itemPaintDot)} />

      {/* Title */}
      <div className={clsx(styles.itemTitle, inView && styles.rollUp)}>
        {item.title}
      </div>

      {/* Video splash */}
      {withVideo && (
        <video
          ref={videoRef}
          className={styles.itemPaintDotVideo}
          autoPlay={false}
          muted={true}
          playsInline
          src={video}
        />
      )}

      {/* Text */}
      <ReactMarkdown
        className={clsx(styles.itemDescription, inView && styles.rollUp)}
      >
        {item.description}
      </ReactMarkdown>
    </div>
  );
};

interface IAboutUsProps {
  section: IAboutUs;
}

const AboutUs: FunctionComponent<IAboutUsProps> = (props) => {
  return (
    <div className={styles.aboutUs}>
      <div className={styles.aboutUsInner}>
        <div className={styles.items}>
          {props.section.items.map((item, index) => (
            <AboutUsItem key={item.id} item={item} withVideo={index === 0} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

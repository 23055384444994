import Select from 'components/shared/Select';
import StrapiImage from 'components/shared/StrapiImage';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { fetchProducts } from 'redux/productSlice';
import { AppState } from 'redux/store';
import { IEnterDetails } from 'types/generated/strapi';
import {
  addItem,
  clearCart,
  getSnipcart,
  openCheckout,
  subscribeSnipcartOrder,
} from 'services/snipcart';
import styles from './index.module.css';
import Input from 'components/shared/Input';
import { useNavigate } from 'react-router-dom';
import { useLoading } from 'utils/useLoading';
import { selectorType } from './selectorType';
import hexoid from 'hexoid';
import {
  createAwardsEntryDiscountCodes,
  getDiscountByCode,
} from 'services/strapi';

const EntryDetails = (
  props: {
    section: IEnterDetails;
  } & ConnectedProps<typeof connector>
) => {
  const { section, allProducts, allProductsStatus, fetchProducts } = props;
  const { blocks, title, selector } = section;
  const [activeSelector, setActiveSelector] = useState(() => selector[0]);
  const [quantity, setQuantity] = useState<number>(1);
  const [entrants, setEntrants] = useState<number>(2);
  const [discountCode, setDiscountCode] = useState<string>('');
  const [error, setError] = useState<string | undefined>();
  const [loading, startLoading, stopLoading] = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    if (allProductsStatus === 'initial') {
      fetchProducts();
    }
  }, [allProductsStatus, fetchProducts, startLoading]);

  const selectOptions = useMemo(
    () => selector.map((c) => ({ label: c.label, value: c.label })),
    [selector]
  );

  const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const target = selector.find((c) => c.label === event.target.value);
    target && setActiveSelector(target);
  };

  // Register Snipcart event callbacks
  // Note: this is just for the "Pre-pay for others"
  // flow, because the other options will unload this
  // component and redirect to the submission form
  useEffect(() => {
    var unsubscribeSnipcartOrder: () => void | undefined;
    (async () => {
      var snipcart = await getSnipcart();
      unsubscribeSnipcartOrder = subscribeSnipcartOrder(
        snipcart,
        async (order: any) => {
          snipcart.api.theme.cart.close();
          startLoading();
          const strapiResponse = await createAwardsEntryDiscountCodes(order);
          if (strapiResponse.success) {
            // Thank you page
            window.location.replace(
              `/${
                activeSelector.link?.page?.slug
              }?discountCodes=${strapiResponse.discountCodes.join(',')}`
            );
          } else {
            setError(`${strapiResponse.message} ${strapiResponse.error}`);
            stopLoading();
          }
        },
        (error: string) => {
          setError(error);
        }
      );
    })();
    // Cleanup
    return () => {
      unsubscribeSnipcartOrder?.();
    };
  }, [activeSelector.link?.page?.slug, navigate, startLoading, stopLoading]);

  const withQuantity = activeSelector.type === selectorType.prePayForOthers;
  const withEntrants =
    activeSelector.type === selectorType.payByMyselfGroup ||
    activeSelector.type === selectorType.prePayForOthersGroup ||
    activeSelector.type === selectorType.usePrePaidCodeGroup;
  const withPrePaidCode =
    activeSelector.type === selectorType.prePayForOthers ||
    activeSelector.type === selectorType.prePayForOthersGroup;

  // Submit
  const onButtonClick = async () => {
    if (!activeSelector.type) {
      setError('Selector type not found');
      return;
    }
    const product = allProducts.find((x) => x.name === 'Awards Entry');
    if (!product) {
      setError('Product not found');
      return;
    }
    const selectorTypeProductNameMap: Record<string, string> = {
      [selectorType.payByMyself]: 'Awards Individual Entry',
      [selectorType.payByMyselfGroup]: 'Awards Group Entry',
      [selectorType.prePayForOthers]: 'Prepaid Individual Awards Entries',
      [selectorType.prePayForOthersGroup]: 'Prepaid Group Award Entry',
      [selectorType.usePrePaidCode]: 'Awards Individual Entry',
      [selectorType.usePrePaidCodeGroup]: 'Awards Group Entry',
    };
    const productName = selectorTypeProductNameMap[activeSelector.type];
    const variation = product.variations.find((x) => x.name === productName);
    if (!variation) {
      setError('Variation not found');
      return;
    }
    startLoading();
    const snipcart = await getSnipcart();
    await clearCart(snipcart);
    // Add item to cart
    try {
      // Entants in group
      const customFields = withEntrants
        ? [
            {
              name: 'Entrants in group',
              type: 'dropdown' as const,
              options: `${entrants}[+${(entrants - 1) * variation.price}]`,
              value: `${entrants}`,
            },
          ]
        : undefined;
      await addItem(
        snipcart,
        product,
        variation,
        withQuantity ? quantity : 1,
        customFields
      );
    } catch (error) {
      setError('Error adding item to cart.');
      stopLoading();
      return;
    }
    // Add discount code
    if (discountCode) {
      const discount = await getDiscountByCode(discountCode);
      if (!discount.success) {
        setError('Discount code invalid');
        stopLoading();
        return;
      }
      try {
        // Validate discount code
        // Group discount code can only be used for group entries
        // Individual discount code can only be used for individual entries
        // Group discount code can only be used for the number of entrants in the group
        const groupDiscount = discount.discount?.name.startsWith(
          'Awards group entry payment code'
        );
        const individualDiscount = discount.discount?.name.startsWith(
          'Awards entry payment code'
        );
        const parsedEntantsFromDiscount = parseInt(
          discount.discount?.name.split(' ')[6] || ''
        );
        if (
          groupDiscount &&
          activeSelector.type !== selectorType.usePrePaidCodeGroup
        ) {
          setError('Group discount code can be used for group entry only.');
          stopLoading();
          return;
        }
        if (
          individualDiscount &&
          activeSelector.type !== selectorType.usePrePaidCode
        ) {
          setError(
            'Individual discount code can be used for individual entry only.'
          );
          stopLoading();
          return;
        }
        if (groupDiscount && isNaN(parsedEntantsFromDiscount)) {
          setError('Group discount code is broken. Please contact us.');
          stopLoading();
          return;
        }
        if (groupDiscount && parsedEntantsFromDiscount !== entrants) {
          setError(
            'Group discount code can only be used for the number of entrants in the group. Please check the number of entrants in the group and try again.'
          );
          stopLoading();
          return;
        }
        // Apply valid discount code
        await snipcart.api.cart.applyDiscount(discountCode);
      } catch (error) {
        setError('Discount code invalid');
        stopLoading();
        return;
      }
    }
    // Add custom fields to be used as discount codes group and individual
    if (withPrePaidCode) {
      const toID = hexoid();
      const name =
        activeSelector.type === selectorType.prePayForOthers
          ? 'Individual payment codes'
          : 'Group payment code';
      const code =
        activeSelector.type === selectorType.prePayForOthers
          ? Array.from({ length: quantity }, (v, i) => toID()).join(', ')
          : toID();

      try {
        await snipcart.api.cart.update({
          customFields: [
            {
              name,
              type: 'readonly',
              value: code,
            },
          ],
        });
      } catch (error) {
        setError(String(error));
        stopLoading();
        return;
      }
    }
    stopLoading();
    // Redirect
    if (withPrePaidCode) {
      openCheckout();
    } else {
      navigate(`/${activeSelector.link?.page?.slug}`);
    }
  };

  return (
    <div className={styles.enterDetails}>
      <div className={styles.enterDetailsInner}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.grid}>
          {!!blocks.length && (
            <div className={styles.textBlocks}>
              {blocks.map((block) => (
                <div key={block.id} className={styles.textBlock}>
                  {!!block.paintDot && (
                    <StrapiImage
                      className={styles.textBlockImage}
                      image={block.paintDot}
                      format="small"
                    />
                  )}
                  {block.title && (
                    <div className={styles.textBlockTitle}>{block.title}</div>
                  )}
                  {block.description && <div>{block.description}</div>}
                </div>
              ))}
            </div>
          )}
          {!!selector.length && (
            <div className={styles.paymentSelector}>
              <div className={styles.sticky}>
                <Select
                  label="Select your payment type"
                  onChange={onSelectChange}
                  options={selectOptions}
                />
                {withQuantity && (
                  <Input
                    classes={{ group: styles.inputGroup }}
                    label="Quantity"
                    value={quantity.toString()}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      // Minimum 1
                      setQuantity(Math.max(Number(e.target.value), 1))
                    }
                    type="number"
                  />
                )}
                {withEntrants && (
                  <Input
                    classes={{ group: styles.inputGroup }}
                    label="Entrants in group"
                    value={entrants.toString()}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      // Minimum 2
                      setEntrants(Math.max(Number(e.target.value), 2))
                    }
                    type="number"
                  />
                )}
                <Input
                  classes={{ group: styles.inputGroup }}
                  label={
                    activeSelector.type === selectorType.usePrePaidCode ||
                    activeSelector.type === selectorType.usePrePaidCodeGroup
                      ? 'Pre-paid code'
                      : 'Discount code'
                  }
                  value={discountCode}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setDiscountCode(e.target.value)
                  }
                />
                <p style={{ color: 'red' }}>{error}</p>
                <div className={styles.applyButtonGroup}>
                  <button
                    className={styles.applyButton}
                    onClick={onButtonClick}
                    disabled={loading}
                  >
                    Proceed
                  </button>
                  {activeSelector.description && (
                    <div className={styles.description}>
                      {activeSelector.description}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  allProducts: state.product.productsRequest.data ?? [],
  allProductsStatus: state.product.productsRequest.status,
});

const mapDispatchToProps = {
  fetchProducts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(EntryDetails);

import { FunctionComponent } from 'react';
import { IAwardsCallToAction } from 'types/generated/strapi';
import styles from './index.module.css';
import ReactMarkdown from 'react-markdown';
import HiglightedSpans from 'components/shared/HighlightedSpans';
import StrapiLink from 'components/shared/StrapiLink';

interface IAwardsCallToActionProps {
  section: IAwardsCallToAction;
}

const AwardsCallToAction: FunctionComponent<IAwardsCallToActionProps> = (
  props
) => {
  const { title, link } = props.section;
  return (
    <div className={styles.awardsCallToAction}>
      <div className={styles.awardsCallToActionInner}>
        <ReactMarkdown
          className={styles.title}
          children={title}
          components={{
            em: ({ node, children, ...props }) => {
              const text = children[0];
              if (typeof text !== 'string') return null;

              return (
                <HiglightedSpans className={styles.highlighted} text={text} />
              );
            },
          }}
        />
        <StrapiLink className={styles.button} link={link} />
      </div>
    </div>
  );
};

export default AwardsCallToAction;

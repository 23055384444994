import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

interface useFilterProps {
  queryName: string;
}

const useFilter = ({ queryName }: useFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterValue = searchParams.getAll(queryName);

  const setValue = useCallback(
    (value: string | number | string[] | number[]) => {
      const searchParamsData = Object.assign(
        {},
        Object.fromEntries(new URLSearchParams(window.location.search)), // Existing search params
        { [queryName]: value }
      );

      setSearchParams(searchParamsData);
    },
    [setSearchParams, queryName]
  );

  return [filterValue, setValue] as const;
};

export default useFilter;

import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import { fetchProducts } from 'redux/productSlice';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeAddClasses from 'rehype-add-classes';
import rehypeRaw from 'rehype-raw';
import type { IAwardsEntry, IProduct } from 'types/generated/strapi';
import styles from './index.module.css';

interface IAwardsEntryProps extends ConnectedProps<typeof connector> {
  section: IAwardsEntry;
}

const AwardsEntry: FunctionComponent<IAwardsEntryProps> = (props) => {
  const { allProducts, allProductsStatus, fetchProducts } = props;
  const { title, description } = props.section;
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);

  // Fetch awards entry products once
  useEffect(() => {
    if (allProductsStatus !== 'initial') return;
    fetchProducts();
  }, [allProductsStatus, fetchProducts]);

  // Select first product after initial fetch
  useEffect(() => {
    if (selectedProduct || allProducts.length <= 0) return;
    setSelectedProduct(allProducts[0]);
  }, [selectedProduct, allProducts]);

  const selectedProductPrice = useMemo(() => {
    return selectedProduct?.variations?.[0].price;
  }, [selectedProduct]);

  const onButtonClick = () => {
    // TODO:
  };

  return (
    <div className={styles.awardsEntry}>
      <div className={styles.awardsEntryInner}>
        <div className={styles.contentWrapper}>
          {/* Title */}
          <h2 className={styles.title}>{title}</h2>

          {/* Description */}
          <div className={styles.description}>
            <ReactMarkdown
              className={styles.descriptionText}
              children={description}
              rehypePlugins={[
                [rehypeExternalLinks, { rel: ['noopener', 'noreferrer'] }],
                [
                  rehypeAddClasses,
                  {
                    ul: styles.descriptionList,
                    li: styles.descriptionListItem,
                  },
                ],
                rehypeRaw,
              ]}
            />
          </div>
        </div>

        <div className={styles.applyWrapper}>
          {selectedProductPrice && (
            <div>
              <div className={styles.label}>Entrance Fee</div>
              <div className={styles.applyPrice}>£{selectedProductPrice}</div>
            </div>
          )}

          <div className={styles.applyButtonGroup}>
            <button className={styles.applyButton} onClick={onButtonClick}>
              Enter the Awards
            </button>
          </div>

          {/* <div className={styles.applyDescription}>
            After checkout, we will ask you to fill in a form with the details
            of your project.
          </div> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  allProducts: state.product.productsRequest.data ?? [],
  allProductsStatus: state.product.productsRequest.status,
});

const mapDispatchToProps = {
  fetchProducts,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AwardsEntry);

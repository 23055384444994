import Input from 'components/shared/Input';
import InputFile from 'components/shared/InputFile';
import Select from 'components/shared/Select';
import Textarea from 'components/shared/Textarea';
import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { fetchDisciplines } from 'redux/disciplineSlice';
import { fetchProjectThemes } from 'redux/projectThemeSlice';
import { fetchAwardBriefs } from 'redux/awardBriefSlice';
import { AppState } from 'redux/store';
import { IEntryForm, IProject } from 'types/generated/strapi';
import styles from './index.module.css';
import nationalityJson from 'assets/data/nationality.json';
import countryJson from 'assets/data/countries.json';
import React from 'react';
import clsx from 'clsx';
import * as strapi from 'services/strapi';
import { fetchAwards } from 'redux/awardSlice';
import slugify from 'slugify';
import { useLoading } from 'utils/useLoading';
import { IEntrantFormData } from './IEntrantFormData';
import { IFormData } from './IFormData';
import { useNavigate } from 'react-router-dom';
import { initialEntrantFormData, initialFormData } from './initialFormData';
import {
  getSnipcart,
  openCheckout,
  subscribeSnipcartOrder,
} from 'services/snipcart';
import { stepsGroup, stepsIndividual } from './steps';
import { yearOfStudySelectorOptions } from './yearOfStudySelectorOptions';

const EntryForm = (
  props: {
    section: IEntryForm;
  } & ConnectedProps<typeof connector>
) => {
  const {
    disciplines,
    projectThemes,
    awardBriefs,
    awards,
    fetchProjectThemes,
    fetchDisciplines,
    fetchAwardBriefs,
    fetchAwards,
    disciplinesStatus,
    projectThemesStatus,
    awardBriefsStatus,
    awardsStatus,
    section: { thankYouPage },
  } = props;

  // Hooks
  const projectImages = useRef<File[]>([]);
  const pdfFile = useRef<File[]>([]);
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setError] = useState('');
  const addEntrantSubmitter = useRef<'addEntrant' | 'nextStep'>();
  const [loading, startLoading, stopLoading] = useLoading();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IFormData>(initialFormData);
  const [submittedProject, setSubmittedProject] = useState<IProject>();
  const [entrantFormData, setEntrantFormData] = useState<IEntrantFormData>(
    initialEntrantFormData
  );
  const [entrants, setEntrants] = useState(1);
  const steps = entrants > 1 ? stepsGroup : stepsIndividual;
  const [currentStep, setCurrentStep] = useState(steps[0]);

  // Fetch themes, disciplines, briefs
  useEffect(() => {
    if (projectThemesStatus === 'initial') fetchProjectThemes();
    if (disciplinesStatus === 'initial') fetchDisciplines();
    if (awardBriefsStatus === 'initial') fetchAwardBriefs();
    if (awardsStatus === 'initial') fetchAwards();
  }, [
    disciplinesStatus,
    projectThemesStatus,
    awardBriefsStatus,
    awardsStatus,
    fetchProjectThemes,
    fetchDisciplines,
    fetchAwardBriefs,
    fetchAwards,
  ]);

  const slugifyIt = (slug: string) =>
    slugify(slug, {
      lower: true,
      remove: /[^a-zA-Z0-9\s]/g,
    });

  const uniqueSlug = useCallback(async (slug: string) => {
    // Slug is unique, do nothing
    const isSlugExist = await strapi.getProjectBySlug(slug);
    if (!isSlugExist) return slug;

    // If the slug is not unique, append the current date
    const newSlug = slugifyIt(`${slug} ${new Date().toISOString()}`);
    return newSlug;
  }, []);

  const createFormData = useCallback(async () => {
    const requestFormData = new FormData();
    // Add unique slug
    const slug = await uniqueSlug(slugifyIt(formData.projectTitle));
    // Add award with biggest id
    const awardsIds = awards.map((c) => c.id);
    const award = awardsIds.length ? Math.max(...awardsIds) : null;
    const snipcart = await getSnipcart();
    const paymentCode =
      snipcart.store.getState().cart.discounts.items[0]?.code ?? '';
    requestFormData.append(
      'data',
      JSON.stringify({ ...formData, slug, award, paymentCode })
    );
    // Add files
    const files = [...pdfFile.current, ...projectImages.current];
    files?.forEach((file) => {
      requestFormData.append('files.userSubmittedFiles', file, file.name);
    });
    return requestFormData;
  }, [awards, formData, uniqueSlug]);

  // Redirect to previous step if cart is empty
  useEffect(() => {
    getSnipcart().then(
      (snipcart) => {
        const cartItems = snipcart.store.getState().cart.items;
        if (cartItems.count === 0) {
          window.location.replace('/awards-entry');
        }
        const entrants = cartItems.items[0].customFields.find(
          (c) => c.name === 'Entrants in group'
        )?.value;
        if (entrants) {
          setEntrants(Number(entrants));
        }
      },
      () => {
        window.location.replace('/awards-entry');
      }
    );
  }, []);

  useEffect(() => {
    if (submittedProject) {
      var unsubscribeSnipcartOrder: () => void | undefined;
      (async () => {
        var snipcart = await getSnipcart();
        unsubscribeSnipcartOrder = subscribeSnipcartOrder(
          snipcart,
          async (order: any) => {
            // Update Strapi project
            if (!submittedProject) {
              setError(`Project not found. Please contact 
            our support team at hello@creative-conscience.co.uk.`);
              snipcart.api.theme.cart.close();
              return;
            }
            await strapi.updateProject(submittedProject.id, {
              transactionId: order.invoiceNumber,
            } as IProject);
            window.location.replace(`/${thankYouPage?.slug || ''}`);
          },
          (error: string) => {
            setError(
              `Something went wrong with your payment, please contact us at 
            hello@creative-conscience.org.uk quoting the following error: "${error}"`
            );
            snipcart.api.theme.cart.close();
          }
        );
      })();
      // Cleanup
      return () => {
        unsubscribeSnipcartOrder?.();
      };
    }
  }, [
    createFormData,
    navigate,
    startLoading,
    stopLoading,
    submittedProject,
    thankYouPage?.slug,
  ]);

  const disciplineSelectorOptions = useMemo(() => {
    if (!disciplines.length) return [];
    return [
      { label: '', value: '' },
      ...disciplines
        .map((c) => ({ label: c.name, value: c.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [disciplines]);

  const projectThemesSelectorOptions = useMemo(() => {
    if (!projectThemes.length) return [];
    return [
      { label: '', value: '' },
      ...projectThemes
        .map((c) => ({ label: c.name, value: c.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [projectThemes]);

  const awardBriefsSelectorOptions = useMemo(() => {
    if (!awardBriefs.length) return [];
    return [
      { label: '', value: '' },
      ...awardBriefs
        .map((c) => ({ label: c.title, value: c.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [awardBriefs]);

  const nationalitySelectorOptions = useMemo(() => {
    return [
      { label: '', value: '' },
      ...nationalityJson.data.map((c) => ({
        label: c.split('_').join(' '),
        value: c,
      })),
    ];
  }, []);

  const countrySelectorOptions = useMemo(() => {
    return [
      { label: '', value: '' },
      ...countryJson.data.map((c) => ({
        label: c.split('_').join(' '),
        value: c,
      })),
    ];
  }, []);

  const currentStepIndex = steps.findIndex(
    (step) => step.name === currentStep.name
  );

  useEffect(() => {
    // On first step(initial render) no need to scroll
    if (currentStepIndex === 0) return;
    scrollToTop();
  }, [currentStepIndex]);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await goToNextStep();
  };

  const goToNextStep = async () => {
    const nextStepIndex = currentStepIndex + 1;

    // Submit project to Strapi
    if (nextStepIndex === steps.length) {
      const formData = await createFormData();
      startLoading(
        `We are uploading your assets. 
        This might take a couple of minutes, 
        <u>please do not leave the page or refresh the browser.</u>`
      );
      const { success, error, data } = await strapi.submitProject(formData);
      stopLoading();
      if (success) {
        setSubmittedProject(data);
        openCheckout();
      } else {
        setError(
          `Something went wrong with your submission, 
          please contact us at hello@creative-conscience.org.uk quoting the following error: "${error}"`
        );
      }
      return;
    }

    // If project entrant added already skip to review entrans step
    const nextStep = steps[nextStepIndex];
    if (nextStep.name === 'yourself' && formData.projectEntrants.length) {
      setCurrentStep(steps[nextStepIndex + 1]);
      return;
    }

    // next step
    setCurrentStep(nextStep);
  };

  const scrollToTop = () => {
    scrollTargetRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const onAddEntrantSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (entrants === 1) {
      setFormData((formData) => ({
        ...formData,
        projectEntrants: [entrantFormData],
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        projectEntrants: [...formData.projectEntrants, entrantFormData],
      }));
      setEntrantFormData(initialEntrantFormData);
    }

    if (addEntrantSubmitter.current === 'addEntrant') {
      scrollToTop();
    } else {
      goToNextStep();
    }

    addEntrantSubmitter.current = undefined;
  };

  const onPreviousStepClick = (step = 1) => {
    const prevStepIndex = currentStepIndex - step;
    if (prevStepIndex === -1) return;
    setCurrentStep(steps[prevStepIndex]);
  };

  const onReturnToEntrantClick = () => {
    const nextStepIndex = steps.findIndex((c) => c.name === 'yourself');
    if (nextStepIndex === -1) return;
    setCurrentStep(steps[nextStepIndex]);
  };

  const isMaximumEntrantsReached = entrants <= formData.projectEntrants.length;
  const isMaximumEntrantsWithExtraEntrantReached =
    entrants <= formData.projectEntrants.length + 1;

  return (
    <div className={styles.entryForm}>
      <div className={styles.entryFormInner}>
        {/* Title, subtitle */}
        <div className={styles.header}>
          <h2 className={styles.title}>{currentStep.title}</h2>
          <div ref={scrollTargetRef} className={styles.subtitle}>
            {currentStep.subtitle}
          </div>
          <span
            className={clsx(styles.paintDot, [
              currentStep.name === 'project' && styles.paintDotStep1,
              currentStep.name === 'yourself' && styles.paintDotStep2,
              currentStep.name === 'reviewyourself' && styles.paintDotStep3,
              currentStep.name === 'education' && styles.paintDotStep4,
            ])}
          />
        </div>

        {/** =====================================================
         * PROJECT
         * ======================================================= */}
        <form
          onSubmit={onSubmit}
          className={clsx(currentStep.name !== 'project' && styles.hiddenForm)}
        >
          {/* Brief */}
          <Select
            classes={{ group: styles.group }}
            label="Please select your chosen brief*"
            required={true}
            options={awardBriefsSelectorOptions}
            value={formData.awardBrief}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                awardBrief: Number(e.target.value),
              }))
            }
          />

          {/* Theme */}
          <Select
            classes={{ group: styles.group }}
            label="Which theme best describes your project?*"
            required={true}
            options={projectThemesSelectorOptions}
            value={formData.projectTheme}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                projectTheme: Number(e.target.value),
              }))
            }
          />

          {/* Discipline */}
          <Select
            classes={{ group: styles.group }}
            label="Which discipline best describes your project?*"
            required={true}
            options={disciplineSelectorOptions}
            value={formData.discipline}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                discipline: Number(e.target.value),
              }))
            }
          />

          {/* Project title */}
          <Input
            classes={{ group: styles.group }}
            label="What is the title of your project?*"
            value={formData.projectTitle}
            required={true}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                projectTitle: e.target.value,
              }))
            }
          />

          {/* Website url */}
          <Input
            classes={{ group: styles.group }}
            label="Project video or website URL if applicable"
            value={(formData.websites && formData.websites[0]?.url) || ''}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                websites: [
                  {
                    url: e.target.value,
                  },
                ],
              }))
            }
          />

          {/* Upload Pdf */}
          <InputFile
            classes={{ group: styles.group }}
            filesRef={pdfFile}
            accept="application/pdf"
            required={true}
            multiple={false}
            buttonLabel="Upload a PDF that showcases your project (max 15MB) *"
            maxSize={15}
          />

          {/* Upload project images */}
          <InputFile
            classes={{ group: styles.group }}
            filesRef={projectImages}
            required={true}
            accept="image/*"
            buttonLabel="Upload three key images (max 2MB each) *"
            max={3}
            min={3}
            maxSize={2}
          />

          {/* Project description */}
          <Textarea
            classes={{ group: styles.group }}
            label="Please summarise your project in 600 characters or less*"
            required={true}
            value={formData.projectDescription}
            notes="Please write a clear summary of your project and creative outcome,
          explaining how the work will communicate a positive message and
          what deliverables have been created."
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                projectDescription: e.target.value,
              }))
            }
            maxLength={600}
          />

          {/* Submit */}
          <div className={styles.submitButtonWrapper}>
            <button className={styles.submitButton} type="submit">
              Next step
            </button>
          </div>
        </form>

        {/** =====================================================
         * ENTRANT
         * ======================================================= */}
        <form
          onSubmit={onAddEntrantSubmit}
          className={clsx(currentStep.name !== 'yourself' && styles.hiddenForm)}
        >
          {/* First name */}
          <Input
            classes={{ group: styles.group }}
            label="Entrant's first name*"
            value={entrantFormData.firstName}
            required={true}
            onChange={(e) =>
              setEntrantFormData((formData) => ({
                ...formData,
                firstName: e.target.value,
              }))
            }
          />

          {/* Last name */}
          <Input
            classes={{ group: styles.group }}
            label="Entrant's last name*"
            value={entrantFormData.lastName}
            required={true}
            onChange={(e) =>
              setEntrantFormData((formData) => ({
                ...formData,
                lastName: e.target.value,
              }))
            }
          />

          {/* Nationality */}
          <Select
            classes={{ group: styles.group }}
            label="Entrant's nationality*"
            options={nationalitySelectorOptions}
            value={entrantFormData.nationality}
            required
            onChange={(e) =>
              setEntrantFormData((formData) => ({
                ...formData,
                nationality: e.target.value || undefined,
              }))
            }
          />

          {/* Email */}
          <Input
            classes={{ group: styles.group }}
            label="Entrant's email*"
            type="email"
            required={true}
            value={entrantFormData.email}
            onChange={(e) =>
              setEntrantFormData((formData) => ({
                ...formData,
                email: e.target.value,
              }))
            }
          />

          {/* Phone */}
          <Input
            classes={{ group: styles.group }}
            label="Entrant's telephone"
            value={entrantFormData.telephone}
            onChange={(e) =>
              setEntrantFormData((formData) => ({
                ...formData,
                telephone: e.target.value,
              }))
            }
          />

          {/* Twitter */}
          <Input
            classes={{ group: styles.group }}
            label="Entrant's twitter"
            value={entrantFormData.twitter}
            onChange={(e) =>
              setEntrantFormData((formData) => ({
                ...formData,
                twitter: e.target.value,
              }))
            }
          />

          {/* Instagram */}
          <Input
            classes={{ group: styles.group }}
            label="Entrant's instagram"
            value={entrantFormData.instagram}
            onChange={(e) =>
              setEntrantFormData((formData) => ({
                ...formData,
                instagram: e.target.value,
              }))
            }
          />

          {/* Website */}
          <Input
            classes={{ group: styles.group }}
            label="Entrant's website"
            value={entrantFormData.website}
            onChange={(e) =>
              setEntrantFormData((formData) => ({
                ...formData,
                website: e.target.value,
              }))
            }
          />

          {/* Add entrant */}
          {!isMaximumEntrantsWithExtraEntrantReached && (
            <div className={styles.groupLg}>
              <button
                className={styles.addEntrantButton}
                type="submit"
                onClick={() => (addEntrantSubmitter.current = 'addEntrant')}
              >
                + Add another entrant
              </button>
            </div>
          )}

          {/* Save and Next step */}
          <div className={styles.submitButtonWrapper}>
            <button
              className={styles.submitButton}
              onClick={() => (addEntrantSubmitter.current = 'nextStep')}
              type="submit"
            >
              Go to next step
            </button>
          </div>

          {/* Previous step */}
          <div className={styles.previousStepButtonWrapper}>
            <button
              className={styles.previousStepButton}
              type="button"
              onClick={() => onPreviousStepClick()}
            >
              Previous step
            </button>
          </div>
        </form>

        {/** =====================================================
         * REVIEW TEAM
         * ======================================================= */}

        <div
          className={clsx(
            currentStep.name !== 'reviewyourself' && styles.hiddenForm
          )}
        >
          <div className={styles.tableWrapper}>
            <div className={styles.table}>
              <div className={styles.columnHeaderIndex}>#</div>
              <div className={styles.columnHeader}>First Name</div>
              <div className={styles.columnHeader}>last Name</div>
              <div className={styles.columnHeaderDeleteButton} />
              {formData.projectEntrants.map((projectEntrant, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={styles.columnContactIndex}>
                      {index + 1}.
                    </div>
                    <div className={styles.columnContact}>
                      {projectEntrant.firstName}
                    </div>
                    <div className={styles.columnContact}>
                      {projectEntrant.lastName}
                    </div>
                    <div className={styles.columnContactDeleteButton}>
                      <button
                        onClick={() =>
                          setFormData((formData) => ({
                            ...formData,
                            projectEntrants: formData.projectEntrants.filter(
                              (_, i) => i !== index
                            ),
                          }))
                        }
                        title="Delete"
                        className={styles.removeContactButton}
                      >
                        x
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          {/* Add another entrant */}
          {!isMaximumEntrantsReached && (
            <div>
              <button
                className={styles.returnToEntrantButton}
                onClick={onReturnToEntrantClick}
                type="button"
              >
                + Add another entrant
              </button>
            </div>
          )}

          {/* Next step */}
          <div className={styles.submitButtonWrapper}>
            <button
              className={styles.submitButton}
              onClick={goToNextStep}
              type="button"
            >
              Next step
            </button>
          </div>

          {/* Previous step */}
          <div className={styles.previousStepButtonWrapper}>
            <button
              className={styles.previousStepButton}
              type="button"
              onClick={() => onPreviousStepClick(2)}
            >
              Previous step
            </button>
          </div>
        </div>

        {/** =====================================================
         * EDUCATION
         * ======================================================= */}
        <form
          onSubmit={onSubmit}
          className={clsx(
            currentStep.name !== 'education' && styles.hiddenForm
          )}
        >
          {/* University */}
          <Input
            classes={{ group: styles.group }}
            label="College / University*"
            value={formData.education.university}
            required={true}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                education: {
                  ...formData.education,
                  university: e.target.value,
                },
              }))
            }
          />

          {/* Year of study */}
          <Select
            classes={{ group: styles.group }}
            label="Current year of studying (if studying now)?*"
            required={true}
            options={yearOfStudySelectorOptions}
            value={formData.education.yearOfStudy}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                education: {
                  ...formData.education,
                  yearOfStudy: e.target.value,
                },
              }))
            }
          />

          {/* Country */}
          <Select
            classes={{ group: styles.group }}
            label="Country in which you are/were studying?*"
            required={true}
            options={countrySelectorOptions}
            value={formData.education.country}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                education: {
                  ...formData.education,
                  country: e.target.value,
                },
              }))
            }
          />

          {/* Tutor’s first name */}
          <Input
            classes={{ group: styles.group }}
            label="Tutor’s first name*"
            value={formData.education.tutorFirstName}
            required={true}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                education: {
                  ...formData.education,
                  tutorFirstName: e.target.value,
                },
              }))
            }
          />

          {/* Tutor’s last name */}
          <Input
            classes={{ group: styles.group }}
            label="Tutor’s last name*"
            value={formData.education.tutorLastName}
            required={true}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                education: {
                  ...formData.education,
                  tutorLastName: e.target.value,
                },
              }))
            }
          />

          {/* Tutor's email */}
          <Input
            classes={{ group: styles.group }}
            label="Tutor's email*"
            value={formData.education.tutorEmail}
            type="email"
            required={true}
            onChange={(e) =>
              setFormData((formData) => ({
                ...formData,
                education: {
                  ...formData.education,
                  tutorEmail: e.target.value,
                },
              }))
            }
          />
          {/* Error */}
          {errorMessage && (
            <div style={{ color: 'red' }} className={styles.group}>
              {errorMessage}
            </div>
          )}

          {/* Submit */}
          <div className={styles.submitButtonWrapper}>
            <button
              className={styles.submitButton}
              type="submit"
              disabled={loading}
            >
              Submit
            </button>
          </div>

          {/* Previous step */}
          <div className={styles.previousStepButtonWrapper}>
            <button
              className={styles.previousStepButton}
              type="button"
              onClick={() => onPreviousStepClick()}
            >
              Previous step
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  disciplines: state.discipline.disciplinesRequest.data ?? [],
  disciplinesStatus: state.discipline.disciplinesRequest.status,
  projectThemes: state.projectTheme.projectThemesRequest.data ?? [],
  projectThemesStatus: state.projectTheme.projectThemesRequest.status,
  awardBriefs: state.awardBrief.awardBriefRequest.data ?? [],
  awardBriefsStatus: state.awardBrief.awardBriefRequest.status,
  awards: state.award.awardsRequest.data ?? [],
  awardsStatus: state.award.awardsRequest.status,
});

const mapDispatchToProps = {
  fetchProjectThemes,
  fetchDisciplines,
  fetchAwardBriefs,
  fetchAwards,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(EntryForm);

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as strapi from "services/strapi";
import { IApiRequest } from "types/ApiRequest";
import { IFooter } from "types/generated/strapi";

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchFooter = createAsyncThunk(
  "footer/fetch",
  async () => {
    const response = await strapi.getFooter();
    return response;
  }
);

export interface IFooterSlice {
  footerRequest: IApiRequest<IFooter>;
}

const initialState: IFooterSlice = {
  footerRequest: {
    status: "initial",
  },
};

export const footerSlice = createSlice({
  name: "footer",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchFooter.pending, (state) => {
        state.footerRequest.status = "pending";
      })
      .addCase(fetchFooter.fulfilled, (state, action) => {
        state.footerRequest.status = "fulfilled";
        state.footerRequest.data = action.payload;
      })
      .addCase(fetchFooter.rejected, (state, action) => {
        state.footerRequest.status = "rejected";
        state.footerRequest.error = action.error;
      });
  },
});

export default footerSlice.reducer;

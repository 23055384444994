import styles from './index.module.css';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { IWinnerList, IProject } from 'types/generated/strapi';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import { fetchDisciplines } from 'redux/disciplineSlice';
import {
  fetchWinningProjects,
  fetchWinningProjectsCount,
} from 'redux/projectSlice';

import { fetchProjectThemes } from 'redux/projectThemeSlice';

import useFilter from 'utils/useFilter';
import StyledSelect from 'components/shared/StyledSelect';
import { Link } from 'react-router-dom';
import StrapiImage from 'components/shared/StrapiImage';
import usePagination from 'utils/usePagination';
import Pagination from 'components/shared/Pagination';

interface IWinnerListProps extends ConnectedProps<typeof connector> {
  section: IWinnerList;
}

const WinnerList: FunctionComponent<IWinnerListProps> = (props) => {
  const gridRef = useRef<HTMLDivElement>(null);

  const {
    fetchDisciplines,
    fetchWinningProjects,
    fetchWinningProjectsCount,
    fetchProjectThemes,
    disciplines,
    disciplinesStatus,
    winners,
    winnersCount,
    winnersStatus,
    projectThemes,
    projectThemesStatus,
  } = props;

  // Fetch disciplines and projectThemes
  useEffect(() => {
    if (disciplinesStatus === 'initial') {
      fetchDisciplines();
    }
    if (projectThemesStatus === 'initial') {
      fetchProjectThemes();
    }
  }, [
    fetchProjectThemes,
    fetchDisciplines,
    disciplinesStatus,
    projectThemesStatus,
  ]);

  // Connect pagination
  const { page, setPage, totalPages, perPage, withNextPage, withPrevPage } =
    usePagination({
      contentPerPage: 9,
      count: winnersCount,
    });

  // Connect filter by url query
  const [themeFilter, setThemeFilter] = useFilter({
    queryName: 'theme',
  });
  const [disciplineFilter, setDisciplineFilter] = useFilter({
    queryName: 'discipline',
  });
  const [pageFilter, setPageFilter] = useFilter({
    queryName: 'page',
  });
  const [searchFilter, setSearchFilter] = useFilter({
    queryName: 'search',
  });

  const pageFilterValue = pageFilter[0] || 1;
  const disciplineFilterValue = disciplineFilter[0] || '';
  const themeFilterValue = themeFilter[0] || '';
  const searchFilterValue = searchFilter[0] || '';

  // Fetch list on strapi search strings change
  useEffect(() => {
    fetchWinningProjects({
      page: pageFilterValue,
      perPage,
      discipline: disciplineFilterValue,
      search: searchFilterValue,
      theme: themeFilterValue,
    });

    fetchWinningProjectsCount({
      discipline: disciplineFilterValue,
      theme: themeFilterValue,
      search: searchFilterValue,
    });
  }, [
    pageFilterValue,
    disciplineFilterValue,
    perPage,
    themeFilterValue,
    searchFilterValue,
    fetchWinningProjects,
    fetchWinningProjectsCount,
  ]);

  // Set pagination page on query page change
  useEffect(() => {
    if (pageFilter[0]) {
      setPage(Number(pageFilter[0]));
    }
  }, [pageFilter, setPage]);

  const disciplineSelectOptions = useMemo(() => {
    return [
      { label: 'All disciplines', value: '' },
      ...disciplines
        .map(({ name }) => ({ label: name, value: name }))
        .sort((a, b) => b.label.localeCompare(a.label)),
    ];
  }, [disciplines]);

  const themeSelectOptions = useMemo(() => {
    return [
      { label: 'All themes', value: '' },
      ...projectThemes
        .map(({ name }) => ({ label: name, value: name }))
        .sort((a, b) => b.label.localeCompare(a.label)),
    ];
  }, [projectThemes]);

  // Scroll to list container on pagination button click
  const onPaginationClick = () => {
    gridRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const entrantsFullNames = useCallback((project: IProject) => {
    if (!project.projectEntrants?.length) return '';
    return project.projectEntrants
      .map((entrant) => `${entrant.firstName} ${entrant.lastName}`)
      .join(', ');
  }, []);

  return (
    <div className={styles.winnerList}>
      <div className={styles.winnerListInner}>
        {/* Grid Header */}
        <div className={styles.winnersGridHeader}>
          {disciplineSelectOptions.length > 0 && (
            <StyledSelect
              placeholder="Discipline"
              variant="secondary"
              options={disciplineSelectOptions}
              value={disciplineFilterValue}
              onChange={(e) => {
                setPageFilter(1);
                setDisciplineFilter(e.target.value);
              }}
            />
          )}
          {themeSelectOptions.length > 0 && (
            <StyledSelect
              variant="secondary"
              options={themeSelectOptions}
              value={themeFilterValue}
              placeholder="Theme"
              onChange={(e) => {
                setPageFilter(1);
                setThemeFilter(e.target.value);
              }}
            />
          )}
          <div className={styles.winnersSearchInputWrapper}>
            <input
              type="text"
              className={styles.winnersSearchInput}
              value={searchFilterValue}
              onChange={(e) => {
                setPageFilter(1);
                setSearchFilter(e.target.value);
              }}
              placeholder="Search for"
            />
          </div>
        </div>

        {/* Grid */}
        <div ref={gridRef} className={styles.winnersGrid}>
          {winnersStatus === 'fulfilled' && !winners.length && (
            <div>No matching projects found</div>
          )}
          {winners.map((project: IProject) => {
            const author = entrantsFullNames(project);

            return (
              <Link
                className={styles.winnersGridItem}
                key={project.id}
                to={`/winners/${project.slug}`}
              >
                {project.titleImage && (
                  <div className={styles.winnerPhotoWrapper}>
                    <StrapiImage
                      className={styles.winnerPhoto}
                      format="medium"
                      image={project.titleImage}
                    />
                  </div>
                )}
                {author && <div className={styles.winnerName}>{author}</div>}
                {project.discipline && (
                  <div className={styles.winnerDiscipline}>
                    {project.discipline.name}
                  </div>
                )}
              </Link>
            );
          })}
        </div>

        {/* Pagination */}
        <Pagination
          page={page}
          totalPages={totalPages}
          withPrevPage={withPrevPage}
          withNextPage={withNextPage}
          onClick={onPaginationClick}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  disciplines: state.discipline.disciplinesRequest.data ?? [],
  disciplinesStatus: state.discipline.disciplinesRequest.status,
  winners: state.project.winningProjectsRequest.data ?? [],
  winnersStatus: state.project.winningProjectsRequest.status,
  winnersCount: state.project.winningProjectsCountRequest.data ?? 0,
  projectThemes: state.projectTheme.projectThemesRequest.data ?? [],
  projectThemesStatus: state.projectTheme.projectThemesRequest.status,
});

const mapDispatchToProps = {
  fetchDisciplines,
  fetchWinningProjects,
  fetchWinningProjectsCount,
  fetchProjectThemes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(WinnerList);

import { FunctionComponent, useEffect } from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import { fetchEventsFuture } from 'redux/eventSlice';

import truncateString from 'utils/truncateString';
import formatSlug from 'utils/formatSlug';

import EventCard from 'components/shared/EventCard';

import { IEvent, IUpcomingEvents } from 'types/generated/strapi';

import styles from './index.module.css';

interface IUpcomingEventsProps extends ConnectedProps<typeof connector> {
  section: IUpcomingEvents;
}

const UpcomingEvents: FunctionComponent<IUpcomingEventsProps> = (props) => {
  const maxEventsToShow = props.section.maxEventsToShow ?? 6;

  const { events, eventsStatus, fetchEventsFuture } = props;
  const { hideSectionIfEmpty, heading } = props.section;

  // Fetch all future events once
  useEffect(() => {
    if (eventsStatus === 'initial') {
      fetchEventsFuture(maxEventsToShow);
    }
  }, [eventsStatus, fetchEventsFuture, maxEventsToShow]);

  const getThumbnail = (event: IEvent) => {
    return event.thumbnail || event.image;
  };

  const getBlurb = (event: IEvent) => {
    return event.blurb || truncateString(event.details);
  };

  const getReadMoreUrl = (event: IEvent) => {
    return formatSlug(`/events/${event.slug}`);
  };

  if (hideSectionIfEmpty && events.length <= 0) return null;

  return (
    <div
      className={styles.upcomingEvents}
      data-sidebar-target
      data-sidebar-sticky
    >
      <div className={styles.upcomingEventsInner}>
        {/* Heading */}
        {heading && <h2 className={styles.heading}>{heading}</h2>}

        {/* Events grid */}
        <div className={styles.eventsGrid}>
          {events.length <= 0 && (
            <div className={styles.noEvents}>No upcoming events found</div>
          )}

          {events?.map((event, index) => {
            return (
              <EventCard
                key={`event-${index}`}
                image={getThumbnail(event)}
                title={event.title}
                blurb={getBlurb(event)}
                date={event.date}
                endDate={event.endDate}
                readMoreUrl={getReadMoreUrl(event)}
                bookingUrl={event.url}
                layout="upcoming"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  events: state.event.eventsFutureRequest.data ?? [],
  eventsStatus: state.event.eventsFutureRequest.status,
});

const mapDispatchToProps = {
  fetchEventsFuture,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(UpcomingEvents);

import clsx from 'clsx';
import { ChangeEvent } from 'react';
import styles from './index.module.css';

interface TextareaProps {
  label: string;
  value: string | number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  required?: boolean;
  form?: string;
  notes?: string;
  classes?: {
    group?: string;
    label?: string;
  };
  maxLength?: number;
}
const Textarea = (props: TextareaProps) => {
  const { label, value, onChange, required, form, notes, classes, maxLength } =
    props;

  return (
    <div className={clsx(styles.group, classes?.group)}>
      <label>
        {label && (
          <div className={clsx(styles.label, classes?.label)}>{label}</div>
        )}
        <textarea
          form={form}
          value={value}
          className={styles.textarea}
          required={required}
          rows={7}
          onChange={(e) => onChange && onChange(e)}
          maxLength={maxLength}
        />
      </label>
      {notes && <div className={styles.notes}>{notes}</div>}
    </div>
  );
};

export default Textarea;

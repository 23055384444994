import { FunctionComponent } from 'react';

import ReactPlayer from 'react-player/lazy';
import type { ReactPlayerProps } from 'react-player';

import styles from './index.module.css';

interface IStrapiLinkProps {
  url: string;
  className?: string;
  options?: ReactPlayerProps;
}

const reactPlayerProps: ReactPlayerProps = {
  controls: true,
  light: true,
  width: '100%',
  height: '100%',
  config: {
    youtube: {
      playerVars: {
        modestbranding: 1,
        playsinline: 1,
        rel: 0,
      },
      embedOptions: {
        host: 'https://www.youtube-nocookie.com',
      },
    },
    vimeo: {
      playerOptions: {
        color: 'e60000',
        dnt: true,
      },
    },
  },
};

const VideoEmbed: FunctionComponent<IStrapiLinkProps> = (props) => {
  const { url, options } = props;

  if (!url) return null;

  return (
    <div className={styles.videoWrapper}>
      <ReactPlayer
        className={styles.videoPlayer}
        url={url}
        {...reactPlayerProps}
        {...options}
      />
    </div>
  );
};

export default VideoEmbed;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IPerson } from 'types/generated/strapi';

export const fetchPerson = createAsyncThunk(
  'person/fetch',
  async (slug: string) => {
    const response = await strapi.getPersonBySlug(slug);
    return response;
  }
);

export const fetchPeople = createAsyncThunk('people/fetch', async () => {
  const response = await strapi.getAllPeople();
  return response;
});

export const fetchJudges = createAsyncThunk(
  'judges/fetch',
  async (searchParams: any) => {
    const response = await strapi.getJudges(searchParams);
    return response;
  }
);

export const fetchJudgesCount = createAsyncThunk(
  'judgesCount/fetch',
  async (searchParams: any) => {
    const response = await strapi.getJudgesCount(searchParams);
    return response;
  }
);

export interface IPersonSlice {
  personRequest: IApiRequest<IPerson>;
  peopleRequest: IApiRequest<IPerson[]>;
  judgesRequest: IApiRequest<IPerson[]>;
  judgesCountRequest: IApiRequest<number>;
}

const initialState: IPersonSlice = {
  personRequest: {
    status: 'initial',
  },
  peopleRequest: {
    status: 'initial',
  },
  judgesRequest: {
    status: 'initial',
  },
  judgesCountRequest: {
    status: 'initial',
  },
};

export const personSlice = createSlice({
  name: 'person',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Single person
      .addCase(fetchPerson.pending, (state) => {
        state.personRequest.status = 'pending';
      })
      .addCase(fetchPerson.fulfilled, (state, action) => {
        state.personRequest.status = 'fulfilled';
        state.personRequest.data = action.payload;
      })
      .addCase(fetchPerson.rejected, (state, action) => {
        state.personRequest.status = 'rejected';
        state.personRequest.error = action.error;
      })

      // All people
      .addCase(fetchPeople.pending, (state) => {
        state.peopleRequest.status = 'pending';
      })
      .addCase(fetchPeople.fulfilled, (state, action) => {
        state.peopleRequest.status = 'fulfilled';
        state.peopleRequest.data = action.payload;
      })
      .addCase(fetchPeople.rejected, (state, action) => {
        state.peopleRequest.status = 'rejected';
        state.peopleRequest.error = action.error;
      })

      // Judges
      .addCase(fetchJudges.pending, (state) => {
        state.judgesRequest.status = 'pending';
      })
      .addCase(fetchJudges.fulfilled, (state, action) => {
        state.judgesRequest.status = 'fulfilled';
        state.judgesRequest.data = action.payload;
      })
      .addCase(fetchJudges.rejected, (state, action) => {
        state.judgesRequest.status = 'rejected';
        state.judgesRequest.error = action.error;
      })

      // Judges count
      .addCase(fetchJudgesCount.pending, (state) => {
        state.judgesCountRequest.status = 'pending';
      })
      .addCase(fetchJudgesCount.fulfilled, (state, action) => {
        state.judgesCountRequest.status = 'fulfilled';
        state.judgesCountRequest.data = action.payload;
      })
      .addCase(fetchJudgesCount.rejected, (state, action) => {
        state.judgesCountRequest.status = 'rejected';
        state.judgesCountRequest.error = action.error;
      });
  },
});

export default personSlice.reducer;

import { FunctionComponent, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';

import StrapiImage from 'components/shared/StrapiImage';

import { IPageHero } from 'types/generated/strapi';

import styles from './index.module.css';

interface IPageHeroProps extends ConnectedProps<typeof connector> {
  section: IPageHero;
}

const PageHero: FunctionComponent<IPageHeroProps> = (props) => {
  const { slug } = useParams();
  const { pageData } = props;
  const { titleImage, backgroundImage } = props.section;

  const titleText = useMemo(() => {
    if (props.section.titleText) return props.section.titleText;
    if (slug === pageData?.slug) return pageData?.title;
  }, [props.section.titleText, pageData?.title, slug, pageData?.slug]);

  const innerIsVisible = useMemo(() => {
    return titleImage || titleText;
  }, [titleImage, titleText]);

  const fallbackTitleIsVisible = useMemo(() => {
    return !titleImage && titleText;
  }, [titleImage, titleText]);

  return (
    <div
      className={clsx(styles.pageHero, !titleImage && styles.pageHeroNoTitle)}
    >
      {/* Background image */}
      {backgroundImage && (
        <StrapiImage
          className={styles.backgroundImage}
          format="xlarge"
          image={backgroundImage}
        />
      )}

      {innerIsVisible && (
        <div className={styles.pageHeroInner}>
          {/* Title image */}
          <h1 className={styles.heading}>
            {titleImage && (
              <StrapiImage
                className={styles.titleImage}
                format="medium"
                image={titleImage}
                alt={titleText}
              />
            )}

            {/* Fallback screen reader accessible title */}
            {fallbackTitleIsVisible && (
              <span className={styles.screenReaderTitle}>{titleText}</span>
            )}
          </h1>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  pageData: state.page.pageRequest.data,
});

const connector = connect(mapStateToProps);

export default connector(PageHero);

import styles from './index.module.css';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { IJudgeList, IPerson } from 'types/generated/strapi';

import { connect, ConnectedProps } from 'react-redux';
import { AppState } from 'redux/store';
import { fetchJudges, fetchJudgesCount } from 'redux/personSlice';
import { fetchDisciplines } from 'redux/disciplineSlice';
import { fetchAwards } from 'redux/awardSlice';

import useFilter from 'utils/useFilter';
import StyledSelect from 'components/shared/StyledSelect';
import { Link } from 'react-router-dom';
import StrapiImage from 'components/shared/StrapiImage';
import usePagination from 'utils/usePagination';
import Pagination from 'components/shared/Pagination';

interface IJudgeListProps extends ConnectedProps<typeof connector> {
  section: IJudgeList;
}

const JudgeList: FunctionComponent<IJudgeListProps> = (props) => {
  const gridRef = useRef<HTMLDivElement>(null);

  const {
    fetchDisciplines,
    fetchJudges,
    fetchJudgesCount,
    fetchAwards,
    judgesCount,
    disciplines,
    disciplinesStatus,
    judges,
    judgesStatus,
    awards,
    awardsStatus,
  } = props;

  // Fetch disciplines and awards
  useEffect(() => {
    if (disciplinesStatus === 'initial') {
      fetchDisciplines();
    }
    if (awardsStatus === 'initial') {
      fetchAwards();
    }
  }, [fetchDisciplines, fetchAwards, disciplinesStatus, awardsStatus]);

  // Connect pagination
  const { page, setPage, totalPages, perPage, withNextPage, withPrevPage } =
    usePagination({
      contentPerPage: 9,
      count: judgesCount,
    });

  // Connect filter by url query
  const [awardYearsFilter, setAwardYearsFilter] = useFilter({
    queryName: 'awardYears',
  });
  const [disciplineFilter, setDisciplineFilter] = useFilter({
    queryName: 'discipline',
  });
  const [pageFilter, setPageFilter] = useFilter({
    queryName: 'page',
  });

  const pageFilterValue = pageFilter[0] || 1;
  const disciplineFilterValue = disciplineFilter[0] || '';
  const awardFilterValue = awardYearsFilter[0] || '';

  // Fetch list on search strings change
  useEffect(() => {
    fetchJudges({
      page: pageFilterValue,
      perPage,
      discipline: disciplineFilterValue,
      award: awardFilterValue,
    });

    fetchJudgesCount({
      discipline: disciplineFilterValue,
      award: awardFilterValue,
    });
  }, [
    pageFilterValue,
    disciplineFilterValue,
    awardFilterValue,
    perPage,
    fetchJudges,
    fetchJudgesCount,
  ]);

  // Set pagination page on query page change
  useEffect(() => {
    if (pageFilter[0]) {
      setPage(Number(pageFilter[0]));
    }
  }, [pageFilter, setPage]);

  // Scroll to list container on pagination button click
  const onPaginationClick = () => {
    gridRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const awardYearsSelectOptions = useMemo(() => {
    return [
      { label: 'All awards', value: '' },
      ...awards
        .map(({ year }) => ({ label: `Awards ${year}`, value: year }))
        .sort((a, b) => b.label.localeCompare(a.label)),
    ];
  }, [awards]);

  const disciplineSelectOptions = useMemo(() => {
    return [
      { label: 'All disciplines', value: '' },
      ...disciplines
        .map(({ name }) => ({ label: name, value: name }))
        .sort((a, b) => b.label.localeCompare(a.label)),
    ];
  }, [disciplines]);

  return (
    <div className={styles.judgeList}>
      <div className={styles.judgeListInner}>
        {/* Grid Header */}
        <div className={styles.judgesGridHeader}>
          {disciplineSelectOptions.length > 0 && (
            <StyledSelect
              variant="secondary"
              options={disciplineSelectOptions}
              value={disciplineFilterValue}
              placeholder="Discipline"
              onChange={(e) => {
                setPageFilter(1);
                setDisciplineFilter(e.target.value);
              }}
            />
          )}
          {awardYearsSelectOptions.length > 0 && (
            <StyledSelect
              variant="secondary"
              options={awardYearsSelectOptions}
              value={awardFilterValue}
              onChange={(e) => {
                setPageFilter(1);
                setAwardYearsFilter(e.target.value);
              }}
            />
          )}
        </div>

        {/* Grid */}
        <div ref={gridRef} className={styles.judgesGrid}>
          {judgesStatus === 'fulfilled' && !judges.length && (
            <div>No matching judges found</div>
          )}
          {judges.map((judge: IPerson) => {
            return (
              <Link
                className={styles.judgesGridItem}
                key={judge.id}
                to={`/people/${judge.slug}`}
              >
                {judge.image && (
                  <div className={styles.judgePhotoWrapper}>
                    <StrapiImage
                      className={styles.judgePhoto}
                      format="medium"
                      image={judge.image}
                    />
                  </div>
                )}
                <div className={styles.judgeName}>{judge.name}</div>
                {judge.discipline && (
                  <div className={styles.judgeDiscipline}>
                    {judge.discipline.name}
                  </div>
                )}
              </Link>
            );
          })}
        </div>

        {/* Pagination */}
        <Pagination
          page={page}
          totalPages={totalPages}
          withPrevPage={withPrevPage}
          withNextPage={withNextPage}
          onClick={onPaginationClick}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  disciplines: state.discipline.disciplinesRequest.data ?? [],
  disciplinesStatus: state.discipline.disciplinesRequest.status,
  judges: state.person.judgesRequest.data ?? [],
  judgesStatus: state.person.judgesRequest.status,
  judgesCount: state.person.judgesCountRequest.data ?? 0,
  awards: state.award.awardsRequest.data ?? [],
  awardsStatus: state.award.awardsRequest.status,
});

const mapDispatchToProps = {
  fetchDisciplines,
  fetchJudges,
  fetchJudgesCount,
  fetchAwards,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(JudgeList);

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as strapi from 'services/strapi';
import { IApiRequest } from 'types/ApiRequest';
import { IEvent } from 'types/generated/strapi';

export const fetchEvent = createAsyncThunk(
  'event/fetch',
  async (slug: string) => {
    const response = await strapi.getEventBySlug(slug);
    return response;
  }
);

export const fetchEvents = createAsyncThunk(
  'events/fetch',
  async (limit: number = -1) => {
    const response = await strapi.getAllEvents(limit);
    return response;
  }
);

export const fetchEventsPast = createAsyncThunk(
  'eventsPast/fetch',
  async (limit: number = -1) => {
    const response = await strapi.getPastEvents(limit);
    return response;
  }
);

export const fetchEventsFuture = createAsyncThunk(
  'eventsFuture/fetch',
  async (limit: number = -1) => {
    const response = await strapi.getFutureAndOngoingEvents(limit);
    return response;
  }
);

export interface IEventSlice {
  eventRequest: IApiRequest<IEvent>;
  eventsRequest: IApiRequest<IEvent[]>;
  eventsPastRequest: IApiRequest<IEvent[]>;
  eventsFutureRequest: IApiRequest<IEvent[]>;
}

const initialState: IEventSlice = {
  eventRequest: {
    status: 'initial',
  },
  eventsRequest: {
    status: 'initial',
  },
  eventsPastRequest: {
    status: 'initial',
  },
  eventsFutureRequest: {
    status: 'initial',
  },
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // Single events
      .addCase(fetchEvent.pending, (state) => {
        state.eventRequest.status = 'pending';
        state.eventRequest.data = undefined;
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.eventRequest.status = 'fulfilled';
        state.eventRequest.data = action.payload;
      })
      .addCase(fetchEvent.rejected, (state, action) => {
        state.eventRequest.status = 'rejected';
        state.eventRequest.error = action.error;
      })

      // All events
      .addCase(fetchEvents.pending, (state) => {
        state.eventsRequest.status = 'pending';
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.eventsRequest.status = 'fulfilled';
        state.eventsRequest.data = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.eventsRequest.status = 'rejected';
        state.eventsRequest.error = action.error;
      })

      // Past events
      .addCase(fetchEventsPast.pending, (state) => {
        state.eventsPastRequest.status = 'pending';
      })
      .addCase(fetchEventsPast.fulfilled, (state, action) => {
        state.eventsPastRequest.status = 'fulfilled';
        state.eventsPastRequest.data = action.payload;
      })
      .addCase(fetchEventsPast.rejected, (state, action) => {
        state.eventsPastRequest.status = 'rejected';
        state.eventsPastRequest.error = action.error;
      })

      // Future events
      .addCase(fetchEventsFuture.pending, (state) => {
        state.eventsFutureRequest.status = 'pending';
      })
      .addCase(fetchEventsFuture.fulfilled, (state, action) => {
        state.eventsFutureRequest.status = 'fulfilled';
        state.eventsFutureRequest.data = action.payload;
      })
      .addCase(fetchEventsFuture.rejected, (state, action) => {
        state.eventsFutureRequest.status = 'rejected';
        state.eventsFutureRequest.error = action.error;
      });
  },
});

export default eventSlice.reducer;
